<template>
<v-container fluid>
    <v-row>
        <v-col>

            <v-card class="pa-4">

                <v-row>
                    <v-col cols="6">
                        <v-form
                            class="mb-6"
                            ref="form"
                            lazy-validation
                            @submit.prevent="calculateRetention()"
                        >
                            <div class="mb-1" style="color: rgba(0, 0, 0, 0.6);">Select Subscriptions</div>

                            <v-card
                                class="mx-auto pa-0"
                                outlined
                                color="rgba(0, 0, 0, 0.2)"
                                style="max-height: 300px; overflow: auto;"
                            >
                                <v-list>
                                    <v-list-item-group
                                        v-model="subscriptionsSelected"
                                        multiple
                                    >
                                        <template v-for="(application, i) in subscriptions">
                                            <v-divider
                                                v-if="i !== 0"
                                                :key="`divider-${i}`"
                                            ></v-divider>

                                            <div class="mt-2 px-3" :key="i">{{ application.name }}</div>
                                            
                                            <template v-if="!application.subscriptions.length">
                                                <p class="px-5" :key="`no-items-${i}`">No subscriptions</p>
                                            </template>
                                            <template v-for="subscription in application.subscriptions">
                                                <v-list-item
                                                    :key="`item-${subscription.id}`"
                                                    :value="subscription.id"
                                                    active-class="primary--text text--accent-4"
                                                >
                                                    <template v-slot:default="{ active }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                            :input-value="active"
                                                            color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title v-text="subscription.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>
                                            </template>
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>

                            <div class="my-2">
                                <v-chip
                                    v-for="(subscriptionId, i) in subscriptionsSelected"
                                    :key="i"
                                    class="mr-2 mb-1"
                                    close
                                    @click:close="clearSelectedSubscriptionById(subscriptionId)"
                                >
                                    {{ getSubscriptionNameById(subscriptionId) }}
                                </v-chip>
                            </div>

                            <v-select
                                item-text="name"
                                item-value="id"
                                v-model="maxPeriodSelected"
                                :items="maxPeriods"
                                label="Limit Periods"
                                clearable
                            ></v-select>

                            <v-btn
                                class="mt-2"
                                depressed
                                color="primary"
                                type="submit"
                                :loading="showTableDataLoading === true"
                            >
                                Calculate
                            </v-btn>

                        </v-form>

                    </v-col>
                </v-row>

                <v-row v-if="tableHeaders.length || tableData.length">
                    <v-col>
                        <v-data-table
                            :headers="tableHeaders"
                            hide-default-header
                            fixed-header
                            :sort-by="sortParams.by"
                            :sort-desc="sortParams.descending"
                            :items="tableData"
                            class="elevation-0 no-wrap"
                            :loading="showTableDataLoading === true"
                            loading-text="Loading... Please wait"
                            hide-default-footer
                            disable-pagination
                        >
                            <template #header="{ }">
                                <thead class="v-data-table-header">
                                    <tr>
                                        <th 
                                            v-for="item in tableHeaders" 
                                            :key="item.value"
                                            v-bind:class="['sortable', sortParams.by == item.value ? 'active': '', (sortParams.by != item.value ? 'desc' : (sortParams.descending ? 'desc':'asc'))]"
                                            @click="changeSort(item.value)"
                                        >
                                            <div>{{ item.text }}</div>
                                            <v-icon class="v-data-table-header__icon" small>mdi-arrow-up</v-icon>
                                        </th>
                                    </tr>
                                    <tr v-if="tableData.length" class="total">
                                        <th v-for="header in tableHeaders" :key="header.value">
                                            <div v-if="header.value === 'country'">
                                                <div>{{ tableTotal[header.value] }}</div>
                                            </div>
                                            <div v-else>
                                                <div>{{ tableTotal[header.value] }}</div>
                                                <div>{{ tableTotal[header.additional_value1] }}</div>
                                                <div style="color: gray;">
                                                    {{ tableTotal[header.additional_value2] || 0 }}% | {{ tableTotal[header.additional_value3] || 0 }}%
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-for="header in tableHeaders" v-slot:[`item.${header.value}`]="{ item }">
                                <div :key="header.value">
                                    <div v-if="header.value === 'country'">
                                        <div>{{ item[header.value] }}</div>
                                    </div>
                                    <div v-else>
                                        <div>{{ item[header.value] }}</div>
                                        <div>{{ item[header.additional_value1] }}</div>
                                        <div style="color: gray;">
                                            {{ item[header.additional_value2] || '-' }}% | {{ item[header.additional_value3] || '-' }}%
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

            </v-card>

        </v-col>
    </v-row>
</v-container>
</template>

<script>

import {objectToQueryString} from "../../../utils/helpers";
import {converValidationServerErrorsToString} from "../../../utils/errorUtil";

export default {
    name: "AdminRetentionV3Page",
    components: {},
    data() {
        return {
            rules: {
            },
            subscriptionsSelected: [],
            subscriptions: [],
            maxPeriodSelected: null,
            maxPeriods: Array.from({length: 24}, (_, i) => i + 1),
            tableHeaders: [],
            tableTotal: {},
            tableData: [],
            showTableDataLoading: false,
            sortParams: {
                by: 'period1_proceeds',
                descending: true,
            },
        }
    },
    watch: {
        subscriptionsSelected: function (newValue, oldValue) {
            localStorage.setItem('retention_v2_v3_subscriptions_selected', JSON.stringify(newValue));
        }
    },
    async mounted() {
        await this.getSubscriptions();
        this.restoreSelectedSubscriptions();
    },
    methods: {
        async getSubscriptions() {
            try {
                const response = await this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/subscriber/subscriptions`)
                this.subscriptions = response.data.subscriptions;
            } catch (err) {
                alert(converValidationServerErrorsToString(err));
            }
        },
        calculateRetention() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            if (!this.subscriptionsSelected.length) {
                return false;
            }

            const q = objectToQueryString({
                subscriptions: this.subscriptionsSelected,
                maxPeriod: this.maxPeriodSelected,
            });

            this.showTableDataLoading = true;

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/subscriber/retention-v3?${q}`).then(resp => {
                this.showTableDataLoading = false;
                const data = resp.data.data;
                this.tableHeaders = data.headers;
                this.tableData = data.data;
                this.tableTotal = data.total;
            })
            .catch(err => {
                this.showTableDataLoading = false;
                alert(converValidationServerErrorsToString(err))
            });
        },
        changeSort(column) {
            if (this.sortParams.by === column) {
                this.sortParams.descending = !this.sortParams.descending;
            } else {
                this.sortParams.by = column;
                this.sortParams.descending = true;
            }
        },
        getSubscriptionNameById(subscriptionId)
        {
            for (let i in this.subscriptions) {
                if (this.subscriptions[i].subscriptions.length) {
                    for (let j in this.subscriptions[i].subscriptions) {
                        if (this.subscriptions[i].subscriptions[j].id == subscriptionId) {
                            return this.subscriptions[i].subscriptions[j].name;
                        }
                    }
                }
            }
            return subscriptionId;
        },
        clearSelectedSubscriptionById(subscriptionId)
        {
            const index = this.subscriptionsSelected.indexOf(subscriptionId);
            if (index !== -1) {
                this.subscriptionsSelected.splice(index, 1);
            }
        },
        restoreSelectedSubscriptions()
        {
            const subscriptionIds = JSON.parse(localStorage.getItem('retention_v2_v3_subscriptions_selected'));
            this.subscriptionsSelected = subscriptionIds;
        }
    }

}

</script>

<style>
    .no-wrap table th {
        white-space: nowrap !important;
        min-width: 3%;
    }

    table tr.total th {
        color: #000 !important;
    }
</style>