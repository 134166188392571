<template>
    <v-container fluid>
        <v-row>
            <v-col>

                <v-card class="pa-4">

                    <v-form
                        class="mb-6"
                        ref="form"
                        lazy-validation
                        @submit.prevent="getData()"
                    >

                        <v-row>
                            <v-col cols="6">

                                <v-select
                                    item-text="name"
                                    item-value="id"
                                    v-model="applicationSelected"
                                    :items="applications"
                                    label="Application"
                                    :rules="rules.applicationRules"
                                    @change="onApplicationChange()"
                                ></v-select>
                                <v-select
                                    item-text="name"
                                    item-value="id"
                                    v-model="subscriptionSelected"
                                    :items="subscriptions"
                                    label="Subscription Name"
                                    multiple
                                    chips
                                    clearable
                                ></v-select>

                                <v-expansion-panels
                                    v-model="optionsPanel"
                                >
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Data Options</v-expansion-panel-header>
                                        <v-expansion-panel-content>

                                            <v-autocomplete
                                                v-model="selectedCountry"
                                                :items="countries"
                                                label="Geo"
                                                multiple
                                                chips
                                                clearable
                                                :filter="filterCountries"
                                            ></v-autocomplete>
                                            <v-text-field
                                                v-model="ignoreLastXDays"
                                                label="Ignore last X days"
                                                type="text"
                                            />
                                            <v-text-field
                                                v-model="vCPLFactor"
                                                label="vCPL Factor"
                                                type="text"
                                            />

                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="d-flex justify-space-between">

                                <v-btn
                                    class="mt-2"
                                    depressed
                                    color="primary"
                                    type="submit"
                                >
                                    Get Data
                                </v-btn>

                                <v-menu
                                    v-model="tableSettingsMenu"
                                    :close-on-content-click="false"
                                    :nudge-width="200"
                                    offset-x
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="mt-2"
                                            depressed
                                            type="button"
                                            plain
                                            color="black"
                                            v-bind="attrs"
                                            v-on="on"
                                            v-if="tableSettings"
                                        >
                                            <v-icon>mdi-cog</v-icon>Table Settings
                                        </v-btn>
                                    </template>
                                    <v-card class="px-4 py-4">
                                        <div style="height: 400px; overflow-y: scroll;">
                                            <div v-for="item in tableSettings.dataHeaders" :key="item.value">
                                                <v-checkbox
                                                    v-model="item.visible"
                                                    :label="item.text"
                                                    class="mt-0"
                                                ></v-checkbox>
                                            </div>
                                        </div>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                @click="tableSettingsMenu = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                text
                                                @click="updateTableSettings()"
                                                :loading="showTableSettingsLoading"
                                            >
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>

                            </v-col>
                        </v-row>

                    </v-form>

                    <v-data-table
                        :headers="getDataHeaders()"
                        hide-default-header
                        :sort-by="sortParams.by"
                        :sort-desc="sortParams.descending"
                        :items="data"
                        class="elevation-0 no-wrap"
                        :loading="isDataLoading === true"
                        loading-text="Loading... Please wait"
                        fixed-header
                        width="100%"
                        :height="data.length ? '100vh' : 'auto'"
                        hide-default-footer
                        disable-pagination
                    >
                        <template #header="{ }">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th 
                                        v-for="item in getDataHeaders()" 
                                        :key="item.value"
                                        v-bind:class="[item.sortable ? 'sortable' : '', sortParams.by == item.value ? 'active': '', (sortParams.by != item.value ? 'desc' : (sortParams.descending ? 'desc':'asc'))]"
                                        @click="item.sortable ? changeSort(item.value) : ''"
                                    >
                                        <div>{{ item.text }}</div>
                                        <v-icon v-if="item.sortable" class="v-data-table-header__icon" small>mdi-arrow-up</v-icon>
                                    </th>
                                </tr>
                                <tr v-if="data.length && tableSettings" class="total">
                                    <th :colspan="countNullTotalHeaders()" v-if="countNullTotalHeaders()"></th>
                                    <th v-for="header in getVisibleTableHeaders().filter(h => total[h.value] !== null)" :key="header.value">
                                        {{ formatTableData(total[header.value], header.dataFormat) }}
                                        <span v-if="header.dataAppend">&nbsp;{{ header.dataAppend }}</span>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-for="header in getVisibleTableHeaders()" v-slot:[`item.${header.value}`]="{ item }">
                            <div :key="header.value" :style="`max-width: ${getHeaderCustomSettingsParam(header.value, 'width')}; white-space: nowrap; overflow: hidden;`">
                                <span v-if="header.displayValue">
                                    {{ formatTableData(item[header.displayValue], header.dataFormat) }}
                                </span>
                                <span v-else>
                                    {{ formatTableData(item[header.value], header.dataFormat) }}
                                </span>
                                <span v-if="header.dataAppend">&nbsp;{{ header.dataAppend }}</span>
                            </div>
                        </template>
                    </v-data-table>

                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<style>
    .no-wrap table th {
        white-space: nowrap !important;
        min-width: 3%;
    }

    table tr.total th {
        color: #000 !important;
    }
</style>


<script>

import {objectToQueryString} from "../../../utils/helpers";
import {converValidationServerErrorsToString} from "../../../utils/errorUtil";

export default {
    name: "AdminDashboardPage",
    created() {

    },
    mounted() {
        this.getApplicationList();
        this.getCountries();
        this.getTableSettings();
    },
    data() {
        return {
            rules: {
                applicationRules: [
                    v => !!v || 'Application is required',
                ],
            },
            applications: [],
            applicationSelected: null,
            subscriptions: [],
            subscriptionSelected: null,
            countries: [],
            selectedCountry: null,
            tableSettings: [],
            sortParams: {
                by: 'leads',
                descending: true,
            },
            data: [],
            total: {},
            isDataLoading: false,
            tableSettingsMenu: null,
            showTableSettingsLoading: false,
            headersCustomSettings: {
                'country': { width: '120px' },
            },
            ignoreLastXDays: '0',
            vCPLFactor: '0.8',
            optionsPanel: null,
        }
    },
    methods: {
        getApplicationList() {
            let q = objectToQueryString(this.queryParams);

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications?${q}`)
                .then(response => this.applications = response.data.data.items)
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getSubscriptions(applicationId) {
            let q = objectToQueryString(this.queryParams);

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/subscriber/subscriptions/${applicationId}?${q}`)
                .then(response => this.subscriptions = response.data.subscriptions)
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getCountries() {
          this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries`)
              .then((response) => {
                this.initialCountries = response.data;
                this.fillCountries();
              })
              .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getTableSettings() {
            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/table/summary_by_subs/settings`)
                .then((response) => {
                    this.tableSettings = response.data;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        updateTableSettings() {
            this.showTableSettingsLoading = true;
            this.$http.post(
                `${process.env.VUE_APP_SERVER_BASE_URL}/admin/table/summary_by_subs/settings`,
                this.tableSettings
            )
                .then(() => {
                    this.showTableSettingsLoading = false;
                    this.tableSettingsMenu = false;
                 })
                .catch(err => {
                    this.showTableSettingsLoading = false;
                    alert(converValidationServerErrorsToString(err));
                });
        },
        getDataHeaders() {
            if (!this.tableSettings) {
                return [];
            }

            let dataHeaders = [];

            for (let i in this.tableSettings.dataHeaders) {
                const header = this.tableSettings.dataHeaders[i];
                if (!header.visible) {
                    continue;
                }

                dataHeaders.push({
                    text: header.text,
                    value: header.value,
                    sortable: true,
                });
            }

            return dataHeaders;
        },
        fillCountries() {
          for (let i in this.initialCountries) {
            if (Object.prototype.hasOwnProperty.call(this.initialCountries, i)) {
              let abbreviations = this.initialCountries[i].abbreviations.map(item => item.abbreviation);
              this.countries.push({ text: `${this.initialCountries[i].name}`, value: abbreviations});
            }
          }
        },
        onApplicationChange() {
            this.subscriptionSelected = null;
            this.subscriptions = [];
            this.getSubscriptions(this.applicationSelected);
        },
        getData() {
            if (!this.applicationSelected) {
                return;
            }
            this.isDataLoading = true;

            const urlParams = new URLSearchParams(window.location.search);

            const postData = {
                subscriptions: this.subscriptionSelected,
                geo: this.selectedCountry,
                ignoreLastXDays: this.ignoreLastXDays,
                vCPLFactor: this.vCPLFactor,
                new: urlParams.get('new') || 0,
            };

            this.$http.post(
                `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.applicationSelected}/statistic/summary?`,
                postData
            )
                .then(response => {
                    this.isDataLoading = false;
                    this.data = response.data.data.items;
                    this.total = response.data.data.total;
                 })
                .catch(err => {
                    this.isDataLoading = false;
                    alert(converValidationServerErrorsToString(err));
                });
        },
        formatDecimals(num, decimals) {
            return (Math.round(num * 100) / 100).toFixed(decimals);
        },
        changeSort(column) {
            if (this.sortParams.by === column) {
                this.sortParams.descending = !this.sortParams.descending;
            } else {
                this.sortParams.by = column;
                this.sortParams.descending = true;
            }
        },
        filterCountries (item, queryText, itemText) {
            const textOne = item.text.toLowerCase();
            const textTwo = `${item.value}`;

            const searchText = queryText.toLowerCase();

            return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1;
        },
        formatTableData(value, format) {
            const formatParts = format.split(',');
            if (formatParts[0] == 'decimal') {
                return this.formatDecimals(value, formatParts[1]);
            }
            return value;
        },
        getVisibleTableHeaders() {
            if (!this.tableSettings || !this.tableSettings.dataHeaders) {
                return [];
            }
            return this.tableSettings.dataHeaders.filter(h => h.visible);
        },
        countNullTotalHeaders() {
            let counter = 0;
            const tableHeaders = this.getVisibleTableHeaders();
            for (let i in tableHeaders) {
                if (this.total[tableHeaders[i].value] === null) {
                    counter++;
                }
            }
            return counter;
        },
        getHeaderCustomSettingsParam(header, param) {
            const headerCustomSettings = this.headersCustomSettings[header];
            if (!headerCustomSettings) {
                return null;
            }
            return headerCustomSettings[param] || null;
        }
    }
}
</script>