<template>
    <div>
        <v-card flat tile>
            <v-toolbar
                dark
                :color="getColor()"
                class="hidden-xs-and-down"
            >
                <!-- <v-app-bar-nav-icon @click="drawer = !drawer"/> -->

                <v-toolbar-title>{{ getTitle() }}</v-toolbar-title>

                <v-toolbar-items class="ml-4">
                    <v-btn class="nav-link" to="/admin/applications" text v-if="can('settings')">
                        Apps
                    </v-btn>

                    <v-btn class="nav-link" to="/admin/data" text v-if="can('settings')">
                        Data
                    </v-btn>

                    <v-menu
                        open-on-hover
                        bottom
                        offset-y
                        :close-on-click="true"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="nav-link"
                            text
                            v-if="can('retention.view')"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Retention<v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                        </template>

                        <v-list>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn class="nav-link" to="/admin/retention" block text>
                                        v1
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn class="nav-link" to="/admin/retention-v2" block text>
                                        v2
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn class="nav-link" to="/admin/retention-v3" block text>
                                        v3
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn class="nav-link" to="/admin/retention-v4" block text>
                                        v4
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn class="nav-link" to="/admin/reviews" text v-if="can('reviews.view')">
                        Reviews
                    </v-btn>

                    <v-btn class="nav-link" to="/admin/mailbox" text v-if="can('mail.view')">
                        Mail
                    </v-btn>

                    <v-menu
                        open-on-hover
                        bottom
                        offset-y
                        :close-on-click="true"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="nav-link"
                                text
                                v-if="can('dashboard.view') || can('dashboard-bu-sub.view')"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Dashboards<v-icon right>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-if="can('dashboard.view')">
                                <v-list-item-title>
                                    <v-btn class="nav-link" to="/admin/statistic" block text>
                                        Dashboard
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="can('dashboard-bu-sub.view')">
                                <v-list-item-title>
                                    <v-btn class="nav-link" to="/admin/dashboard" block text>
                                        Dash-by-subs
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="can('dashboard-bu-sub.view')">
                                <v-list-item-title>
                                    <v-btn class="nav-link" to="/admin/dashboard-all-apps" block text>
                                        Summary
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="can('dashboard-bu-sub.view')">
                                <v-list-item-title>
                                    <v-btn class="nav-link" to="/admin/dashboard-summary-app-sub" block text>
                                        Summary App-Sub
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                </v-toolbar-items>

                <v-spacer />

                <v-toolbar-items class="ml-4">
                    <v-btn class="nav-link" to="/reviews-parsing" text>
                        Reviews Parser
                    </v-btn>
                    <v-btn v-if="can('settings')" class="nav-link" to="/admin/settings" text>
                      Settings
                    </v-btn>
                    <template v-if="!isLoggedIn">
                        <v-btn to="/login" text>
                            <v-icon>mdi-login</v-icon> Login
                        </v-btn>
                    </template>
                    <template v-else>
                        <v-btn type="button" class="nav-link" @click="logout" text>
                            <v-icon>mdi-logout</v-icon> Logout
                        </v-btn>
                    </template>
                </v-toolbar-items>
            </v-toolbar>
        </v-card>

        <v-navigation-drawer v-model="drawer" fixed temporary>
            <div class="pa-2">
                <v-list v-if="isLoggedIn">
                    <v-list-item-group>
                        <v-list-item v-for="(item, i) in computedMenuItems" :key="i" :to="getLink(item)">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"/>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <!--<template v-if="!!user">
                    {{ user }}
                </template>-->
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {rolesEnum, rolesList} from "../enums/enums";

    export default {
        name: "Navbar",
        data: () => ({
            drawer: null,
            menuItems: [
                {
                    icon: 'mdi-heart',
                    text: 'Приложения',
                    link: '/applications',
                    computable: true,
                    roles: [
                        rolesList.admin,
                    ]
                },
            ],
        }),
        computed: {
            ...mapGetters({
                isLoggedIn: 'IS_LOGGED_IN',
                user: 'GET_USER',
            }),
            title: () => {
                return process.env.VUE_APP_TITLE
            },
            computedMenuItems: function () {
                return this.menuItems.filter(it => it.roles.includes(this.user.role))
            },
            rolesEnum: function () {
                return rolesEnum
            }

        },
        methods: {
            ...mapActions([
                'EXEC_LOGOUT'
            ]),
            getLink(item) {
                if (!item.computable) {
                    return item.link
                }

                let roleName = this.user.role

                return `/${roleName}${item.link}`
            },
            logout() {
                this.EXEC_LOGOUT().then(() => {
                    this.$router.push('/login')
                })
            },
            isItemAllow(role) {
                return role === this.user.role
            },
            getTitle() {
                return process.env.VUE_APP_TITLE;
            },
            getColor() {
                if (process.env.VUE_APP_ENV === 'production') {
                    return 'red lighten-2';
                } else {
                    return 'blue lighten-2';
                }
            },
        }
    }
</script>

<style scoped>

</style>
