<template>
    <v-card>
        <v-card-title>
            {{ getTitle() }}
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <UserForm
                            v-if="this.actionLocal"
                            :action="actionLocal"
                            :user="userLocal"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

    import {eventBus, eventBusEvents} from "../../../../eventBus/eventBus";
    import {crudActions} from "../../../../enums/enums";
    import UserForm from "./UserForm";
    import CountryForm from "../../Countries/CountryForm";

    export default {
        name: "UserPanel",
        props: ['action', 'user'],
        components: {
            UserForm,
        },
        data() {
            return {
                actionLocal: null,
                userLocal: null,
            }
        },
        mounted() {
            this.actionLocal = this.action
            this.userLocal = this.user
        },
        created() {
            eventBus.$on(eventBusEvents.EVENT_EDIT_APPLICATION_BY_ADMIN, async () => {
                this.actionLocal = this.crudActions.update
            });
            eventBus.$on(eventBusEvents.EVENT_REFRESH_APPLICATION_BY_ADMIN, async () => {
                this.actionLocal = this.crudActions.create
            });
        },
        computed: {
            crudActions: function () {
                return crudActions
            },
        },
        methods: {
            goToCreate() {
                this.actionLocal = this.crudActions.create
                eventBus.$emit(eventBusEvents.EVENT_REFRESH_APPLICATION_BY_ADMIN)
            },
            isActionCreate() {
                return this.actionLocal === this.crudActions.create
            },
            isActionUpdate() {
                return !this.isActionCreate()
            },
            getTitle() {
                if (this.actionLocal === this.crudActions.update) {
                    return 'Edit user';
                }
                return 'Add user';
            },
        }
    }
</script>

<style scoped>

</style>
