<template>
  <v-app id="inspire">
    <navbar />
    <v-main class="grey lighten-3">
      <v-progress-circular indeterminate color="red" v-if="processingRequest" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
import { mapActions, mapGetters } from "vuex";
import { converValidationServerErrorsToString } from "./utils/errorUtil";

export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = process.env.VUE_APP_TITLE;
      },
    },
  },
  components: {
    Navbar,
  },
  mounted() {
    if (this.token) {
      this.$http
        .post(`${process.env.VUE_APP_SERVER_BASE_URL}/auth/user-info`)
        .then((res) => this.EXEC_REFRESH_USER(res.data.data.user))
        .catch((err) => alert(converValidationServerErrorsToString(err)));
    }
  },
  created() {
    this.$http.interceptors.response.use(void 0, (err) => {
      return new Promise(() => {
        if (err.response.status === 401) {
          this.$store.dispatch("EXEC_LOGOUT");
          this.$router.push("/login");
        }
        throw err;
      });
    });
  },
  computed: {
    ...mapGetters({
      processingRequest: "GET_PROCESSING_REQUEST",
      user: "GET_USER",
      token: "GET_TOKEN",
    }),
  },
  methods: {
    ...mapActions(["EXEC_REFRESH_USER"]),
  },
};
</script>

<style scoped>
#app {
  padding: 20px;
}
</style>
