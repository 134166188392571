<template>
    <div>
        <v-form v-if="countryLocal"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSubmit"
        >
            <v-text-field
                v-model="countryLocal.name"
                name="country"
                label="Country"
                type="text"
                @keyup.enter="handleSubmit"
                required
            />
            <v-text-field
                v-model="countryLocal.abbreviations"
                name="abbreviations"
                label="Abbreviations (list them separated by commas)"
                type="text"
                @keyup.enter="handleSubmit"
                required
            />
          <v-text-field
              v-model="countryLocal.tier"
              name="tier"
              :rules="rules.tierRules"
              label="Tier (1, 2, 3)"
              type="text"
              @keyup.enter="handleSubmit"
              required
          />
            <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="handleSubmit"> {{ btnText }}
            </v-btn>
        </v-form>
    </div>
</template>

<script>
    import {eventBus, eventBusEvents} from "../../../eventBus/eventBus";
    import {crudActions} from "../../../enums/enums";
    import {converValidationServerErrorsToString} from "../../../utils/errorUtil";
    import {mapActions} from "vuex";

    require('dotenv').config()

    export default {
        name: "ApplicationForm",
        props: ['action', 'country'],
        data() {
            return {
                tiers: [
                    1, 2, 3
                ],
                valid: true,
                dateToMenu: null,

                actionLocal: null,
                countryLocal: {
                    id: null,
                    name: null,
                    abbreviations: null,
                    tier: null,
                },

              rules: {
                tierRules: [
                  v => !!v || 'Tier must be valid',
                  v => (v == 1 || v == 2 || v == 3) || 'Tier value must be 1, 2 or 3'
                ],

              },
            }


        },
        computed: {
            crudActions: function () {
                return crudActions
            },
            btnText: function () {
                return this.actionLocal === this.crudActions.create ? 'Create' : 'Update'
            }
        },
        created() {
            this.actionLocal = this.action

            if (this.application) {
                this.applicationLocal = this.application
            }

            eventBus.$on(eventBusEvents.EVENT_EDIT_APPLICATION_BY_ADMIN, async application => {
                console.log('EDIT_APPLICATION_BY_ADMIN', application)
            });
            eventBus.$on(eventBusEvents.EVENT_REFRESH_APPLICATION_BY_ADMIN, async () => {
                this.refresh()
            });
        },
        mounted() {
            if (this.actionLocal === this.crudActions.update) {
                console.log(this.countryLocal);
                this.countryLocal = this.country
            }
        },
        methods: {
            ...mapActions([
                'EXEC_PROCESSING_REQUEST',
            ]),
            edit(data) {
                console.log(data);
                this.actionLocal = this.crudActions.update
            },
            handleSubmit(e) {
                e.preventDefault()

                if (this.actionLocal === this.crudActions.create) {
                    this.create()
                } else {
                    this.update()
                }
            },
            create() {
                console.log('country_form_create')

                this.EXEC_PROCESSING_REQUEST(true)

                this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries`,
                    this.countryLocal
                ).then(resp => {
                    const country = resp.data;
                    eventBus.$emit(eventBusEvents.EVENT_REFRESH_COUNTRIES_BY_ADMIN)
                })
                    .catch(err => alert(converValidationServerErrorsToString(err)))
                    .finally(() => this.EXEC_PROCESSING_REQUEST(false))
            },

            update() {
                console.log('country_form_update')

                this.EXEC_PROCESSING_REQUEST(true)

                let data = {
                    ...this.countryLocal,
                    ...{
                        _method: 'put'
                    }
                }

                console.log('country_form_update_data___', data)
                close();
                this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries/${this.countryLocal.id}`, data)
                    .then(() => {
                        eventBus.$emit(eventBusEvents.EVENT_REFRESH_COUNTRIES_BY_ADMIN)
                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)))
                    .finally(() => this.EXEC_PROCESSING_REQUEST(false))
            },
        }
    }
</script>

<style scoped>

</style>
