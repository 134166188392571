<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card class="pa-4">
                    <v-row>
                        <v-col cols="6">
                            <v-select
                                item-text="name"
                                item-value="id"
                                v-model="applicationSelected"
                                :items="applications"
                                label="Application"
                                @change="getReviews(applicationSelected)"
                            ></v-select>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4">
                            <div class="d-flex">
                                <v-select
                                    v-model="selectedStorage"
                                    :items="storage"
                                    placeholder="Storage"
                                    clearable
                                    :filled="selectedRating !== null"
                                    @change="updateReviewsWithFilters()"
                                ></v-select>
                                <v-select
                                    class="ml-4"
                                    v-model="selectedRating"
                                    :items="ratings"
                                    placeholder="Rating"
                                    clearable
                                    :filled="selectedRating !== null"
                                    @change="updateReviewsWithFilters()"
                                ></v-select>
                                <v-autocomplete
                                    class="ml-4"
                                    v-model="selectedCountry"
                                    :items="countries"
                                    :filter="filterCountries"
                                    placeholder="Geo"
                                    clearable
                                    :filled="selectedCountry !== null"
                                    @change="updateWithFilters()"
                                ></v-autocomplete>
                            </div>
                        </v-col>
                    </v-row>

                    <div class="d-flex">
                        <v-card
                            class="mt-2 mb-6 mr-4 elevation-0"
                            outlined
                            max-width="344"
                        >
                            <v-card-title>Ratings Summary</v-card-title>
                            <v-card-text>
                                <div>
                                    Average user rating:
                                    <strong>{{
                                        getAppAverageUserRating() || "--"
                                    }}</strong>
                                </div>
                                <div>
                                    User rating count:
                                    <strong>{{
                                        getAppUserRatingCount() || "--"
                                    }}</strong>
                                </div>
                            </v-card-text>
                        </v-card>

                        <v-card
                            class="mt-2 mb-6 mr-4 elevation-0"
                            outlined
                            max-width="344"
                        >
                            <v-card-title>Reviews Summary</v-card-title>
                            <v-card-text>
                                <div>
                                    Average rating:
                                    <strong>{{
                                        getReviewsAverageUserRating() || "--"
                                    }}</strong>
                                </div>
                                <div>
                                    Total reviews:
                                    <strong>{{
                                        getReviewsUserRatingCount() || "--"
                                    }}</strong>
                                </div>
                            </v-card-text>
                        </v-card>

                        <!-- Unsubscribes -->
                        <v-card
                            class="mt-2 mb-6 elevation-0"
                            outlined
                            max-width="344"
                        >
                            <v-card-title>Unsubscribes Summary</v-card-title>
                            <v-card-text>
                                <div>
                                    Total unsubscribes:
                                    <strong>{{
                                        getUnsubscribesCount() || "--"
                                    }}</strong>
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>

                    <v-tabs class="mb-2" v-model="tab">
                        <v-tab key="reviews">Reviews</v-tab>
                        <v-tab key="refunds">Refunds</v-tab>
                        <v-tab key="unsubscribes">Unsubscribes</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item key="reviews">
                            <v-data-table
                                :headers="reviewHeaders"
                                :sort-by="'raw_updated'"
                                :sort-desc="true"
                                :items="reviews"
                                :item-class="colorRowForReviewFromStore"
                                :items-per-page="50"
                                class="elevation-0"
                                :loading="showReviewsLoading === true"
                                loading-text="Loading... Please wait"
                            >
                                <template v-slot:item.content="{ item }">
                                    <div class="ma-2">{{ item.content }}</div>
                                    <v-card
                                        dark
                                        outlined
                                        class="pa-2 ma-2"
                                        :color="
                                            item.status ? '#1F7087' : '#AEAEAE'
                                        "
                                        v-if="item.reply"
                                        ><div class="reply-date text-right">
                                            {{ item.reply_date }}
                                        </div>
                                        <div>{{ item.reply }}</div></v-card
                                    >
                                </template>
                                <template v-slot:item.raw_updated="{ item }">
                                    <v-chip class="mr-2">{{
                                        item.updated
                                    }}</v-chip>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-menu bottom left v-if="item.id">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                v-bind="attrs"
                                                v-on="on"
                                                depressed
                                            >
                                                <v-icon color="black"
                                                    >mdi-dots-vertical</v-icon
                                                >
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item
                                                v-for="(listItem,
                                                i) in menuItems"
                                                :key="i"
                                                :disabled="
                                                    (item.reply && i === 0) ||
                                                        (!item.reply && i > 0)
                                                "
                                            >
                                                <v-list-item-title
                                                    @click="
                                                        menuHandler(
                                                            i,
                                                            item.id,
                                                            item.reply
                                                        )
                                                    "
                                                    class="pointer"
                                                    :class="
                                                        i === 3 && item.reply
                                                            ? 'red--text'
                                                            : ''
                                                    "
                                                    >{{
                                                        listItem.title
                                                    }}</v-list-item-title
                                                >
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                        <v-tab-item key="refunds">
                            <v-data-table
                                :headers="refundHeaders"
                                :sort-by="'raw_updated'"
                                :sort-desc="true"
                                :items="refunds"
                                :item-class="colorRowForReviewFromStore"
                                :items-per-page="50"
                                class="elevation-0"
                                :loading="showReviewsLoading === true"
                                loading-text="Loading... Please wait"
                            >
                                <template v-slot:item.raw_updated="{ item }">
                                    <v-chip>{{ item.updated }}</v-chip>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                        <v-tab-item key="unsubscribes">
                            <v-data-table
                                :headers="unsubscribeHeaders"
                                :sort-by="'raw_updated'"
                                :sort-desc="true"
                                :items="unsubscribes"
                                :item-class="colorRowForReviewFromStore"
                                :items-per-page="50"
                                class="elevation-0"
                                :loading="showReviewsLoading === true"
                                loading-text="Loading... Please wait"
                            >
                                <template v-slot:item.raw_updated="{ item }">
                                    <v-chip>{{ item.updated }}</v-chip>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-dialog v-model="replyDialog" max-width="800">
                <v-card>
                    <v-card-title class="text-h5">
                        Reply to comment
                    </v-card-title>

                    <v-textarea
                        outlined
                        name="input-7-4"
                        label="Ответ"
                        v-model="replyText"
                        class="px-3 pt-5"
                    >
                    </v-textarea>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="green darken-1"
                            text
                            @click="replyDialog = false"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            color="green darken-1"
                            text
                            @click="
                                sendReply();
                                replyDialog = false;
                            "
                        >
                            Send
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { converValidationServerErrorsToString } from "../../../utils/errorUtil";

export default {
    name: "AdminReviewsPage",
    created() {},
    mounted() {
        this.getApplicationList();
        this.getCountries();
    },
    data() {
        //  ( дата-автор-заголовок-оценка-текст отзыва-voteSum-voteCount)
        return {
            tab: null,
            applicationSelected: null,
            applications: [],
            ratings: [],
            storage: [],
            selectedRating: null,
            selectedStorage: null,
            initialCountries: [],
            countries: [],
            selectedCountry: null,
            reviewHeaders: [
                { text: "Date", value: "raw_updated", width: "8%" },
                { text: "Author", value: "author_name" },
                { text: "Country", value: "country" },
                { text: "Title", value: "title" },
                { text: "Rating", value: "rating", width: "8%" },
                { text: "Review", value: "content", width: "40%" },
                { text: "voteSum", value: "vote_sum", width: "8%" },
                { text: "voteCount", value: "vote_count", width: "8%" },
                { text: "Actions", value: "actions", sortable: false }
            ],
            refundHeaders: [
                { text: "Date", value: "raw_updated", width: "8%" },
                { text: "Author", value: "author_name" },
                { text: "Country", value: "country" },
                { text: "Reason", value: "reason" },
                { text: "Report", value: "report" }
            ],
            unsubscribeHeaders: [
                { text: "Date", value: "raw_updated", width: "8%" },
                { text: "Uid", value: "author_name" },
                { text: "Country", value: "country" }
            ],
            initialReviews: [],
            initialRefund: [],
            initialUnsubscribes: [],
            reviews: [],
            refunds: [],
            unsubscribes: [],
            showReviewsLoading: false,
            menuItems: [
                { title: "Add Reply" },
                { title: "Edit Reply" },
                { title: "Check State" },
                { title: "Delete Reply" }
            ],
            replyDialog: false,
            replyText: "",
            replyIdCurrent: null
        };
    },
    methods: {
        colorRowForReviewFromStore: item => ({
            sea: item.storage == "app"
        }),
        getApplicationList() {
            this.$http
                .get(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications?`
                )
                .then(response => {
                    this.applications = response.data.data.items;

                    console.log(this.applications);
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getCountries() {
            this.$http
                .get(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries?`
                )
                .then(response => {
                    this.initialCountries = response.data;
                    this.fillCountries();
                    console.log("initial countries", this.initialCountries);
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },

        fillCountries() {
            for (let i in this.initialCountries) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        this.initialCountries,
                        i
                    )
                ) {
                    let abbreviations = this.initialCountries[
                        i
                    ].abbreviations.map(item => item.abbreviation);
                    this.countries.push({
                        text: `${this.initialCountries[i].name}`,
                        value: abbreviations
                    });
                }
            }
            console.log(this.countries);
        },
        getReviews(applicationId) {
            this.getReviewsForApplication(applicationId);
            this.getRefundForApplication(applicationId);
            this.getUnsubscribesForApplication(applicationId);
        },
        getReviewsForApplication(applicationId) {
            this.showReviewsLoading = true;

            this.$http
                .get(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/reviews/${applicationId}?`
                )
                .then(response => {
                    this.initialReviews = response.data.data.reviews;
                    this.updateReviewsWithFilters();

                    this.showReviewsLoading = false;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },

        getRefundForApplication(applicationId) {
            this.showReviewsLoading = true;

            this.$http
                .get(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/refunds/${applicationId}?`
                )
                .then(response => {
                    this.initialRefund = response.data.data.refunds;

                    this.updateRefundWithFilters();

                    this.showReviewsLoading = false;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },

        getUnsubscribesForApplication(applicationId) {
            this.showReviewsLoading = true;

            this.$http
                .get(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/unsubscribes/${applicationId}?`
                )
                .then(response => {
                    this.initialUnsubscribes = response.data.data.unsubscribes;

                    this.updateUnsubscribesWithFilters();

                    this.showReviewsLoading = false;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },

        updateWithFilters() {
            this.updateReviewsWithFilters();
            this.updateRefundWithFilters();
        },
        updateRefundWithFilters() {
            this.refunds = [];

            for (let i in this.initialRefund) {
                if (this.selectedCountry !== null) {
                    if (
                        this.selectedCountry.indexOf(
                            this.initialRefund[i].country
                        ) == -1
                    ) {
                        continue;
                    }
                }

                this.refunds.push(this.initialRefund[i]);
            }
        },
        updateReviewsWithFilters() {
            console.log("selected country: ", this.selectedCountry);

            this.reviews = [];

            for (let i in this.initialReviews) {
                if (this.selectedRating !== null) {
                    if (this.selectedRating != this.initialReviews[i].rating) {
                        continue;
                    }
                }

                if (this.selectedStorage !== null) {
                    if (
                        this.selectedStorage != this.initialReviews[i].storage
                    ) {
                        continue;
                    }
                }

                if (this.selectedCountry !== null) {
                    if (
                        this.selectedCountry.indexOf(
                            this.initialReviews[i].country
                        ) == -1
                    ) {
                        continue;
                    }
                }

                this.reviews.push(this.initialReviews[i]);
            }

            this.fillRatingsFromReviews();
            this.fillStorageFromReviews();
            this.fillCountriesFromReviews();
        },

        updateUnsubscribesWithFilters() {
            this.unsubscribes = [];

            for (let i in this.initialUnsubscribes) {
                if (this.selectedCountry !== null) {
                    if (
                        this.selectedCountry.indexOf(
                            this.initialRefund[i].geo
                        ) == -1
                    ) {
                        continue;
                    }
                }

                this.unsubscribes.push(this.initialUnsubscribes[i]);
            }
        },

        fillStorageFromReviews() {
            this.storage = [
                { text: `In-App`, value: "app" },
                { text: `Store`, value: "store" }
            ];
        },
        fillRatingsFromReviews() {
            // let ratingValues = {
            //     star1: 0,
            //     star2: 0,
            //     star3: 0,
            //     star4: 0,
            //     star5: 0,
            // };

            // for (let i in this.initialReviews) {
            //     ratingValues[`star${this.initialReviews[i].rating}`] = ratingValues[`star${this.initialReviews[i].rating}`] + 1;
            // }

            // this.ratings = [
            //     { text: `1-Star (${ratingValues.star1})`, value: 1},
            //     { text: `2-Star (${ratingValues.star2})`, value: 2},
            //     { text: `3-Star (${ratingValues.star3})`, value: 3},
            //     { text: `4-Star (${ratingValues.star4})`, value: 4},
            //     { text: `5-Star (${ratingValues.star5})`, value: 5},
            // ];

            this.ratings = [
                { text: `1-Star`, value: 1 },
                { text: `2-Star`, value: 2 },
                { text: `3-Star`, value: 3 },
                { text: `4-Star`, value: 4 },
                { text: `5-Star`, value: 5 }
            ];
        },
        fillCountriesFromReviews() {
            // let countries = {};

            // for (let i in this.initialReviews) {

            //     const prevValue = countries[this.initialReviews[i].country] || 0;

            //     countries[this.initialReviews[i].country] = prevValue + 1;

            // }

            // this.countries = [];

            // for (let i in this.initialCountries) {
            //     if (Object.prototype.hasOwnProperty.call(this.initialCountries, i)) {
            //         this.countries.push({ text: `${this.initialCountries[i]} (${countries[i] || 0 })`, value: i });
            //     }
            // }

            for (let i in this.initialCountries) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        this.initialCountries,
                        i
                    )
                ) {
                    this.countries.push({
                        text: `${this.initialCountries[i]}`,
                        value: i
                    });
                }
            }
        },
        filterCountries(item, queryText, itemText) {
            console.log(item);
            console.log(item, queryText);
            const textOne = item.text.toLowerCase();
            const textTwo = `${item.value}`;

            const searchText = queryText.toLowerCase();

            return (
                textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            );
        },
        getSelectedApplication() {
            if (this.applicationSelected === null) {
                return null;
            }

            for (let i in this.applications) {
                if (this.applications[i].id === this.applicationSelected) {
                    return this.applications[i];
                }
            }

            return null;
        },
        getAppAverageUserRating() {
            const app = this.getSelectedApplication();
            if (!app) {
                return null;
            }
            return this.getSelectedApplication().average_user_rating || 0;
        },
        getAppUserRatingCount() {
            const app = this.getSelectedApplication();
            if (!app) {
                return null;
            }
            return this.getSelectedApplication().user_rating_count || 0;
        },
        getReviewsAverageUserRating() {
            if (!this.reviews.length) {
                return null;
            }

            let ratings = [];

            for (let i in this.reviews) {
                ratings.push(this.reviews[i].rating);
            }

            const ratingsSum = ratings.reduce((a, b) => a + b);
            const ratingsCount = ratings.length;

            const rating = ratingsSum / ratingsCount;

            return (Math.round(rating * 100) / 100).toFixed(1);
        },
        getReviewsUserRatingCount() {
            return this.reviews.length || null;
        },

        getUnsubscribesCount() {
            return this.unsubscribes.length || null;
        },
        // Обработик меню-гамбургера
        menuHandler(index, customerReviewId, replyOldText) {
            console.log(customerReviewId);
            this.replyIdCurrent = customerReviewId;
            if (index === 1) this.replyText = replyOldText;
            if (index < 2) this.replyDialog = true;
            if (index === 2) this.check();
            if (index === 3) this.delete();
        },
        sendReply() {
            this.showReviewsLoading = true;
            const data = {
                text: this.replyText,
                app: this.applicationSelected
            };
            this.$http
                .post(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/review-response/${this.replyIdCurrent}`,
                    data
                )
                .then(response => {
                    if (!response.data?.result) {
                        this.showReviewsLoading = false;
                        alert("Ошибка публикации ответа");
                        return false;
                    }
                    this.getReviewsForApplication(this.applicationSelected);

                    return true;
                })
                .catch(err => {
                    this.showReviewsLoading = false;
                    alert(converValidationServerErrorsToString(err));
                });
        },
        delete() {
            if (!confirm("Delete review response?")) {
                return;
            }
            this.showReviewsLoading = true;
            this.$http
                .delete(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/review-response/${this.replyIdCurrent}`
                )
                .then(response => {
                    console.log("review response deleted", response.data);
                    this.getReviewsForApplication(this.applicationSelected);
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        check() {
            this.showReviewsLoading = true;
            this.$http
                .get(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/review-response/${this.replyIdCurrent}`
                )
                .then(() => {
                    this.getReviewsForApplication(this.applicationSelected);
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        }
    }
};
</script>
<style>
.sea {
    background: #c4f5ee;
}
.reply-date {
    font-size: 9px;
}
.pointer {
    cursor: pointer;
}
</style>
