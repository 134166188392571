<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>

          <v-card class="pa-4">

            <v-row>
              <v-col cols="1">
                <v-row>
                  <h3>Dictionary</h3>
                </v-row>
                <v-row>
                  <a href="/admin/settings/dictionary/countries">Countries</a>
                </v-row>

              </v-col>
              <v-col cols="1">
                <v-row>
                  <h3>Remote users</h3>
                </v-row>
                <v-row>
                  <a href="/admin/settings/users">Users</a>
                </v-row>

              </v-col>
            </v-row>

          </v-card>

        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>
export default {
    name: "Settings",
}
</script>
<style>
  .t-w-900 {
    width: 900px;
  }
</style>