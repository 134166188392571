<template>
<v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    fullscreen hide-overlay
>

    <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
            <slot></slot>
        </span>
    </template>

    <v-card>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title v-if="isUpdate()">Update Account</v-toolbar-title>
            <v-toolbar-title v-else>New Account</v-toolbar-title>

            <v-spacer/>

            <v-toolbar-items>
                <v-btn @click="dialog = false" dark text>
                    Close
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>

            <v-container fluid>
                <v-row>
                    <v-col cols="6">

                        <v-progress-circular
                            v-if="loading"
                            color="primary"
                            class="mt-4"
                            size="50"
                            indeterminate
                        />

                        <v-form
                            v-else
                            ref="form"
                            v-model="formValid"
                            lazy-validation
                            @submit.prevent="handleSubmit()"
                        >
                            <h2 class="mt-4">Account Settings</h2>

                            <v-checkbox
                                v-model="vendorModel.enabled"
                                label="Enabled"
                            ></v-checkbox>

                            <v-text-field
                                v-model="vendorModel.name"
                                label="Name (Optional)"
                                type="text"
                                @keyup.enter="handleSubmit()"
                            />

                            <v-text-field
                                v-model="vendorModel.number"
                                label="Vendor Number"
                                type="text"
                                @keyup.enter="handleSubmit()"
                                required
                            />

                            <v-text-field
                                v-model="vendorModel.iss"
                                label="Issuer ID"
                                type="text"
                                @keyup.enter="handleSubmit()"
                                required
                            />

                            <v-text-field
                                v-model="vendorModel.kid"
                                label="Key ID"
                                type="text"
                                @keyup.enter="handleSubmit()"
                                required
                            />

                            <v-textarea
                                v-model="vendorModel.p8_key"
                                name=p8_key
                                label="Certificate Key (p8)"
                                value="p8_key"
                                @keyup.enter="handleSubmit()"
                                required
                            />

                            <h2 class="mt-4">Proxy Settings</h2>

                            <v-text-field
                                v-model="vendorModel.proxy_host"
                                label="Proxy Host (IP:PORT)"
                                type="text"
                                @keyup.enter="handleSubmit()"
                                required
                            />

                            <v-text-field
                                v-model="vendorModel.proxy_login"
                                label="Proxy Login"
                                type="text"
                                @keyup.enter="handleSubmit()"
                                required
                            />

                            <v-text-field
                                v-model="vendorModel.proxy_password"
                                label="Proxy Pass"
                                type="text"
                                @keyup.enter="handleSubmit()"
                                required
                            />

                            <v-btn
                                v-if="isUpdate()"
                                class="mt-4"
                                color="success"
                                @click="handleSubmit()"
                                :loading="submitting"
                            >
                                Update
                            </v-btn>

                            <v-btn
                                v-else
                                class="mt-4"
                                color="success"
                                @click="handleSubmit()"
                                :loading="submitting"
                            >
                                Save
                            </v-btn>
                        </v-form>

                    </v-col>
                </v-row>
            </v-container>

        </v-card-text>

    </v-card>
</v-dialog>
</template>

<script>
import {converValidationServerErrorsToString} from "../../utils/errorUtil";

export default {
    name: 'AccountEditDialog',
    props: ['vendorId'],
    components: {
    },
    data() {
        return {
            dialog: false,
            formValid: true,
            loading: false,
            vendorModel: {
                id: null,
                name: null,
                number: null,
                iss: null,
                kid: null,
                p8_key: null,
                proxy_host: null,
                proxy_login: null,
                proxy_password: null,
            },
            submitting: false,
        }
    },
    watch: {
        dialog: {
            immediate: true,
            handler(to, from) {
                if (to === true) {
                    this.fillVendorModel({});
                    if (this.vendorId) {
                        this.loadVendorData(this.vendorId);
                    }
                }
            }
        },
    },
    methods: {
        loadVendorData(vendorId) {
            this.loading = true;
            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/vendors/${vendorId}`)
                .then(response => {
                    this.loading = false;
                    this.fillVendorModel(response.data)
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        },
        fillVendorModel(vendor) {
            this.vendorModel.id = vendor.id;
            this.vendorModel.name = vendor.name;
            this.vendorModel.number = vendor.number;
            this.vendorModel.iss = vendor.iss;
            this.vendorModel.kid = vendor.kid;
            this.vendorModel.p8_key = vendor.p8_key;
            this.vendorModel.proxy_host = vendor.proxy_host;
            this.vendorModel.proxy_login = vendor.proxy_login;
            this.vendorModel.proxy_password = vendor.proxy_password;
            this.vendorModel.enabled = vendor.enabled;
        },
        handleSubmit() {
            let url = `${process.env.VUE_APP_SERVER_BASE_URL}/admin/vendors/`;

            if (this.isUpdate()) {
                url = `${process.env.VUE_APP_SERVER_BASE_URL}/admin/vendors/${this.vendorId}`;
            }

            this.submitting = true;

            this.$http.post(url, {
                vendor: this.vendorModel,
            }).then(resp => {
                this.submitting = false;
                this.$emit('save', this.vendorModel);
                this.dialog = false;
            })
            .catch(err => {
                this.submitting = false;
                alert(converValidationServerErrorsToString(err));
            })
        },
        isUpdate() {
            return !!this.vendorId;
        }
    },
}
</script>

<style scoped>
</style>
