<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col>

                    <v-card class="pa-4">

                        <v-row>
                            <v-col cols="6">
                                <v-form
                                    class="mb-6"
                                    ref="form"
                                    lazy-validation
                                    @submit.prevent="getData"
                                >

                                    <v-select
                                        item-text="name"
                                        item-value="id"
                                        v-model="applicationSelected"
                                        :rules="rules.applicationRules"
                                        :items="applications"
                                        label="Application"
                                        required
                                    ></v-select>

                                  <v-select
                                      v-model="selectedCountry"
                                      :items="countries"
                                      placeholder="Geo"
                                      clearable
                                      :filled="selectedCountry !== null"
                                  ></v-select>


                                    <v-menu
                                        v-model="dateFromMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="dateFrom"
                                                label="Date From"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateFrom"
                                            @input="dateFromMenu = false"
                                        ></v-date-picker>
                                    </v-menu>

                                    <v-menu
                                        v-model="dateToMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="dateTo"
                                                label="Date To"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateTo"
                                            @input="dateToMenu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                    
                                    <v-spacer></v-spacer>

                                    <v-btn
                                        class="mt-2"
                                        depressed
                                        color="primary"
                                        type="submit"
                                    >
                                        Get Data
                                    </v-btn>

                                </v-form>
                            </v-col>
                          <v-col cols="6">
                          </v-col>

                        </v-row>

                        <template v-if="showTabs">
                            <v-tabs class="mb-2" v-model="tab">
                                <v-tab key="events">Events</v-tab>
                                <v-tab key="proceeds">Proceeds</v-tab>
                                <v-tab key="backend">Backend</v-tab>
                                <v-tab key="backend-asc">Backend/ASC</v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item key="events">
                                    <v-data-table
                                        :headers="proceedsHeaders"
                                        :items="installsData"
                                        class="elevation-1"
                                        hide-default-footer
                                    ></v-data-table>
                                    <v-data-table
                                        v-if="isEventsLoading === false"
                                        :headers="eventHeaders"
                                        :items="eventsData"
                                        class="elevation-1"
                                        hide-default-footer
                                    ></v-data-table>
                                    <div class="mt-4 text-center" v-if="isEventsLoading === true">
                                        <v-progress-circular
                                            indeterminate
                                            :size="50"
                                            color="primary"
                                        ></v-progress-circular>
                                    </div>
                                </v-tab-item>

                                <v-tab-item key="proceeds">
                                    <v-data-table
                                        :headers="proceedsHeaders"
                                        :items="proceedsData"
                                        class="elevation-1"
                                        hide-default-footer
                                    ></v-data-table>
                                </v-tab-item>

                                <v-tab-item key="backend">
                                <v-data-table
                                    v-if="isEventsAppLoading === false"
                                    :headers="eventAppHeaders"
                                    :items="eventsAppData"
                                    class="elevation-1"
                                    hide-default-footer
                                    disable-pagination

                                >
                                  <template v-slot:item.src="{ item }">
                                    <a :href="item.src">CSV</a>
                                  </template>
                                </v-data-table>



                                <div class="mt-4 text-center" v-if="isEventsAppLoading === true">
                                  <v-progress-circular
                                      indeterminate
                                      :size="50"
                                      color="primary"
                                  ></v-progress-circular>
                                </div>
                              </v-tab-item>


                              <v-tab-item key="backend-asc">
                                <v-data-table
                                    v-if="isEventsAppLoading === false"
                                    :headers="backendAscHeaders"
                                    :items="backendAsc"
                                    class="elevation-1"
                                    hide-default-footer
                                    disable-pagination

                                >
                                  <template v-slot:item.src="{ item }">
                                    <a :href="item.src">CSV</a>
                                  </template>
                                </v-data-table>



                                <div class="mt-4 text-center" v-if="isEventsAppLoading === true">
                                  <v-progress-circular
                                      indeterminate
                                      :size="50"
                                      color="primary"
                                  ></v-progress-circular>
                                </div>
                              </v-tab-item>
                            </v-tabs-items>
                        </template>

                    </v-card>

                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>
import {objectToQueryString} from "../../../utils/helpers";
import {converValidationServerErrorsToString} from "../../../utils/errorUtil";




export default {
    name: "AdminDataPage",
    mounted() {
        this.getApplicationList();
        this.getCountries();
    },
    components: {
    },
    data() {
        return {
            rules: {
                applicationRules: [
                    v => !!v || 'Application is required',
                ],
            },
            selectedCountry: null,
            tab: null,
            applicationSelected: null,
            applications: [],
            backendAsc: [],
            dateFromMenu: null,
            dateToMenu: null,
            dateFrom: null,
            dateTo: null,
            eventHeaders: [
                { text: 'Type', value: 'type', width: '70%'},
                { text: 'Total', value: 'total' }
            ],
            eventAppHeaders: [
              { text: 'Type', value: 'type', width: '70%'},
              { text: 'Total', value: 'total' },
              { text: 'Src', value: 'src' }
            ],
            proceedsHeaders: [
                { text: 'Type', value: 'type', width: '70%'},
                { text: 'Total', value: 'total' },
            ],
            backendAscHeaders: [
              { text: '', value: 'name'},
              { text: 'Installs', value: 'installs' },
              { text: 'Trials', value: 'trials' },
              { text: 'Subs', value: 'subs' },
              { text: 'Refunds', value: 'refunds' },
            ],
            countries: [],
            initialCountries: [],
            eventsData: [],
            proceedsData: [],
            eventsAppData: [],
            installsData: [],
            showTabs: false,
            isEventsLoading: false,
            isProceedsLoading: false,
            isEventsAppLoading: false,
        }
    },
    methods: {
        fillCountries() {

          for (let i in this.initialCountries) {
            if (Object.prototype.hasOwnProperty.call(this.initialCountries, i)) {
              let abbreviations = this.initialCountries[i].abbreviations.map(item => item.abbreviation);
              this.countries.push({ text: `${this.initialCountries[i].name}`, value: abbreviations});
            }
          }
          console.log(this.countries);
        },

        getCountries() {
          this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries`)
              .then((response) => {
                this.initialCountries = response.data;
                this.fillCountries();
                console.log('initial countries', this.initialCountries);
              })
              .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getApplicationList() {
            let q = objectToQueryString(this.queryParams);

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications?${q}`)
                .then(response => this.applications = response.data.data.items)
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getData() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            const q = objectToQueryString({
                started_at: this.dateFrom,
                finished_at: this.dateTo,
                country_key: this.selectedCountry != null ? this.selectedCountry : null,
            });

            this.isEventsLoading = true;
            this.isProceedsLoading = true;
            this.showTabs = true;

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.applicationSelected}/events?${q}`)
                .then((response) => {
                    this.eventsData = this.formatEventsFromResponse(response.data.data.events);
                    console.log(response.data.data.events);
                    this.isEventsLoading = false;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.applicationSelected}/proceeds?${q}`)
                .then((response) => {
                    this.proceedsData = this.formatProceedsFromResponse(response.data.data.proceeds);
                    this.installsData = this.formatInstallsFromResponse(response.data.data.proceeds);

                    this.isProceedsLoading = false;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));

          this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.applicationSelected}/events-from-app?${q}`)
              .then((response) => {
                console.log(response.data.data.events);
                this.eventsAppData = this.formatEventAppFromResponse(response.data.data.events);
              })
              .catch(err => alert(converValidationServerErrorsToString(err)));

            this.getBackendASC();
        },

        getBackendASC() {
            const q = objectToQueryString({
              started_at: this.dateFrom,
              finished_at: this.dateTo,
              country_key: this.selectedCountry != null ? this.selectedCountry : null,
            });

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.applicationSelected}/events-from-app-asc?${q}`)
                .then((response) => {
                  console.log(response.data.data);
                  this.backendAsc = response.data.data;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        formatEventsFromResponse(events) {
            let result = [];

            for (const key in events) {
                if (Object.prototype.hasOwnProperty.call(events, key)) {

                    result.push({
                        type: key,
                        total: events[key],
                    });
                }
            }

            return result;
        },
        formatProceedsFromResponse(proceeds) {
            let result = [];

            for (const key in proceeds) {
                if (Object.prototype.hasOwnProperty.call(proceeds, key) && !this.isInstallKey(key)) {
                    result.push({
                        type: this.capitalizeFirstLetter(key),
                        total: proceeds[key],
                    });
                }
            }

            return result;
        },

        formatEventAppFromResponse(events) {
          let result = [];

          for (const key in events) {
            if (Object.prototype.hasOwnProperty.call(events, key) && !this.isInstallKey(key)) {
              var query = objectToQueryString({
                event: key,
                token: localStorage.getItem('token'),
                started_at: this.dateFrom,
                finished_at: this.dateTo,
                country_key: this.selectedCountry != null ? this.selectedCountry : null,
              });

              result.push({
                type: this.capitalizeFirstLetter(key),
                total: events[key],
                src: `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.applicationSelected}/events-from-app/csv?${query}`
              });
            }
          }

          return result;
        },
        formatInstallsFromResponse(proceeds) {
          let result = [];

          for (const key in proceeds) {
            if (Object.prototype.hasOwnProperty.call(proceeds, key) && this.isInstallKey(key)) {
              result.push({
                type: this.capitalizeFirstLetter(key),
                total: proceeds[key],
              });
            }
          }

          return result;
        },

        isInstallKey(key) {
            if(key == 'installs' || key == 're-installs' || key == 'updates') {
                return true;
            }
            return false;
        },
        capitalizeFirstLetter(string) {
            //return string.charAt(0).toUpperCase() + string.slice(1);
          return string;
        },
    },
}
</script>
