<template>
<v-row>
    <v-col cols="12">
        <v-card>

            <v-tabs class="mb-0" v-model="tab">
                <v-tab to="/admin/applications" exact>Apps</v-tab>
                <v-tab href="#vendors">Accounts</v-tab>
            </v-tabs>

            <TabVendors />

        </v-card>

    </v-col>
</v-row>
</template>

<script>

    import TabVendors from "./parts/TabVendors.vue";

    export default {
        name: "AdminVendorsPage",
        data() {
            return {
                tab: 'vendors',
            }
        },
        components: {
            TabVendors,
        },
    }
</script>

<style scoped>
</style>
