<template>
    <v-container class="col-md-6 offset-md-3">
        <v-card>
            <v-card-title>
                Home
            </v-card-title>

            <v-card-text>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    A accusamus amet asperiores at distinctio dolore,
                    expedita ipsam officiis quo quod similique tempore voluptas.
                    Aliquam blanditiis eius nostrum perspiciatis, provident voluptates.
                </p>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        name: "HomePage"
    }
</script>

<style scoped>

</style>
