<template>
    <v-avatar v-if="status === 'ok'" size="10px" color="green"></v-avatar>
    <v-avatar v-else-if="status === 'error'" size="10px" color="red"></v-avatar>
    <v-avatar v-else size="10px" color="grey"></v-avatar>
</template>

<script>
    export default {
        name: "ApplicationProxyStatus",
        props: ['status'],
        data() {
            return {
            }
        },
        mounted() {
        },
        created() {
        },
        methods: {
        }
    }
</script>