const queryString = require('query-string');

const getWhatsappLinkByPhone = phone => {
    let phoneModify = phone
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('+', '')
        .replaceAll(' ', '')

    return `https://api.whatsapp.com/send?phone=${phoneModify}`
}

const removeEmptyOrNullFromObject = (obj) => {
    if (!obj) {
        return {};
    }
    Object.keys(obj).forEach(k =>
      (obj[k] && typeof obj[k] === 'object') && removeEmptyOrNullFromObject(obj[k]) ||
      (!obj[k] && obj[k] !== undefined) && delete obj[k]
    );
    return obj;
};

const objectToQueryString = data => {
    return queryString.stringify(removeEmptyOrNullFromObject(data), {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
    })
}

export  {getWhatsappLinkByPhone, objectToQueryString, removeEmptyOrNullFromObject}
