<template>
    <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="360px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="$props.parser" class="ml-2" color="orange" v-bind="attrs" v-on="on" fab x-small dark>
                <v-icon aria-hidden="false">mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-else class="ml-2" color="info" v-bind="attrs" v-on="on" fab x-small dark>
                <v-icon aria-hidden="false">mdi-plus</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-toolbar dark color="primary">

                <v-toolbar-title v-if="$props.parser">Edit Parser</v-toolbar-title>
                <v-toolbar-title v-else>Add Parser</v-toolbar-title>

                <v-spacer/>

                <v-toolbar-items>
                    <v-btn @click="dialog = false" dark text>
                        Close
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col>

                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                                @submit.prevent="handleSubmit"
                            >
                                <v-text-field
                                    v-model="app.id"
                                    label="App ID"
                                    type="text"
                                />
                                <v-text-field
                                    v-model="app.name"
                                    label="Name (Optional)"
                                    type="text"
                                />
                                <v-btn
                                    :disabled="!valid"
                                    color="info"
                                    @click="handleSubmit"
                                    :loading="loading"
                                    block
                                >
                                    <span v-if="$props.parser">Update Parser</span>
                                    <span v-else>Save Parser</span>
                                </v-btn>
                            </v-form>

                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-divider/>
        </v-card>
    </v-dialog>
</template>

<script>

    import {converValidationServerErrorsToString} from "../../utils/errorUtil";

    export default {
        name: "ManualReviewsParserControlDialog",
        props: ['parser'],
        data() {
            return {
                dialog: null,
                valid: null,
                loading: false,
                app: {
                    id: null,
                    name: null,
                },
            }
        },
        mounted() {
            console.log('asd');
        },
        created() {
            const localParser = Object.assign({}, (this.$props.parser || {}));
            this.app.id = localParser.app_id || null;
            this.app.name = localParser.app_name || null;
        },
        methods: {
            handleSubmit() {
                this.loading = true;

                this.$http.post(this.getRequestUrl(), this.app).then(response => {
                    // this.dialog = false;
                    this.$router.go();
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
            },
            getRequestUrl()
            {
                if (this.$props.parser) {
                    return `${process.env.VUE_APP_SERVER_BASE_URL}/review-parser/${this.$props.parser.id}`;
                }
                return `${process.env.VUE_APP_SERVER_BASE_URL}/review-parser`;
            }
        },
    }
</script>