<template>
    <v-container fluid>
        <div class="admin-hello">
            <section>
                <router-view/>
            </section>
        </div>
    </v-container>
</template>

<script>
    export default {
        name: 'AdminPage',
        data() {
            return {
                msg: 'Админка'
            }
        }
    }
</script>

<style scoped>

</style>
