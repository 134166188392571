<template>
    <v-container fluid>
        <v-row>
            <v-col>

                <v-card class="pa-4">

                    <v-row>
                        <v-col cols="6">
                            <v-form
                                class="mb-6"
                                ref="form"
                                lazy-validation
                                @submit.prevent="getData()"
                            >

                                <v-select
                                    item-text="name"
                                    item-value="id"
                                    v-model="applicationSelected"
                                    :items="applications"
                                    label="Application"
                                    :rules="rules.applicationRules"
                                    @change="onApplicationChange()"
                                ></v-select>
                                <v-select
                                    item-text="name"
                                    item-value="id"
                                    v-model="subscriptionSelected"
                                    :items="subscriptions"
                                    label="Subscription Name"
                                    multiple
                                    chips
                                    clearable
                                ></v-select>
                                <v-select
                                    v-model="selectedCountry"
                                    :items="countries"
                                    label="Geo"
                                    multiple
                                    chips
                                    clearable
                                ></v-select>
                                <v-btn
                                    class="mt-2"
                                    depressed
                                    color="primary"
                                    type="submit"
                                >
                                    Get Data
                                </v-btn>
                            </v-form>
                        </v-col>
                    </v-row>

                    <v-data-table
                        :headers="dataHeaders"
                        :sort-by="'leads'"
                        :sort-desc="true"
                        :items="data"
                        :items-per-page="50"
                        class="elevation-0 no-wrap"
                        :loading="isDataLoading === true"
                        loading-text="Loading... Please wait"
                    >
                        <template slot="body.prepend">
                            <tr v-if="data.length">
                                <th colspan="3">Total</th>
                                <th>{{ total.proceeds }}</th>
                                <th>{{ total.first_paid }}</th>
                                <th>{{ total.rebills }}</th>
                                <th>{{ formatDecimals(total.arpi, 2) }}</th>
                                <th>{{ formatDecimals(total.arpt, 2) }}</th>
                                <th>{{ formatDecimals(total.arps, 2) }}</th>
                                <th>{{ total.proceeds_1m }}</th>
                                <th>{{ formatDecimals(total.arpt_1m, 2) }}</th>
                                <th>{{ formatDecimals(total.arps_1m, 2) }}</th>
                                <th>{{ formatDecimals(total.arpt_3m, 2) }}</th>
                                <th>{{ formatDecimals(total.arps_3m, 2) }}</th>
                                <th>{{ formatDecimals(total.arpt_6m, 2) }}</th>
                                <th>{{ formatDecimals(total.arps_6m, 2) }}</th>
                                <th>{{ total.installs }}</th>
                                <th>{{ total.leads }}</th>
                                <th>{{ total.trials }}</th>
                                <th>{{ total.subs }}</th>
                                <th>{{ formatDecimals(total.ins_t, 1) }}&nbsp;%</th>
                                <th>{{ formatDecimals(total.t_s, 1) }}&nbsp;%</th>
                                <th>{{ formatDecimals(total.i_s, 1) }}&nbsp;%</th>
                            </tr>
                        </template>
                        <template v-slot:item.arpi="{ item }">
                            <div>{{ formatDecimals(item.arpi, 2) }}</div>
                        </template>
                        <template v-slot:item.arpt="{ item }">
                            <div>{{ formatDecimals(item.arpt, 2) }}</div>
                        </template>
                        <template v-slot:item.arps="{ item }">
                            <div>{{ formatDecimals(item.arps, 2) }}</div>
                        </template>
                        <template v-slot:item.arpt_1m="{ item }">
                            <div>{{ formatDecimals(item.arpt_1m, 2) }}</div>
                        </template>
                        <template v-slot:item.arps_1m="{ item }">
                            <div>{{ formatDecimals(item.arps_1m, 2) }}</div>
                        </template>
                        <template v-slot:item.arpt_3m="{ item }">
                            <div>{{ formatDecimals(item.arpt_3m, 2) }}</div>
                        </template>
                        <template v-slot:item.arps_3m="{ item }">
                            <div>{{ formatDecimals(item.arps_3m, 2) }}</div>
                        </template>
                        <template v-slot:item.arpt_6m="{ item }">
                            <div>{{ formatDecimals(item.arpt_6m, 2) }}</div>
                        </template>
                        <template v-slot:item.arps_6m="{ item }">
                            <div>{{ formatDecimals(item.arps_6m, 2) }}</div>
                        </template>
                        <template v-slot:item.ins_t="{ item }">
                            <div>{{ formatDecimals(item.ins_t, 1) }}&nbsp;%</div>
                        </template>
                        <template v-slot:item.t_s="{ item }">
                            <div>{{ formatDecimals(item.t_s, 1) }}&nbsp;%</div>
                        </template>
                        <template v-slot:item.i_s="{ item }">
                            <div>{{ formatDecimals(item.i_s, 1) }}&nbsp;%</div>
                        </template>
                    </v-data-table>

                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<style>
    .no-wrap td {
        white-space: nowrap !important;
    }
</style>


<script>

import {objectToQueryString} from "../../../utils/helpers";
import {converValidationServerErrorsToString} from "../../../utils/errorUtil";

export default {
    name: "AdminDashboardPage",
    created() {

    },
    mounted() {
        this.getApplicationList();
        this.getCountries();
    },
    data() {
        return {
            rules: {
                applicationRules: [
                    v => !!v || 'Application is required',
                ],
            },
            applications: [],
            applicationSelected: null,
            subscriptions: [],
            subscriptionSelected: null,
            countries: [],
            selectedCountry: null,
            dataHeaders: [
                { text: 'Tier', value: 'tier'},
                { text: 'Country', value: 'country' },
                { text: 'Subscription', value: 'subscription' },
                { text: 'Proceeds', value: 'proceeds' },
                { text: 'First Paid', value: 'first_paid' },
                { text: 'Rebills', value: 'rebills' },
                { text: 'ARPI', value: 'arpi' },
                { text: 'ARPT', value: 'arpt' },
                { text: 'ARPS', value: 'arps' },
                { text: 'Proceeds 1M', value: 'proceeds_1m' },
                { text: 'ARPT 1M', value: 'arpt_1m' },
                { text: 'ARPS 1M', value: 'arps_1m' },
                { text: 'ARPT 3M', value: 'arpt_3m' },
                { text: 'ARPS 3M', value: 'arps_3m' },
                { text: 'ARPT 6M', value: 'arpt_6m' },
                { text: 'ARPS 6M', value: 'arps_6m' },
                { text: 'Installs', value: 'installs' },
                { text: 'Leads', value: 'leads' },
                { text: 'Trials', value: 'trials' },
                { text: 'Subs', value: 'subs' },
                { text: 'Ins-T %', value: 'ins_t' },
                { text: 'T-S %', value: 't_s' },
                { text: 'I-S%', value: 'i_s' },
            ],
            data: [],
            total: {},
            isDataLoading: false,
        }
    },
    methods: {
        getApplicationList() {
            let q = objectToQueryString(this.queryParams);

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications?${q}`)
                .then(response => this.applications = response.data.data.items)
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getSubscriptions(applicationId) {
            let q = objectToQueryString(this.queryParams);

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/subscriber/subscriptions/${applicationId}?${q}`)
                .then(response => this.subscriptions = response.data.subscriptions)
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getCountries() {
          this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries`)
              .then((response) => {
                this.initialCountries = response.data;
                this.fillCountries();
                console.log('initial countries', this.initialCountries);
              })
              .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        fillCountries() {
          for (let i in this.initialCountries) {
            if (Object.prototype.hasOwnProperty.call(this.initialCountries, i)) {
              let abbreviations = this.initialCountries[i].abbreviations.map(item => item.abbreviation);
              this.countries.push({ text: `${this.initialCountries[i].name}`, value: abbreviations});
            }
          }
          console.log(this.countries);
        },
        onApplicationChange() {
            this.subscriptionSelected = null;
            this.subscriptions = [];
            this.getSubscriptions(this.applicationSelected);
        },
        getData() {
            if (!this.applicationSelected) {
                return;
            }
            this.isDataLoading = true;

            const postData = {
                subscriptions: this.subscriptionSelected,
                geo: this.selectedCountry,
            };

            this.$http.post(
                `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.applicationSelected}/statistic/summary-by-events?`,
                postData
            )
                .then(response => {
                    this.isDataLoading = false;
                    this.data = response.data.data.items;
                    this.total = response.data.data.total;
                 })
                .catch(err => {
                    this.isDataLoading = false;
                    alert(converValidationServerErrorsToString(err));
                });
        },
        formatDecimals(num, decimals) {
            return (Math.round(num * 100) / 100).toFixed(decimals);
        },
    }
}
</script>