<template>
<v-row>
    <v-col cols="12">
        
    <v-card>

        <v-tabs class="mb-0" v-model="tab">
            <v-tab href="#apps">Apps</v-tab>
            <v-tab to="/admin/applications/vendors">Accounts</v-tab>
        </v-tabs>

        <TabApps />

    </v-card>

    </v-col>
</v-row>
</template>

<script>

    import TabApps from "./parts/TabApps.vue";

    export default {
        name: "AdminApplicationsPage",
        data() {
            return {
                tab: 'apps',
            }
        },
        components: {
            TabApps,
        },
    }
</script>

<style scoped>
</style>
