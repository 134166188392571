<template>
    <div>
        <v-chip
            v-if="!enabled"
            :class="getChipClasses(enabled)"
            color="gray"
            text-color="black"
            small
        >
            Disabled
        </v-chip>

        <v-chip
            v-else-if="!latestReport"
            :class="getChipClasses(enabled)"
            color="gray"
            text-color="black"
            small
        >
            Never Synced
        </v-chip>

        <v-chip
            v-else-if="latestReport && latestReport.status === 'waiting'"
            :class="getChipClasses(enabled)"
            color="light-blue"
            text-color="white"
            small
        >
            {{ formatDate(latestReport.created_at) }}
        </v-chip>

        <v-chip
            v-else-if="latestReport && latestReport.status === 'started'"
            :class="getChipClasses(enabled)"
            color="light-blue"
            text-color="white"
            small
        >
            {{ formatDate(latestReport.started_at) }}
            <v-progress-linear
                indeterminate
                color="light-blue lighten-4"
                absolute
                bottom
            ></v-progress-linear>
        </v-chip>

        <v-chip
            v-else-if="latestReport && latestReport.status === 'completed'"
            :class="getChipClasses(enabled)"
            color="green"
            text-color="white"
            small
        >
            {{ formatDate(latestReport.completed_at) }}
        </v-chip>

        <v-tooltip
            v-else-if="latestReport && latestReport.status === 'failed'"
            bottom
            color="error"
            max-width="400"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-chip
                    :class="getChipClasses(enabled)"
                    color="red"
                    text-color="white"
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                    {{ formatDate(latestReport.failed_at) }}
                </v-chip>
            </template>
            <span>(id={{ latestReport.id }}) {{ latestReport.fail_message }}</span>
        </v-tooltip>

        <!-- <v-btn
            class="ml-1 cursor-pointer"
            v-if="application.waiting_report"
            icon
            color="info"
        >
            <v-icon>
                mdi-progress-clock
            </v-icon>
        </v-btn> -->

        <!-- <v-btn
            class="ml-1"
            @click="sync"
            v-if="showSync()"
            :disabled="!(canSync() && isSyncing() === false)"
            text
            icon
            color="black"
            x-small
        >
            <v-icon>
                mdi-sync
            </v-icon>
        </v-btn> -->
    </div>
</template>

<style scoped>
    .cursor-pointer {
        cursor: default !important;
    }

    .chip-style {
        min-width: 100px;
        justify-content: center;
    }

    .chip-disabled {
        opacity: 0.4;
    }
</style>

<script>
    import {converValidationServerErrorsToString} from "../../utils/errorUtil";
    import {eventBus, eventBusEvents} from "../../eventBus/eventBus";
    import {convertUtcToDate} from "../../utils/converters"

    const queryString = require('query-string');

    export default {
        name: "ApplicationReportStatus",
        props: ['application', 'latestReport', 'enabled'],
        data() {
            return {
                // syncing: false,
            }
        },
        mounted() {
        },
        created() {
            eventBus.$on(eventBusEvents.EVENT_REFRESH_APPLICATION_BY_ADMIN, async () => {
                // this.syncing = false;
            });
        },
        methods: {
            // showSync() {
            //     return !this.application.waiting_report;
            // },
            // canSync() {
            //     return this.application.enabled && ((!this.application.waiting_report
            //         && this.application.latest_report
            //         && this.application.latest_report.status !== 'waiting'
            //         && this.application.latest_report.status !== 'started'
            //     ) || (!this.application.waiting_report && !this.application.latest_report));
            // },
            // sync() {
            //     this.syncing = true;

            //     let q = queryString.stringify({
            //         // stub: 1,
            //     }, {
            //         arrayFormat: 'bracket',
            //         skipNull: true,
            //         skipEmptyString: true
            //     });

            //     this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.application.id}/parse?${q}`)
            //         .then(response => {
            //             this.refresh();
            //         })
            //         .catch(err => this.refresh())
            //         // .catch(err => alert(converValidationServerErrorsToString(err)))
            //         .then(() => {
            //             this.refresh();
            //         });

            // },
            // refresh() {
            //     setTimeout(() => {
            //         eventBus.$emit(eventBusEvents.EVENT_REFRESH_APPLICATION_BY_ADMIN);
            //     }, 3000);
            // },
            // isSyncing() {
            //     return this.syncing;
            // },
            formatDate(date) {
                return convertUtcToDate(date, 'Europe/Moscow', 'D MMM HH:mm');
            },
            getChipClasses(enabled) {
                let classes = ['chip-style'];
                if (!enabled) {
                    classes.push('chip-disabled');
                }
                return classes;
            }
        },
    }
</script>