const moment = require('moment-timezone');

moment.tz.setDefault('UTC');

const convertUtcToDate = (utcDate, tz = null, format = null) => {
    let data = tz ? moment(utcDate).tz(tz) : moment(utcDate)
    // return data.format("DD-MM-YYYY")
    format = format || "DD.MM.YYYY HH:mm";
    return data.format(format);
}
export {convertUtcToDate}
