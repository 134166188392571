<template>
    <v-dialog
        v-model="dialog"
        max-width="1200px"
        content-class="mailbox-dialog"
    >
        <div class="d-flex flex-row" v-if="!isLoading && selectedMessage.messageId">
            <div class="conversation">
                <div
                    class="conversation__item"
                    v-for="(item, i) in conversation" :key="i"
                    :class="{active: isActive(item.message_id)}"
                    @click="showMessage(item.message_id)"
                >
                    <div class="conversation__date">{{ item.date }}</div>
                    <div class="conversation__message">
                        <div>{{ item.subject }}</div>
                        <div>{{ item.body_cleared }}</div>
                    </div>
                    <div class="reply mt-2" v-if="item.reply">
                        <v-icon class="mr-1" size="16" color="black">mdi-arrow-u-right-bottom-bold</v-icon>
                        <span class="reply__date">{{ item.reply.date }}</span>
                        <div class="reply__message">{{ item.reply.reply_message_cleared }}</div>
                    </div>
                </div>
            </div>
            <v-card
                flat
                class="mail"
                style="d-flex flex-column fill-height flex-grow-1"
            >
                <div class="mail__inner d-flex flex-column fill-height">
                    <v-card-title class="d-flex">
                        <div class="d-flex flex-column align-start">
                            <div>{{ selectedMessage.subject }}</div>
                            <div style="color: gray; line-height: 1;"><small>{{ selectedMessage.sender }}</small></div>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialog = false">Close</v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                        ref="content"
                        class="overflow-y-auto pt-4"
                        style="height: 100%;"
                    >
                        <div class="pt-4" v-if="!selectedMessage.reply">
                            <v-btn
                                class="mb-4"
                                v-if="!showReplyForm"
                                depressed
                                color="primary" 
                                @click.prevent="showReplyForm = true"
                            >
                                Reply
                            </v-btn>
                            <div class="mb-4" v-if="showReplyForm">
                                <v-textarea
                                    ref="replyMessage"
                                    filled
                                    label="Reply Message"
                                    rows="4"
                                    v-model="replyMessage"
                                    :readonly="isSendingReply"
                                ></v-textarea>
                                <v-btn
                                    depressed
                                    color="primary"
                                    class="mr-4"
                                    @click.prevent="sendReply()"
                                    :loading="isSendingReply"
                                >
                                    Send reply
                                </v-btn>
                                <v-btn depressed outlined color="secondary" @click.prevent="showReplyForm = false" v-show="!isSendingReply">Cancel</v-btn>
                            </div>
                        </div>
                        <div class="pt-4" v-else>
                            <v-alert
                                color="#f2f2f2"
                                icon="mdi-arrow-u-right-bottom-bold"
                                dense
                            >
                                <div><strong>{{ selectedMessage.reply.date }}</strong></div>
                                <div v-html="selectedMessage.reply.reply_message"></div>
                            </v-alert>
                        </div>
                        <iframe :srcdoc="selectedMessage.bodyHtml" frameBorder="0" style="overflow: hidden; width: 100%; height: 100%;" width="100%" height="100%" @load="resizeIframe()" ref="iframe"></iframe>
                    </v-card-text>
                </div>
            </v-card>
        </div>
        <div
            class="d-flex justify-center align-center fill-height"
            v-if="isLoading"
        >
            <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </div>
    </v-dialog>
</template>

<style>
    .mailbox-dialog {
        position: relative;
        overflow: hidden;
        height: 100%;
        background-color: #fff;
    }

    .conversation {
        position: absolute;
        top: 0;
        left: 0;
        width: 300px;
        height: 100%;
        overflow-y: auto;
        z-index: 2;
    }

    .conversation__item {
        padding: 15px 20px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
    }

    .conversation__item.active,
    .conversation__item:hover {
        background-color: #f2f2f2;
    }

    .conversation__date {
        font-size: 80%;
        color: #666;
    }

    .conversation__message {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 90%;
    }

    .reply__date {
        font-weight: bold;
        font-size: 80%;
    }

    .reply__message {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 90%;
        font-weight: bold;
    }

    .mail {
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 300px;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 0 4px 4px 0 !important;
    }

    .mail__inner {
        position: relative;
        width: 100%;
        height: 100%;
        border-left: 1px solid #ccc;
    }

    .overflow-100vh {
        overflow: hidden;
        height: 100vh;
    }
</style>

<script>
    import {converValidationServerErrorsToString} from "../../utils/errorUtil";

    export default {
        name: "MailboxMailDialog",
        props: ['show', 'applicationId', 'with', 'messageId'],
        data() {
            return {
                dialog: false,
                isLoading: false,
                conversation: [],
                selectedMessage: {},
                showReplyForm: false,
                replyMessage: '',
                isSendingReply: false,
            }
        },
        watch: { 
            show (val) {
                this.dialog = val;
            },
            dialog (val) {
                if (val) {
                    this.onDialogShow();
                    console.log('dialog opened');
                } else {
                    this.onDialogClose();
                    this.$emit('onClose');
                    console.log('dialog closed');
                }
            }
        },
        mounted() {
        },
        created() {
        },
        methods: {
            onDialogShow() {
                console.log('selectedMessage: ', this.selectedMessage);
                this.loadConversation(this.$props.with, this.$props.messageId);
            },
            onDialogClose() {
                console.log('onDialogClose');
                this.selectedMessage = {};
                this.conversation = [];
            },
            loadConversation(email, selectedMessageId, callback) {
                callback = callback || function () {};
                this.isLoading = true;
                const applicationId = this.$props.applicationId;
                this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/mailbox/${applicationId}/conversation?with=${email}`)
                    .then((response) => {
                        console.log('conversation: ', response.data.conversation);
                        this.conversation = response.data.conversation;
                        this.isLoading = false;
                        callback();
                        this.showMessage(selectedMessageId);
                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)));
            },
            showMessage(messageId) {
                console.log('showSelectedMessage', messageId);
                for (let i in this.conversation) {
                    const message = this.conversation[i];
                    if (message.message_id == messageId) {
                        this.selectedMessage = {
                            messageId: message.message_id,
                            subject: message.subject,
                            sender: message.email,
                            bodyHtml: message.body,
                            reply: message.reply,
                        };
                        setTimeout(() => {
                            this.$refs.content.scrollTo(0, 0);
                        }, 50);
                    }
                }
            },
            isActive(messageId) {
                return this.selectedMessage && this.selectedMessage.messageId == messageId;
            },
            sendReply() {

                this.isSendingReply = true;

                const applicationId = this.$props.applicationId;

                const data = {
                    messageId: this.selectedMessage.messageId,
                    replyMessage: this.replyMessage,
                };

                this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/mailbox/${applicationId}/reply`,
                    data
                ).then(resp => {
                    console.log('sendReply response:', resp.data);
                    if (resp.data.error) {
                        alert(resp.data.error);
                        this.resetReplyForm();
                        return;
                    }
                    this.afterReply();
                })
                .catch(err => {
                    alert(converValidationServerErrorsToString(err));
                    this.resetReplyForm();
                });
            },
            afterReply() {
                this.loadConversation(this.$props.with, this.selectedMessage.messageId, () => {
                    this.resetReplyForm();
                });
            },
            resetReplyForm() {
                    this.showReplyForm = false;
                    this.replyMessage = '';
                    this.isSendingReply = false;
            },
            resizeIframe() {
                this.$refs.iframe.style.height = this.$refs.iframe.contentWindow.document.documentElement.scrollHeight + 'px';
            }
        },
    }

</script>