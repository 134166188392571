<template>
    <span>

        <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen hide-overlay>

            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                    <slot></slot>
                </span>
            </template>

            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-toolbar-title>
                        Application
                    </v-toolbar-title>

                    <v-spacer/>

                    <v-toolbar-items>
                        <v-btn @click="dialog = false" dark text>
                            Закрыть
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-row>
                    <v-col>
                        <ApplicationRemotePanel
                            :action="action"
                            :application="application"
                        />
                    </v-col>
                </v-row>

                <v-divider/>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
    import ApplicationRemotePanel from "./ApplicationRemotePanel";

    export default {
        name: 'ApplicationRemoteBackendEditDialog',
        props: ['application', 'action'],
        components: {
            ApplicationRemotePanel,
        },
        data() {
            return {
                dialog: false
            }
        },
        computed: {
        },
        created() {
        },
        methods: {
            close() {
                this.dialog = false
            }
        },
    }
</script>

<style scoped>

</style>
