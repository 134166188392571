<template>
<div>
    <v-card-title style="align-items: center;">

        <VendorEditDialog @save="loadVendors()">
            <v-btn color="info" depressed>
                <v-icon>mdi-plus</v-icon>Account
            </v-btn>
        </VendorEditDialog>

        <v-spacer/>

        <v-row>
            <v-col cols="9">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search accounts / vendors / apps"
                    single-line
                    hide-details
                    clearable
                />
            </v-col>
            <v-col cols="3">
                <v-select
                    label="Status"
                    :items="filterStatusItems"
                    item-text="text"
                    item-value="value"
                    v-model="filterStatus"
                ></v-select>
            </v-col>
        </v-row>

    </v-card-title>

    <v-card-text>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="getVendorsData()"
            :sort-by="['id']"
            :sort-desc="[true]"
            :item-class="null"
            hide-default-footer
            disable-pagination
            :loading="loading"
        >
            <template v-slot:item.name="{ item }">
                <div class="vendor-name">
                    <div class="vendor-name__line1 mt-1">{{ getVendorName(item) }}</div>
                    <div class="vendor-name__line2">
                        Vendor: #{{ item.number }}
                    </div>
                    <div class="vendor-name__line3 mt-1 mb-2">
                        <template v-if="item.applicationList.length">
                            <span
                                v-for="app in item.applicationList"
                                :key="app.id"
                                class="mr-1"
                            >
                                <v-chip x-small>{{ app.name }}</v-chip>
                            </span>
                        </template>
                        <v-chip v-else x-small color="black" text-color="white">No apps</v-chip>
                    </div>

                </div>
            </template>

            <template v-slot:item.proxy_status="{ item }">
                <div class="pl-2">
                    <ApplicationProxyStatus :status="item.proxy_status || null" />
                </div>
            </template>

            <template v-slot:item.last_sync_summary_sales_job="{ item }">
                <ApplicationReportStatus :application="{}" :latestReport="item.last_sync_summary_sales_job" :enabled="!!item.applicationList.length" />
            </template>

            <template v-slot:item.last_sync_subscription_event_job="{ item }">
                <ApplicationReportStatus :application="{}" :latestReport="item.last_sync_subscription_event_job" :enabled="!!item.applicationList.length" />
            </template>

            <template v-slot:item.last_sync_subscriber_job="{ item }">
                <ApplicationReportStatus :application="{}" :latestReport="item.last_sync_subscriber_job" :enabled="!!item.applicationList.length" />
            </template>

            <template v-slot:item.actions="{ item }">
                <VendorEditDialog
                    :vendorId="item.id"
                    @save="loadVendors()"
                >
                    <v-btn
                        fab
                        x-small
                        depressed
                        class="info"
                    >
                        <v-icon aria-hidden="false">
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </VendorEditDialog>
            </template>

        </v-data-table>
    </v-card-text>
</div>
</template>

<style scoped>
    .vendor-name__line1 {
        font-size: 14px;
        line-height: 20px;
    }

    .vendor-name__line2 {
        font-size: 12px;
        line-height: 12px;
        color: #999;
    }

    .vendor-name__line3 {
        font-size: 12px;
        line-height: 12px;
    }
</style>

<script>
    import {converValidationServerErrorsToString} from "../../../../utils/errorUtil";
    import {eventBus, eventBusEvents} from "../../../../eventBus/eventBus";
    import {crudActions} from "../../../../enums/enums";

    import VendorEditDialog from "../../../../components/Vendor/VendorEditDialog.vue";
    import ApplicationProxyStatus from "../../../../components/Application/ApplicationProxyStatus.vue";
    import ApplicationReportStatus from "../../../../components/Application/ApplicationReportStatus.vue";

    export default {
        name: "TabVendors",
        components: {
            VendorEditDialog,
            ApplicationProxyStatus,
            ApplicationReportStatus,
        },
        data: function () {
            return {
                loading: false,
                search: '',
                filterStatusItems: [],
                filterStatus: 'enabled',
                headers: [
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: true,
                        value: 'id',
                    },
                    {
                        text: 'Account',
                        align: 'start',
                        sortable: true,
                        value: 'name',
                    },
                    {
                        text: 'Proxy',
                        align: 'start',
                        sortable: true,
                        value: 'proxy_status',
                    },
                    {
                        text: 'AppStore Sales',
                        align: 'start',
                        sortable: false,
                        value: 'last_sync_summary_sales_job',
                    },
                    {
                        text: 'AppStore Events',
                        align: 'start',
                        sortable: false,
                        value: 'last_sync_subscription_event_job',
                    },
                    {
                        text: 'AppStore Subs',
                        align: 'start',
                        sortable: false,
                        value: 'last_sync_subscriber_job',
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'actions',
                        width: '20%'
                    },
                ],
                vendors: [],
            }
        },
        mounted() {
            this.loadVendors();
        },
        methods: {
            loadVendors() {
                this.loading = true;
                this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/vendors`)
                    .then(response => {
                        this.loading = false;
                        this.vendors = response.data;

                        this.updateFilterStatusItems();
                    })
                    .catch(err => {
                        this.loading = false;
                        alert(converValidationServerErrorsToString(err));
                    });
            },
            updateFilterStatusItems() {
                let countEnabled = 0;
                let countDisabled = 0;

                for (let i in this.vendors) {
                    const vendor = this.vendors[i];
                    if (vendor.enabled == 1) {
                        countEnabled++;
                    }
                }

                for (let i in this.vendors) {
                    const vendor = this.vendors[i];
                    if (vendor.enabled == 0) {
                        countDisabled++;
                    }
                }

                this.filterStatusItems = [
                    { text: `Enabled (${countEnabled})`, value: 'enabled' },
                    { text: `Disabled (${countDisabled})`, value: 'disabled' },
                ]
            },
            getVendorsData() {
                if (this.filterStatus === 'enabled') {
                    return this.filteredVendors.filter(item => {
                        return item.enabled == 1;
                    });
                }

                if (this.filterStatus === 'disabled') {
                    return this.filteredVendors.filter(item => {
                        return item.enabled == 0;
                    });
                }

                return this.filteredVendors;
            },
            getVendorName(vendor) {
                if (vendor.name) {
                    return vendor.name;
                }
                return `Vendor #${vendor.number}`;
            },
        },
        computed: {
            filteredVendors() {
                let ssearch = this.search.toLowerCase();
                ssearch = ssearch.replace(/\s/, '');
                ssearch = ssearch.replace('#', '');
                ssearch = ssearch.replace('-', '');

                if (!ssearch) {
                    return this.vendors;
                }

                return this.vendors.filter(vendor => {

                    // application find
                    let applicationFind = false;
                    if (vendor.applicationList) {
                        for (let i in vendor.applicationList) {
                            let appName = vendor.applicationList[i].name;
                            appName = appName.toLowerCase();
                            appName = appName.replace(/\s/, '');
                            appName = appName.replace('-', '');
                            if (appName.indexOf(ssearch) !== -1) {
                                applicationFind = true;
                                break;
                            }
                        }
                    }

                    let vendorName = vendor.name || '';
                    vendorName = vendorName.toLowerCase();
                    vendorName = vendorName.replace(/\s/, '');
                    vendorName = vendorName.replace('-', '');

                    const vendorNameFind = vendorName.indexOf(ssearch) !== -1;

                    const vendorNumberFind = vendor.number.indexOf(ssearch) !== -1;

                    return vendorNameFind || vendorNumberFind || applicationFind;
                })
            },
        },
    }
</script>