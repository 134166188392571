<template>
    <v-container fluid>
        <v-row>
            <v-col>

                <v-card class="pa-4">

                    <v-form
                        class="mb-6"
                        ref="form"
                        lazy-validation
                        @submit.prevent="getData()"
                    >

                        <v-row>
                            <v-col cols="4">
                                <v-autocomplete
                                    :loading="applicationsLoading"
                                    item-text="name"
                                    item-value="id"
                                    v-model="selectedApplicationIds"
                                    :items="applications"
                                    label="Applications"
                                    chips
                                    clearable
                                    deletable-chips
                                    multiple
                                ></v-autocomplete>

                                <v-menu
                                    v-model="dateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateFrom"
                                            label="Date From"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateFrom"
                                        @input="dateFromMenu = false"
                                    ></v-date-picker>
                                </v-menu>

                                <v-menu
                                    v-model="dateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateTo"
                                            label="Date To"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateTo"
                                        @input="dateToMenu = false"
                                    ></v-date-picker>
                                </v-menu>

                                <v-autocomplete
                                    v-model="selectedCountry"
                                    :items="countries"
                                    label="Geo"
                                    clearable
                                ></v-autocomplete>

                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="d-flex justify-space-between">

                                <v-btn
                                    class="mt-2"
                                    depressed
                                    color="primary"
                                    type="submit"
                                >
                                    Get Data
                                </v-btn>

                                <v-menu
                                    v-model="tableSettingsMenu"
                                    :close-on-content-click="false"
                                    :nudge-width="200"
                                    offset-x
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="mt-2"
                                            depressed
                                            type="button"
                                            plain
                                            color="black"
                                            v-bind="attrs"
                                            v-on="on"
                                            v-if="tableSettings"
                                        >
                                            <v-icon>mdi-cog</v-icon>Table Settings
                                        </v-btn>
                                    </template>
                                    <v-card class="px-4 py-4">
                                        <div style="height: 400px; overflow-y: scroll;">
                                            <div v-for="item in tableSettings.dataHeaders" :key="item.value">
                                                <v-checkbox
                                                    v-model="item.visible"
                                                    :label="item.text"
                                                    class="mt-0"
                                                ></v-checkbox>
                                            </div>
                                        </div>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                @click="tableSettingsMenu = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                text
                                                @click="updateTableSettings()"
                                                :loading="showTableSettingsLoading"
                                            >
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>

                            </v-col>
                        </v-row>

                    </v-form>

                    <v-data-table
                        :headers="getDataHeaders()"
                        hide-default-header
                        :sort-by="sortParams.by"
                        :sort-desc="sortParams.descending"
                        :items="data"
                        class="elevation-0 no-wrap"
                        :loading="isDataLoading === true"
                        loading-text="Loading... Please wait"
                        fixed-header
                        width="100%"
                        :height="data.length ? '100vh' : 'auto'"
                        hide-default-footer
                        disable-pagination
                    >
                        <template #header="{ }">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th 
                                        v-for="item in getDataHeaders()" 
                                        :key="item.value"
                                        v-bind:class="[item.sortable ? 'sortable' : '', sortParams.by == item.value ? 'active': '', (sortParams.by != item.value ? 'desc' : (sortParams.descending ? 'desc':'asc'))]"
                                        @click="item.sortable ? changeSort(item.value) : ''"
                                    >
                                        <div>{{ item.text }}</div>
                                        <v-icon v-if="item.sortable" class="v-data-table-header__icon" small>mdi-arrow-up</v-icon>
                                    </th>
                                </tr>
                                <tr v-if="data.length && tableSettings" class="total">
                                    <th :colspan="countNullTotalHeaders()" v-if="countNullTotalHeaders()"></th>
                                    <th v-for="header in getVisibleTableHeaders().filter(h => total[h.value] !== null)" :key="header.value">
                                        {{ formatTableData(total[header.value], header.dataFormat) }}
                                        <span v-if="header.dataAppend">&nbsp;{{ header.dataAppend }}</span>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-for="header in getVisibleTableHeaders()" v-slot:[`item.${header.value}`]="{ item }">
                            <div :key="header.value" :style="`max-width: ${getHeaderCustomSettingsParam(header.value, 'width')}; white-space: nowrap; overflow: hidden;`">
                                <span v-if="header.displayValue">
                                    {{ formatTableData(item[header.displayValue], header.dataFormat) }}
                                </span>
                                <span v-else>
                                    {{ formatTableData(item[header.value], header.dataFormat) }}
                                </span>
                                <span v-if="header.dataAppend">&nbsp;{{ header.dataAppend }}</span>
                            </div>
                        </template>
                    </v-data-table>

                </v-card>

            </v-col>
        </v-row>
        <v-dialog v-model="loading" fullscreen>
            <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
                <v-layout justify-center align-center>
                    <v-progress-linear
                        color="light-blue"
                        height="20"
                        :value="loadingProgress"
                        striped
                        style="max-width: 600px;"
                    ></v-progress-linear>
                </v-layout>
            </v-container>
        </v-dialog>
    </v-container>
</template>

<style>
    .no-wrap table th {
        white-space: nowrap !important;
        min-width: 3%;
    }
</style>


<script>

import {objectToQueryString} from "../../../utils/helpers";
import {converValidationServerErrorsToString} from "../../../utils/errorUtil";

export default {
    name: "AdminDashboardAllAppsPage",
    created() {
    },
    watch: {
        selectedApplicationIds: function (newValue, oldValue) {
            localStorage.setItem('dashboard_all_apps__selected_application_ids', JSON.stringify(newValue));
        },
        dateFrom: function (newValue, oldValue) {
            localStorage.setItem('dashboard_all_apps__date_from', JSON.stringify(newValue));
        },
        dateTo: function (newValue, oldValue) {
            localStorage.setItem('dashboard_all_apps__date_to', JSON.stringify(newValue));
        },
        selectedCountry: function (newValue, oldValue) {
            localStorage.setItem('dashboard_all_apps__selected_country', JSON.stringify(newValue));
        },
    },
    async mounted() {
        this.applicationsLoading = true;
        this.getApplicationList(() => {
            this.restoreSelectedApplicationIds();
            this.applicationsLoading = false;
        });
        this.getCountries(() => {
            this.restoreSelectedCountry();
        });
        this.getTableSettings();
        this.restoreSelectedDates();
    },
    data() {
        return {
            loading: false,
            loadingProgress: 0,
            resultsLoaded: 0,
            applicationsLoading: false,
            applications: [],
            selectedApplicationIds: [],
            dateFromMenu: false,
            dateToMenu: false,
            dateFrom: null,
            dateTo: null,
            selectedCountry: null,
            countries: [],
            rules: {
            },
            tableSettings: [],
            sortParams: {
                by: 'leads',
                descending: true,
            },
            data: [],
            total: {},
            isDataLoading: false,
            tableSettingsMenu: null,
            showTableSettingsLoading: false,
            headersCustomSettings: {
            },
        }
    },
    methods: {
        restoreSelectedApplicationIds() {
            const selectedIdsJson = localStorage.getItem('dashboard_all_apps__selected_application_ids');
            if (selectedIdsJson) {
                try {
                    this.selectedApplicationIds = JSON.parse(selectedIdsJson);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        restoreSelectedDates() {
            const dateFrom = localStorage.getItem('dashboard_all_apps__date_from');
            const dateTo = localStorage.getItem('dashboard_all_apps__date_to');
            
            if (dateFrom) {
                try {
                    this.dateFrom = JSON.parse(dateFrom);
                } catch (err) {
                    console.log(err);
                }
            }

            if (dateTo) {
                try {
                    this.dateTo = JSON.parse(dateTo);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        async getApplicationList(callback) {
            let q = objectToQueryString(this.queryParams);

            await this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications?${q}`)
                .then(response => {
                    this.applications = response.data.data.items;
                    callback();
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getCountries(callback) {
          this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries`)
              .then((response) => {
                this.initialCountries = response.data;
                this.fillCountries();
                callback();
              })
              .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        fillCountries() {
          for (let i in this.initialCountries) {
            if (Object.prototype.hasOwnProperty.call(this.initialCountries, i)) {
              let abbreviations = this.initialCountries[i].abbreviations.map(item => item.abbreviation);
              this.countries.push({ text: `${this.initialCountries[i].name}`, value: abbreviations[0]});
            }
          }
        },
        restoreSelectedCountry()
        {
            const idString = localStorage.getItem('dashboard_all_apps__selected_country');
            if (idString) {
                const id = JSON.parse(idString);
                this.selectedCountry = id;
            }
        },
        async getTableSettings() {
            await this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/table/summary_all_apps/settings`)
                .then((response) => {
                    this.tableSettings = response.data;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        updateTableSettings() {
            this.showTableSettingsLoading = true;
            this.$http.post(
                `${process.env.VUE_APP_SERVER_BASE_URL}/admin/table/summary_all_apps/settings`,
                this.tableSettings
            )
                .then(() => {
                    this.showTableSettingsLoading = false;
                    this.tableSettingsMenu = false;
                 })
                .catch(err => {
                    this.showTableSettingsLoading = false;
                    alert(converValidationServerErrorsToString(err));
                });
        },
        getDataHeaders() {
            if (!this.tableSettings) {
                return [];
            }

            let dataHeaders = [];

            for (let i in this.tableSettings.dataHeaders) {
                const header = this.tableSettings.dataHeaders[i];
                if (!header.visible) {
                    continue;
                }

                dataHeaders.push({
                    text: header.text,
                    value: header.value,
                    sortable: true,
                });
            }

            return dataHeaders;
        },
        async getData() {
            this.loading = true;
            this.loadingProgress = 0;
            this.data = [];
            this.resultsLoaded = [];
            await this.executeData();
            await this.getDataTotal();
            this.loading = false;
        },
        async executeData() {
            let promises = this.selectedApplicationIds.map(applicationId => {
                return this.getDataForApplication(applicationId);
            });
            return Promise.all(promises);
        },
        async getDataForApplication(applicationId) {
            const postData = {
                applicationIds: [applicationId],
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                geo: this.selectedCountry,
            };
            try {
                const response = await this.$http.post(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/statistic/summary`,
                    postData
                );
                response.data.data.result.map((item) => {
                    this.data.push(item);
                });
                this.resultsLoaded.push(applicationId);
                this.updateLoadingProgress();
            } 
            catch (err) {
                alert(converValidationServerErrorsToString(err));
            }
        },
        async getDataTotal() {
            const postData = {
                rows: this.data,
            };
            try {
                const response = await this.$http.post(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/statistic/summary/total`,
                    postData
                );
                this.total = response.data.data.result;
            } 
            catch (err) {
                alert(converValidationServerErrorsToString(err));
            }
        },
        updateLoadingProgress() {
            const total = this.selectedApplicationIds.length;
            const totalLoaded = this.resultsLoaded.length;
            this.loadingProgress = parseInt((totalLoaded / total) * 100);
        },
        // getData() {
        //     this.isDataLoading = true;

        //     const postData = {
        //         applicationIds: this.selectedApplicationIds,
        //         dateFrom: this.dateFrom,
        //         dateTo: this.dateTo,
        //         geo: this.selectedCountry,
        //     };

        //     this.$http.post(
        //         `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/statistic/summary?`,
        //         postData
        //     )
        //         .then(response => {
        //             this.isDataLoading = false;
        //             this.data = response.data.data.items;
        //             this.total = response.data.data.total;
        //          })
        //         .catch(err => {
        //             this.isDataLoading = false;
        //             alert(converValidationServerErrorsToString(err));
        //         });
        // },
        formatDecimals(num, decimals) {
            return (Math.round(num * 100) / 100).toFixed(decimals);
        },
        changeSort(column) {
            if (this.sortParams.by === column) {
                this.sortParams.descending = !this.sortParams.descending;
            } else {
                this.sortParams.by = column;
                this.sortParams.descending = true;
            }
        },
        formatTableData(value, format) {
            const formatParts = format.split(',');
            if (formatParts[0] == 'decimal') {
                return this.formatDecimals(value, formatParts[1]);
            }
            return value;
        },
        getVisibleTableHeaders() {
            if (!this.tableSettings || !this.tableSettings.dataHeaders) {
                return [];
            }
            return this.tableSettings.dataHeaders.filter(h => h.visible);
        },
        countNullTotalHeaders() {
            let counter = 0;
            const tableHeaders = this.getVisibleTableHeaders();
            for (let i in tableHeaders) {
                if (this.total[tableHeaders[i].value] === null) {
                    counter++;
                }
            }
            return counter;
        },
        getHeaderCustomSettingsParam(header, param) {
            const headerCustomSettings = this.headersCustomSettings[header];
            if (!headerCustomSettings) {
                return null;
            }
            return headerCustomSettings[param] || null;
        }
    }
}
</script>