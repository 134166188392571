<template>
    <v-container fluid>
        <v-row>
            <v-col>

                <v-card class="pa-4">

                    <v-row>
                        <v-col cols="6">

                            <v-select
                                item-text="name"
                                item-value="id"
                                v-model="applicationSelected"
                                :items="applications"
                                label="Application"
                                @change="getMailboxForApplication(applicationSelected)"
                            ></v-select>

                        </v-col>

                        <v-spacer></v-spacer>

                        <v-col cols="2">
                            <div class="d-flex">
                                <v-autocomplete
                                    class="ml-4"
                                    v-model="selectedCountry"
                                    :items="countries"
                                    :filter="filterCountries"
                                    placeholder="Geo"
                                    clearable
                                    :filled="selectedCountry !== null"
                                    @change="updateWithFilters()"
                                ></v-autocomplete>
                            </div>
                        </v-col>
                    </v-row>

                    <v-data-table
                        :headers="headers"
                        :sort-by="'date_raw'"
                        :sort-desc="true"
                        :items="mailbox"
                        :items-per-page="50"
                        class="mailbox-table elevation-0"
                        :loading="showLoading === true"
                        loading-text="Loading... Please wait"
                        @click:row="openMail"
                    >
                        <template v-slot:item.date_raw="{ item }">
                            <strong v-if="!item.is_seen">{{ item.date }}</strong>
                            <span v-else>{{ item.date }}</span>
                        </template>
                        <template v-slot:item.geo="{ item }">
                            <strong v-if="!item.is_seen">{{ item.geo }}</strong>
                            <span v-else>{{ item.geo }}</span>
                        </template>
                        <template v-slot:item.email="{ item }">
                            <strong v-if="!item.is_seen">{{ item.email }}</strong>
                            <span v-else>{{ item.email }}</span>
                        </template>
                        <template v-slot:item.subject="{ item }">
                            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                <strong v-if="!item.is_seen">{{ getSubject(item) }}</strong>
                                <span v-else>{{ getSubject(item) }}</span>
                                <em class="ml-2" style="color: gray;">{{ getBodyPreview(item) }}</em>
                            </div>
                        </template>
                        <template v-slot:item.has_attachments="{ item }">
                            <v-icon v-if="item.has_attachments === true">
                                mdi-paperclip
                            </v-icon>
                        </template>
                    </v-data-table>

                </v-card>

            </v-col>
        </v-row>

        <MailboxMailDialog
            :applicationId="applicationSelected"
            :show="mailDialog"
            :with="mailSelected.email"
            :messageId="mailSelected.message_id"
            @onClose="mailDialog = false"
        />

    </v-container>
</template>

<style>
    .mailbox-table table {
        width: 100%;
    }

    .mailbox-table td {
        max-width: 0;

    }

    .mailbox-table td:nth-child(1) {
        width: 10%;
    }

    .mailbox-table td:nth-child(2) {
        width: 5%;
    }

    .mailbox-table td:nth-child(4) {
        width: 60%;
    }

    .mailbox-table td:nth-child(5) {
        width: 5%;
    }

    .mailbox-table tr:hover {
        cursor: pointer;
    }
</style>

<script>
    import MailboxMailDialog from "../../../components/Mailbox/MailboxMailDialog";
    import {converValidationServerErrorsToString} from "../../../utils/errorUtil";

    export default {
        name: "MailboxIndexPage",
        components: {
            MailboxMailDialog,
        },
        data() {
            return {
                applications: [],
                applicationSelected: null,
                showReviewsLoading: false,
                headers: [
                    { text: 'Date', value: 'date_raw' },
                    { text: 'Geo', value: 'geo' },
                    { text: 'Email', value: 'email' },
                    { text: 'Topic', value: 'subject' },
                    { text: 'Attachments', value: 'has_attachments' },
                ],
                initialMailbox: [],
                mailbox: [],
                mailSelected: {},
                mailDialog: false,
                countries: [],
                selectedCountry: null,
                showLoading: false,
            }
        },
        created() {
            this.getApplicationList();
            this.getCountries();
        },
        methods: {
            getApplicationList() {
                this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications?`)
                    .then((response) => {
                        this.applications = response.data.data.items;
                        // console.log(this.applications);
                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)));
            },
            getCountries() {
              this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries?`)
                  .then((response) => {
                    this.initialCountries = response.data;
                    this.fillCountries();
                    console.log('initial countries', this.initialCountries);
                  })
                  .catch(err => alert(converValidationServerErrorsToString(err)));
            },

            fillCountries() {

              for (let i in this.initialCountries) {
                if (Object.prototype.hasOwnProperty.call(this.initialCountries, i)) {
                  let abbreviations = this.initialCountries[i].abbreviations.map(item => item.abbreviation);
                  this.countries.push({ text: `${this.initialCountries[i].name}`, value: abbreviations});
                }
              }
              console.log(this.countries);
            },
            getMailboxForApplication(applicationId) {
                this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/mailbox/${applicationId}?`)
                    .then((response) => {
                        this.initialMailbox = response.data.mailbox;
                        this.updateWithFilters();
                        // console.log(this.applications);
                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)));
            },
            getSubject(mail) {
                if (mail.subject) {
                    return mail.subject;
                }
                return '[No subject]';
            },
            getBodyPreview(mail) {
                if (mail.body_cleared) {
                    return mail.body_cleared;
                }
                return '[No body]';
            },
            openMail(mail) {
                this.mailDialog = true;
                this.mailSelected = mail;
            },
            filterCountries (item, queryText, itemText) {
                console.log(item, queryText);
                const textOne = item.text.toLowerCase();
                const textTwo = `${item.value}`;

                const searchText = queryText.toLowerCase();

                return textOne.indexOf(searchText) > -1 ||
                    textTwo.indexOf(searchText) > -1;
            },
            updateWithFilters() {

                this.mailbox = [];

                for (let i in this.initialMailbox) {

                    if (this.selectedCountry !== null) {
                      if (this.selectedCountry.indexOf(this.initialMailbox[i].geo) == -1) {
                        continue;
                      }
                    }

                    this.mailbox.push(this.initialMailbox[i]);
                }

            },
        },
    }
</script>