<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col>

                    <v-card class="pa-4">

                        <v-row>
                            <v-col cols="6">
                                <v-form
                                    class="mb-6"
                                    ref="form"
                                    lazy-validation
                                    @submit.prevent="getData"
                                >

                                    <v-select
                                        item-text="name"
                                        item-value="id"
                                        v-model="applicationSelected"
                                        :rules="rules.applicationRules"
                                        :items="applications"
                                        label="Application"
                                        required
                                        @change="fillDate"
                                    ></v-select>

                                    <v-autocomplete
                                        v-model="selectedCountry"
                                        :items="countries"
                                        label="Geo"
                                        multiple
                                        chips
                                        clearable
                                        :filter="filterCountries"
                                    ></v-autocomplete>


                                    <v-menu
                                        v-model="dateFromMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="dateFrom"
                                                label="Date From"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateFrom"
                                            @input="dateFromMenu = false"
                                        ></v-date-picker>
                                    </v-menu>

                                    <v-menu
                                        v-model="dateToMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="dateTo"
                                                label="Date To"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateTo"
                                            @input="dateToMenu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                    <div>Traffic Started: <strong>{{ applicationLocal.traffic_started || '--' }}</strong></div>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                        class="mt-2"
                                        depressed
                                        color="primary"
                                        type="submit"
                                    >
                                        Get Data
                                    </v-btn>

                                </v-form>

                            </v-col>
                            <v-col cols="6"></v-col>
                        </v-row>

                        <v-row>

                            <v-col>
                                <div class="d-flex justify-space-between">

                                    <div></div>

                                    <v-menu
                                        v-model="tableSettingsMenu"
                                        :close-on-content-click="false"
                                        :nudge-width="200"
                                        offset-x
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="mt-2"
                                                depressed
                                                type="button"
                                                plain
                                                color="black"
                                                v-bind="attrs"
                                                v-on="on"
                                                v-if="tableSettings"
                                            >
                                                <v-icon>mdi-cog</v-icon>Table Settings
                                            </v-btn>
                                        </template>
                                        <v-card class="px-4 py-4">
                                            <div style="height: 400px; overflow-y: scroll;">
                                                <div v-for="item in tableSettings.dataHeaders" :key="item.value">
                                                    <v-checkbox
                                                        v-model="item.visible"
                                                        :label="trimBr(item.text)"
                                                        class="mt-0"
                                                    ></v-checkbox>
                                                </div>
                                            </div>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    @click="tableSettingsMenu = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    color="primary"
                                                    text
                                                    @click="updateTableSettings()"
                                                    :loading="showTableSettingsLoading"
                                                >
                                                    Save
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-menu>

                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-data-table
                                    :headers="getDataHeaders()"
                                    hide-default-header
                                    :sort-by="sortParams.by"
                                    :sort-desc="sortParams.descending"
                                    :items="data"
                                    class="elevation-0 no-wrap"
                                    :loading="isNegativeLoading === true"
                                    loading-text="Loading... Please wait"
                                    fixed-header
                                    width="100%"
                                    :height="data.length && (!selectedCountry || !selectedCountry.length) ? '100vh' : 'auto'"
                                    hide-default-footer
                                    disable-pagination
                                >
                                    <template #header="{ }">
                                        <thead class="v-data-table-header">
                                            <tr>
                                                <th 
                                                    v-for="item in getDataHeaders()" 
                                                    :key="item.value"
                                                    v-bind:class="[item.sortable ? 'sortable' : '', sortParams.by == item.value ? 'active': '', (sortParams.by != item.value ? 'desc' : (sortParams.descending ? 'desc':'asc'))]"
                                                    @click="item.sortable ? changeSort(item.value) : ''"
                                                >
                                                    <div v-html="item.text"></div>
                                                    <v-icon v-if="item.sortable" class="v-data-table-header__icon" small>mdi-arrow-up</v-icon>
                                                </th>
                                            </tr>
                                            <tr v-if="data.length && tableSettings" class="total">
                                                <th :colspan="countNullTotalHeaders()" v-if="countNullTotalHeaders()"></th>
                                                <th v-for="header in getVisibleTableHeaders().filter(h => dataTotal[h.value] !== null)" :key="header.value">
                                                    {{ formatTableData(dataTotal[header.value], header.dataFormat) }}
                                                    <span v-if="header.dataAppend">&nbsp;{{ header.dataAppend }}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template v-for="header in getVisibleTableHeaders()" v-slot:[`item.${header.value}`]="{ item }">
                                        <div :key="header.value" :style="`max-width: ${getHeaderCustomSettingsParam(header.value, 'width')}; white-space: nowrap; overflow: hidden;`">
                                            <span v-if="header.displayValue">
                                                {{ formatTableData(item[header.displayValue], header.dataFormat) }}
                                            </span>
                                            <span v-else>
                                                {{ formatTableData(item[header.value], header.dataFormat) }}
                                            </span>
                                            <span v-if="header.dataAppend">&nbsp;{{ header.dataAppend }}</span>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>

                        </v-row>

                    </v-card>

                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<style>
    .no-wrap table th {
        white-space: nowrap !important;
        min-width: 3%;
    }

    table tr.total th {
        color: #000 !important;
    }
</style>

<script>

import {objectToQueryString} from "../../../utils/helpers";
import {converValidationServerErrorsToString} from "../../../utils/errorUtil";

export default {
    name: "StatisticPage",
    components: {
    },
    mounted() {
        this.getApplicationList();
        this.getCountries();
        this.getTableSettings();
    },
    data() {
        return {
            rules: {
                applicationRules: [
                    v => !!v || 'Application is required',
                ],
            },
            applicationLocal: {
                app_id: "",
                name: "",
                vendor_code: "",
                issuer_code: "",
                key_code: "",
                p8_key: "",
                parse_reviews: false,
                host: "",
                login: "",
                password: "",
                domain: "",
                secret: "",
                mail_settings_email: null,
                mail_settings_password: null,
                enabled: null,
                traffic_started: null,
                subscriptionsData: [],
            },
            selectedCountry: null,
            tab: null,
            applicationSelected: null,
            applications: [],
            dateFromMenu: null,
            dateToMenu: null,
            dateFrom: null,
            dateTo: null,
            countries: [],
            initialCountries: [],
            dataTotal: {},
            data: [],
            isNegativeLoading: false,

            // custom table data
            // todo: move to standalone module
            tableSettings: [],
            sortParams: {
                by: 'leads',
                descending: true,
            },
            tableSettingsMenu: null,
            showTableSettingsLoading: false,
            headersCustomSettings: {
                'country': { width: '120px' },
            },

        }
    },
    methods: {

        fillDate() {
            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.applicationSelected}`)
                .then((response) => {
                    console.log(response.data.data.application);
                    this.dateFrom = this.formatISODate(response.data.data.application.traffic_started);
                    this.fillApplicationLocal(response.data.data.application);
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },

        fillCountries() {
            for (let i in this.initialCountries) {
                if (Object.prototype.hasOwnProperty.call(this.initialCountries, i)) {
                let abbreviations = this.initialCountries[i].abbreviations.map(item => item.abbreviation);
                this.countries.push({ text: `${this.initialCountries[i].name}`, value: abbreviations});
                }
            }
            console.log(this.countries);
        },

        getCountries() {
            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries`)
                .then((response) => {
                    this.initialCountries = response.data;
                    this.fillCountries();
                    console.log('initial countries', this.initialCountries);
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },

        getApplicationList() {
            let q = objectToQueryString(this.queryParams);

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications?${q}`)
                .then(response => this.applications = response.data.data.items)
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },

        getData() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            const q = objectToQueryString({
                started_at: this.dateFrom,
                finished_at: this.dateTo,
                geo: this.selectedCountry,
            });

            this.isNegativeLoading = true;

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.applicationSelected}/statistic/negative?${q}`)
                .then((response) => {
                    this.data = response.data.data.items;
                    this.dataTotal = response.data.data.total;
                    this.isNegativeLoading = false;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));

        },

        fillApplicationLocal(data) {
          return [
              this.applicationLocal.traffic_started = this.formatDate(data.traffic_started),
          ];
        },

        formatDate(date) {
            if (date == null) {
                return null
            } else {
                let dateObj = new Date(date);

                const year = dateObj.getFullYear();
                const month = (dateObj.getMonth()+1).toString().padStart(2, '0');
                const day = dateObj.getDate().toString().padStart(2, '0');

                return `${day}.${month}.${year}`;
            }
        },

        formatISODate(date) {
            if (date == null) {
                return null
            } else {
                let dateObj = new Date(date);

                const year = dateObj.getFullYear();
                const month = (dateObj.getMonth()+1).toString().padStart(2, '0');
                const day = dateObj.getDate().toString().padStart(2, '0');

                return `${year}-${month}-${day}`;
            }
        },

        filterCountries (item, queryText, itemText) {
            const textOne = item.text.toLowerCase();
            const textTwo = `${item.value}`;

            const searchText = queryText.toLowerCase();

            return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1;
        },

        // custom table methods
        // todo: make standalone module
        getTableSettings() {
            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/table/statistic_data_by_geo/settings`)
                .then((response) => {
                    this.tableSettings = response.data;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },

        updateTableSettings() {
            this.showTableSettingsLoading = true;
            this.$http.post(
                `${process.env.VUE_APP_SERVER_BASE_URL}/admin/table/statistic_data_by_geo/settings`,
                this.tableSettings
            )
                .then(() => {
                    this.showTableSettingsLoading = false;
                    this.tableSettingsMenu = false;
                 })
                .catch(err => {
                    this.showTableSettingsLoading = false;
                    alert(converValidationServerErrorsToString(err));
                });
        },

        getDataHeaders() {
            if (!this.tableSettings) {
                return [];
            }

            let dataHeaders = [];

            for (let i in this.tableSettings.dataHeaders) {
                const header = this.tableSettings.dataHeaders[i];
                if (!header.visible) {
                    continue;
                }

                dataHeaders.push({
                    text: header.text,
                    value: header.value,
                    sortable: true,
                });
            }

            return dataHeaders;
        },

        formatDecimals(num, decimals) {
            return (Math.round(num * 100) / 100).toFixed(decimals);
        },

        changeSort(column) {
            if (this.sortParams.by === column) {
                this.sortParams.descending = !this.sortParams.descending;
            } else {
                this.sortParams.by = column;
                this.sortParams.descending = true;
            }
        },

        formatTableData(value, format) {
            const formatParts = format.split(',');
            if (formatParts[0] == 'decimal') {
                return this.formatDecimals(value, formatParts[1]);
            }
            return value;
        },

        getVisibleTableHeaders() {
            if (!this.tableSettings || !this.tableSettings.dataHeaders) {
                return [];
            }
            return this.tableSettings.dataHeaders.filter(h => h.visible);
        },

        countNullTotalHeaders() {
            let counter = 0;
            const tableHeaders = this.getVisibleTableHeaders();
            for (let i in tableHeaders) {
                if (this.dataTotal[tableHeaders[i].value] === null) {
                    counter++;
                }
            }
            return counter;
        },

        getHeaderCustomSettingsParam(header, param) {
            const headerCustomSettings = this.headersCustomSettings[header];
            if (!headerCustomSettings) {
                return null;
            }
            return headerCustomSettings[param] || null;
        },

        trimBr(text) {
            return text.replaceAll('<br/>', '');
        }

    },
}
</script>