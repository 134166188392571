<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col>

                    <v-card class="pa-4">

                        <v-row>
                            <v-col cols="6">
                                <v-form
                                    class="mb-6"
                                    ref="form"
                                    lazy-validation
                                    @submit.prevent="calculateRetention"
                                >

                                    <v-select
                                        item-text="name"
                                        item-value="id"
                                        v-model="applicationSelected"
                                        :items="applications"
                                        label="Application"
                                        @change="onApplicationChange()"
                                        :rules="rules.applicationRules"
                                    ></v-select>

                                    <v-select
                                        item-text="name"
                                        item-value="id"
                                        v-model="subscriptionSelected"
                                        :items="subscriptions"
                                        label="Subscription Name"
                                        :rules="rules.subscriptionNameRules"
                                    ></v-select>

                                    <v-menu
                                        v-model="dateFromMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="dateFrom"
                                                label="Start Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                                :rules="rules.dateFromRules"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateFrom"
                                            type="month"
                                            @input="dateFromMenu = false"
                                        ></v-date-picker>
                                    </v-menu>

                                  <v-select
                                      v-model="selectedCountry"
                                      :items="countries"
                                      placeholder="Geo"
                                      clearable
                                      :filled="selectedCountry !== null"
                                  ></v-select>

                                    <v-btn
                                        class="mt-2"
                                        depressed
                                        color="primary"
                                        type="submit"
                                    >
                                        Calculate Retention
                                    </v-btn>

                                </v-form>
                            </v-col>

                            <v-col cols="6"></v-col>

                        </v-row>


                        <table class="retention" v-if="retentionData.byMonths" border="0">
                            <tr>
                                <td></td>
                                <td></td>
                                <td colspan="12" align="center">Consecutive Renewal Periods</td>
                            </tr>

                            <tr class="header">
                                <td>Subscription Start Month</td>
                                <td class="pr-5" align="right">Subscriptions Started</td>
                                <td align="center">1</td>
                                <td align="center">2</td>
                                <td align="center">3</td>
                                <td align="center">4</td>
                                <td align="center">5</td>
                                <td align="center">6</td>
                                <td align="center">7</td>
                                <td align="center">8</td>
                                <td align="center">9</td>
                                <td align="center">10</td>
                                <td align="center">11</td>
                                <td align="center">12</td>
                            </tr>

                            <tr class="average">
                                <td colspan="2">Average</td>
                                <td v-for="(value, i) in retentionData.averageRetention" :key="i">
                                    <div class="no-value" v-if="!value">-</div>
                                    <div class="value" v-else>{{ value }}%</div>
                                </td>
                            </tr>

                            <tr class="divider">
                                <td colspan="12"></td>
                            <tr>

                            <tr v-for="(item, i) in retentionData.byMonths" :key="i">
                                <td>{{ item.startMonthFormatted }}</td>
                                <td class="pr-5" align="right">{{ getSubscriptionsFormatted(item.subscriptionsStarted) }}</td>
                                <td v-for="(value, j) in item.retention" :key="j">
                                    <div class="no-value" v-if="!value">-</div>
                                    <div class="value" v-else>{{ value }}%</div>
                                </td>
                            </tr>

                        </table>

                    </v-card>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style scoped>
    .retention {
        width: 100%;
        max-width: 1100px;
        border-collapse: collapse;
    }

    .retention td {
        margin: 0;
        padding: 4px 4px;
    }

    .header td {
        padding-top: 12px;
        padding-bottom: 12px;
        margin: 0px 0;
    }

    .average td {
        padding-top: 6px;
        border-top: 1px dashed #ccc;
        border-bottom: 1px dashed #ccc;
        padding-bottom: 6px
    }

    .divider td {
        padding-top: 0;
        padding-bottom: 3px;
    }

    .value {
        width: 60px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #f2f2f2;
    }

    .no-value {
        width: 60px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: transparent;
        border: 1px solid #e8e8e8;
    }
</style>

<script>

import {objectToQueryString} from "../../../utils/helpers";
import {converValidationServerErrorsToString} from "../../../utils/errorUtil";

export default {
    name: "AdminRetentionPage",
    components: {
    },
    data() {
        return {
            rules: {
                applicationRules: [
                    v => !!v || 'Application is required',
                ],
                subscriptionNameRules: [
                    v => !!v || 'Subscription Name is required',
                ],
                dateFromRules: [
                    v => !!v || 'Start Date is required',
                ],
            },
            applicationSelected: null,
            applications: [],
            subscriptionSelected: null,
            subscriptions: [],
            dateFromMenu: null,
            dateFrom: null,
            selectedCountry: null,
            countries: [],
            retentionData: [],
        }
    },
    created() {

    },
    mounted() {
        this.getApplicationList();
        this.getCountries();
    },
    methods: {
        getApplicationList() {
            let q = objectToQueryString(this.queryParams);

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications?${q}`)
                .then(response => this.applications = response.data.data.items)
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getSubscriptions(applicationId) {
            let q = objectToQueryString(this.queryParams);

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/subscriber/subscriptions/${applicationId}?${q}`)
                .then(response => this.subscriptions = response.data.subscriptions)
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getCountries() {
          this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries`)
              .then((response) => {
                this.initialCountries = response.data;
                this.fillCountries();
                console.log('initial countries', this.initialCountries);
              })
              .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        fillCountries() {

          for (let i in this.initialCountries) {
            if (Object.prototype.hasOwnProperty.call(this.initialCountries, i)) {
              let abbreviations = this.initialCountries[i].abbreviations.map(item => item.abbreviation);
              this.countries.push({ text: `${this.initialCountries[i].name}`, value: abbreviations});
            }
          }
          console.log(this.countries);
        },
        onApplicationChange() {
            this.subscriptionSelected = null;
            this.subscriptions = [];
            
            this.getSubscriptions(this.applicationSelected);
        },
        calculateRetention() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            const data = {
                subscription: this.subscriptionSelected,
                from: this.dateFrom,
                geo: this.selectedCountry,
            };

            this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/subscriber/retention/${this.applicationSelected}`,
                data
            ).then(resp => {
                console.log('retention data:', resp.data.data);
                this.retentionData = resp.data.data;
            })
            .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        getSubscriptionsFormatted(value) {
            if (value >= 1000) {
                value = value / 1000;
                return (Math.round(value * 100) / 100).toFixed(2) + 'K';
            } else {
                return value;
            }
        }
    },
}

</script>