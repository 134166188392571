<template>
    <div>
        <v-container fluid>

            <v-row>
                <v-col>

                    <v-card class="pa-4">
                        <v-row>
                          <UserControlDialog :action="crudActions.create"/>
                        </v-row>
                        <v-row>
                            <v-data-table
                            :items="users"
                            :headers="this.header"
                            >
                                <template v-slot:item.actions="{ item }">
                                  <UserControlDialog :user="item" :action="crudActions.update"/>
                                  <AgreeDialog @remove="remove(item)" class="ml-2"/>
                                </template>

                            </v-data-table>
                        </v-row>

                    </v-card>

                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>
import AgreeDialog from "../../../../components/AgreeDialog";
import {eventBus, eventBusEvents} from "../../../../eventBus/eventBus";
import {crudActions} from "../../../../enums/enums";
import {converValidationServerErrorsToString} from "../../../../utils/errorUtil";
import UserControlDialog from "./UserControlDialog";

export default {
    name: "Users",
    data() {
      return {
        users: [],
        header: [
          { text: 'id', value: 'id'},
          { text: 'Name', value: 'name'},
          { text: 'Email', value: 'email'},
          { text: 'Phone', value: 'phone'},
          { text: 'Role', value: 'role'},
          { text: 'Status', value: 'status'},
          { text: '', value: 'actions'},
        ],
      }
    },
    components: {
        AgreeDialog,
        UserControlDialog,
    },
    computed: {
      crudActions: function () {
        return crudActions
      }
    },
    mounted() {
      this.getCountries();
    },
    created() {
      eventBus.$on(eventBusEvents.EVENT_REFRESH_COUNTRIES_BY_ADMIN, async () => {
        this.getCountries();
      });
    },
    methods: {
        remove(user) {
          this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/users/${user.id}`).then(response => {
            console.log('user removed', response.data)
            this.getCountries()
          }).catch(err => alert(converValidationServerErrorsToString(err)));
        },

        getCountries() {
          this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/users`)
              .then((response) => {
                 this.users = this.formatUsers(response.data);
              })
              .catch(err => alert(converValidationServerErrorsToString(err)));
        },

        formatUsers(users) {
          let result = [];
          users.forEach((user) => {
            console.log(user);
            result.push(
                {
                  id: user.id,
                  name: user.name,
                  email: user.email,
                  phone: user.phone,
                  role: user.role,
                  status: user.status,
                }
            )
          });
          return result;
        },
    },
}
</script>
<style>
  .t-w-900 {
    width: 900px;
  }
</style>