<template>
    <div>
        <v-form v-if="userLocal"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSubmit"
        >
            <v-text-field
                v-model="userLocal.name"
                name="name"
                label="Name"
                type="text"
                @keyup.enter="handleSubmit"
                required
            />
            <v-text-field
                v-model="userLocal.phone"
                v-mask="'+7 (###) ###-##-##'"
                name="phone"
                label="Phone"
                type="text"
                @keyup.enter="handleSubmit"
                required
            />
            <v-text-field
                v-model="userLocal.email"
                name="email"
                label="Email"
                type="text"
                @keyup.enter="handleSubmit"
                required
            />
            <v-text-field
                v-model="userLocal.password"
                name="password"
                label="Password"
                type="password"
                @keyup.enter="handleSubmit"
                required
            />
            <v-select
                item-text="name"
                item-value="name"
                v-model="userLocal.role"
                :items="roleList"
                label="Role"
                required
            />
            <v-select
                item-text="name"
                item-value="name"
                v-model="userLocal.status"
                :items="statusList"
                label="Status"
                required
            />
            <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="handleSubmit"> {{ btnText }}
            </v-btn>
        </v-form>
    </div>
</template>

<script>

    import {crudActions} from "../../../../enums/enums";
    import {eventBus, eventBusEvents} from "../../../../eventBus/eventBus";
    import {converValidationServerErrorsToString} from "../../../../utils/errorUtil";
    import {mapActions} from "vuex";

    require('dotenv').config()

    export default {
        name: "UserForm",
        props: ['action', 'user'],
        data() {
            return {
                valid: true,

                statusList: [
                  {
                    'name': 'active'
                  },
                  {
                    'name': 'disable'
                  }
                ],

                roleList: [
                  {
                    'name': 'admin'
                  },
                  {
                    'name': 'mediabuy'
                  },
                  {
                    'name': 'support'
                  },
                  {
                    'name': 'media-jun'
                  },
                ],

                actionLocal: null,
                userLocal: {
                    id: null,
                    name: null,
                    email: null,
                    phone: null,
                    password: null,
                    status: null,
                },
            }
        },
        computed: {
            crudActions: function () {
                return crudActions
            },
            btnText: function () {
                return this.actionLocal === this.crudActions.create ? 'Create' : 'Update'
            }
        },
        created() {
            this.actionLocal = this.action

            if (this.application) {
                this.applicationLocal = this.application
            }

            eventBus.$on(eventBusEvents.EVENT_EDIT_APPLICATION_BY_ADMIN, async application => {
                console.log('EDIT_APPLICATION_BY_ADMIN', application)
            });
            eventBus.$on(eventBusEvents.EVENT_REFRESH_APPLICATION_BY_ADMIN, async () => {
                this.refresh()
            });
        },
        mounted() {
            if (this.actionLocal === this.crudActions.update) {
                console.log(this.userLocal);
                this.userLocal = this.user
            }
        },
        methods: {
            ...mapActions([
                'EXEC_PROCESSING_REQUEST',
            ]),
            edit(data) {
                console.log(data);
                this.actionLocal = this.crudActions.update
            },
            handleSubmit(e) {
                e.preventDefault()

                if (this.actionLocal === this.crudActions.create) {
                    this.create()
                } else {
                    this.update()
                }
            },
            create() {
                console.log('user_form_create')
                console.log(this.userLocal)
                this.EXEC_PROCESSING_REQUEST(true)

                this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/users`,
                    this.userLocal
                ).then(resp => {
                    const user = resp.data;
                    eventBus.$emit(eventBusEvents.EVENT_REFRESH_COUNTRIES_BY_ADMIN)
                })
                    .catch(err => alert(converValidationServerErrorsToString(err)))
                    .finally(() => this.EXEC_PROCESSING_REQUEST(false))
            },
            update() {
                console.log('user_form_update')

                this.EXEC_PROCESSING_REQUEST(true)

                let data = {
                    ...this.userLocal,
                    ...{
                        _method: 'put'
                    }
                }

                console.log('user_form_update_data___', data)
                close();
                this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/users/${this.userLocal.id}`, data)
                    .then(() => {
                        eventBus.$emit(eventBusEvents.EVENT_REFRESH_COUNTRIES_BY_ADMIN)
                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)))
                    .finally(() => this.EXEC_PROCESSING_REQUEST(false))
            },
        }
    }
</script>

<style scoped>

</style>
