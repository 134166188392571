<template>
    <div>
        <v-form v-if="settings"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSubmit"
        >
          <v-textarea
              v-model="settings.domains"
              name="domain"
              label="Binom domains"
              type="textarea"
              required
          />
          <v-checkbox
              v-model="settings.isAllWhite"
              name="isAllWhite"
              label="White only"
              type="checkbox"
              @keyup.enter="handleSubmit"
              required
          />
          <v-text-field
              v-model="settings.itcPassword"
              name="itcPassword"
              label="ITC Password"
              type="text"
              @keyup.enter="handleSubmit"
              required
          />
          <v-text-field
              v-model="settings.debugItcPassword"
              name="debugItcPassword"
              label="Debug ITC Password"
              type="text"
              @keyup.enter="handleSubmit"
              required
          />
          <v-text-field
              v-model="settings.appLink"
              name="appLink"
              label="App Link"
              type="text"
              @keyup.enter="handleSubmit"
              required
          />
          <v-text-field
              v-model="settings.sendPostbackUrl"
              name="sendPostbackUrl"
              label="Send postback URL"
              type="text"
              @keyup.enter="handleSubmit"
              required
          />
          <v-text-field
              v-model="settings.addEventUrl"
              name="addEventUrl"
              label="Add Event URL"
              type="text"
              @keyup.enter="handleSubmit"
              required
          />
          <v-textarea
              v-model="settings.plans"
              name="plans"
              label="Plans"
              type="text"
              required
          />
          <v-textarea
              v-model="settings.json_settings"
              name="json_settings"
              label="Json settings"
              required
          />
            <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="handleSubmit"> Save
            </v-btn>
        </v-form>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import {converValidationServerErrorsToString} from "../../utils/errorUtil";
    import {eventBus, eventBusEvents} from "../../eventBus/eventBus";
    import {crudActions} from "../../enums/enums";

    require('dotenv').config()

    export default {
        name: "ApplicationRemoteForm",
        props: ['action', 'application'],
        data() {
            return {
                valid: true,

                actionLocal: null,

                settings: {
                    domains: "",
                    isAllWhite: false,
                    itcPassword: "",
                    debugItcPassword: "",
                    appLink: "",
                    sendPostbackUrl: "",
                    json_settings: "",
                    plans: "",
                },

                applicationLocal: null,
            }
        },
        computed: {
            crudActions: function () {
                return crudActions
            },
        },
        created() {
            this.actionLocal = this.action

            if (this.application) {
                this.applicationLocal = this.application
                this.edit(this.application)
            }

            eventBus.$on(eventBusEvents.EVENT_EDIT_APPLICATION_BY_ADMIN, async application => {
                console.log('EDIT_APPLICATION_BY_ADMIN', application)
                this.edit(application)
            });
        },
        mounted() {
            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.application.id}/remote/getsettings`)
                .then(response => this.refresh(response.data.data));
        },
        methods: {
            ...mapActions([
                'EXEC_PROCESSING_REQUEST',
            ]),
            edit(data) {
                console.log(data);
                this.actionLocal = this.crudActions.update
                this.applicationLocal.id = data.id
                this.applicationLocal.app_id = data.app_id
                this.applicationLocal.name = data.name
                this.applicationLocal.vendor_code = data.vendor_code
                this.applicationLocal.issuer_code = data.issuer_code
                this.applicationLocal.key_code = data.key_code
                this.applicationLocal.p8_key = data.p8_key
                this.applicationLocal.parse_reviews = data.parse_reviews
                this.applicationLocal.host = data.proxy.host;
                this.applicationLocal.login = data.proxy.login;
                this.applicationLocal.password = data.proxy.password;
                this.applicationLocal.domain  = data.remote != null ? data.remote.domain : '';
                this.applicationLocal.key = data.remote != null ? data.remote.key : '';
            },
            refresh(data) {
                this.settings.domains = data.domains;
                this.settings.isAllWhite = data.isAllWhite;
                this.settings.itcPassword = data.itcPassword;
                this.settings.debugItcPassword = data.debugItcPassword;
                this.settings.appLink = data.appLink;
                this.settings.sendPostbackUrl = data.sendPostbackUrl;
                this.settings.addEventUrl = data.addEventUrl;
                this.settings.skip_launch_check = data.skip_launch_check;
                this.settings.plans = JSON.stringify(data.plans, undefined, 4);
                this.settings.json_settings = JSON.stringify(data.json_settings, undefined, 4);
                console.log(data);
            },
            handleSubmit(e) {
                e.preventDefault()

                this.save();
            },
            save() {
                console.log('save_remote_application_settings')

                let settings = this.settings;

                if (!this.validate()) {
                    return false
                }
                console.log(this.settings);
                this.EXEC_PROCESSING_REQUEST(true)
                settings.plans = JSON.parse(settings.plans);
                settings.json_settings = JSON.parse(settings.json_settings);

                let data = {
                    ...this.settings,
                    ...{
                        _method: 'post'
                    }
                }

                console.log('settings___', data)

                this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.application.id}/remote/setsettings`, data)
                    .then(response => this.refresh(response.data.settings))
                    .catch(err => alert(converValidationServerErrorsToString(err)))
                    .finally(() => this.EXEC_PROCESSING_REQUEST(false))
            },
            validate() {
                if (!this.settings.domains) {
                    alert('domain is empty')
                    return false
                }
                if (!this.settings.debugItcPassword) {
                    alert('debugItcPassword is empty')
                    return false
                }
                if (!this.settings.itcPassword) {
                    alert('itcPassword is empty')
                    return false
                }
                if (!this.settings.json_settings) {
                    alert('json_settings is empty')
                    return false
                }
                if (!this.settings.plans) {
                    alert('plans is empty')
                    return false
                }
                if (!this.settings.sendPostbackUrl) {
                  alert('sendPostbackUrl is empty')
                  return false
                }

                return true
            }
        }
    }
</script>

<style scoped>
  textarea {
    width: 100%;
    height: 150px;
  }
</style>
