<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <slot></slot>
        </span>
      </template>

      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title v-if="isUpdate()"
            >Update Application</v-toolbar-title
          >
          <v-toolbar-title v-else>New Application</v-toolbar-title>

          <v-spacer />

          <v-toolbar-items>
            <v-btn @click="dialog = false" dark text> Close </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="8">
                <v-progress-circular
                  v-if="loading"
                  color="primary"
                  class="mt-4"
                  size="50"
                  indeterminate
                />

                <v-form
                  v-else
                  ref="form"
                  v-model="formValid"
                  lazy-validation
                  @submit.prevent="handleSubmit()"
                >
                  <v-tabs class="mb-2" v-model="tab">
                    <v-tab key="app-data">Application Data</v-tab>
                    <v-tab key="parsing-options">Parsing&Report Options</v-tab>
                    <v-tab
                      key="mail-settings"
                      v-if="applicationLocal.parse_mailbox"
                      >Mail settings</v-tab
                    >
                    <v-tab key="subscriptions" v-if="isUpdate()"
                      >Subscriptions</v-tab
                    >
                    <v-tab key="appstore-connect" v-if="isUpdate()"
                      >AppStore Connect</v-tab
                    >
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <v-tab-item key="app-data">
                      <v-checkbox
                        v-model="applicationLocal.enabled"
                        label="Enabled"
                      ></v-checkbox>

                      <v-text-field
                        v-model="applicationLocal.name"
                        :rules="rules.nameRules"
                        label="Application Name"
                        type="text"
                        @keyup.enter="handleSubmit()"
                        required
                      />

                      <v-text-field
                        v-model="applicationLocal.app_id"
                        label="App ID"
                        type="text"
                        @keyup.enter="handleSubmit()"
                        required
                      />

                      <v-text-field
                        v-model="applicationLocal.sku"
                        label="SKU"
                        type="text"
                        @keyup.enter="handleSubmit()"
                        required
                      />

                      <h2 class="mt-4">Backend Settings</h2>

                      <v-text-field
                        v-model="applicationLocal.domain"
                        :rules="rules.domainRules"
                        name="domain"
                        label="Backend Domain"
                        type="text"
                        @keyup.enter="handleSubmit()"
                        required
                      />

                      <v-text-field
                        v-model="applicationLocal.key"
                        :rules="rules.keyRules"
                        name="domain"
                        label="Backend Key"
                        type="text"
                        @keyup.enter="handleSubmit()"
                        required
                      />

                      <h2 class="mt-4">Account Settings</h2>

                      <v-container
                        v-if="
                          applicationLocal.vendorList &&
                          applicationLocal.vendorList.length
                        "
                        fluid
                      >
                        <template
                          v-for="(vendor, index) in applicationLocal.vendorList"
                        >
                          <v-row v-if="index === 0" :key="index" dense>
                            <v-col>
                              <v-autocomplete
                                v-model="applicationLocal.vendorList[index].id"
                                item-value="id"
                                item-text="display_name"
                                :items="vendors"
                                label="Account"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row v-else :key="index" dense>
                            <v-col>
                              <v-autocomplete
                                v-model="applicationLocal.vendorList[index].id"
                                item-value="id"
                                item-text="display_name"
                                :items="vendors"
                                label="Transfer To Account"
                              ></v-autocomplete>
                            </v-col>
                            <v-col>
                              <v-menu
                                v-model="dateToMenu[index]"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="
                                      applicationLocal.vendorList[index]
                                        .transferred_at
                                    "
                                    label="Transfer Date"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="
                                    applicationLocal.vendorList[index]
                                      .transferred_at
                                  "
                                  @input="dateToMenu[index] = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </template>
                        <v-row
                          v-if="
                            isUpdate() &&
                            applicationLocal.vendorList &&
                            applicationLocal.vendorList[0].id &&
                            !hideTransferAction
                          "
                          dense
                          no-gutters
                        >
                          <v-col>
                            <v-btn
                              color="info"
                              block
                              @click="addTransferAccount()"
                              >Add Transfer Account</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="
                            isUpdate() &&
                            applicationLocal.vendorList &&
                            applicationLocal.vendorList[0].id &&
                            hideTransferAction
                          "
                          dense
                          no-gutters
                        >
                          <v-col>
                            <v-btn
                              color="error"
                              block
                              @click="cancelTransferAction()"
                              >Cancel Transfer Account</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>

                    <v-tab-item key="parsing-options">
                      <h2 class="mt-4">Parsing Options</h2>
                      <v-container fluid>
                        <v-row dense no-gutters>
                          <v-col>
                            <v-checkbox
                              v-model="applicationLocal.parsing_events_from_app"
                              label="Backend events"
                            ></v-checkbox>
                            <v-checkbox
                              v-model="applicationLocal.is_collect_app_reviews"
                              label="Backend reviews"
                            ></v-checkbox>
                            <v-checkbox
                              v-model="applicationLocal.is_refund_parsing"
                              label="Backend refunds"
                            ></v-checkbox>
                            <v-checkbox
                              v-model="
                                applicationLocal.parse_backend_unsubscribes
                              "
                              label="Backend unsubscribes"
                            ></v-checkbox>
                            <v-checkbox
                              v-model="applicationLocal.parse_backend_pp"
                              label="Backend pp"
                            ></v-checkbox>
                            <v-checkbox
                              v-model="applicationLocal.parse_reviews"
                              label="API reviews"
                            ></v-checkbox>
                            <v-checkbox
                              v-model="applicationLocal.parse_mailbox"
                              label="Mails"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                      <h2 class="mt-4">Report Options</h2>
                      <v-container fluid>
                        <v-row dense no-gutters>
                          <v-col>
                            <v-checkbox
                              v-model="applicationLocal.report_missing_rebills"
                              label="Missing rebills for last 12 hours"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>

                    <v-tab-item
                      key="mail-settings"
                      v-if="applicationLocal.parse_mailbox"
                    >
                      <v-select
                        :items="mailServiceProviders"
                        v-model="
                          applicationLocal.mail_settings_service_provider
                        "
                        label="Mail Service Provider"
                      ></v-select>

                      <v-text-field
                        v-model="applicationLocal.mail_settings_email"
                        label="Email Address"
                        type="text"
                        @keyup.enter="handleSubmit()"
                      />

                      <v-text-field
                        v-model="applicationLocal.mail_settings_password"
                        label="Email Password"
                        type="password"
                        @keyup.enter="handleSubmit()"
                      />
                    </v-tab-item>

                    <v-tab-item key="subscriptions" v-if="isUpdate()">
                      <v-simple-table
                        v-if="applicationLocal.subscriptionsData.length > 0"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Name</th>
                              <th class="text-left">Trial?</th>
                              <th class="text-left" width="100">Price, $</th>
                              <th class="text-left" width="100">Onb</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in applicationLocal.subscriptionsData"
                              :key="item.id"
                            >
                              <td>{{ item.name }}</td>
                              <td>
                                <v-checkbox v-model="item.trial"></v-checkbox>
                              </td>
                              <td>
                                <v-text-field
                                  v-model="item.price"
                                  placeholder=""
                                ></v-text-field>
                              </td>
                              <td>
                                <v-text-field
                                  v-model="item.onb"
                                  placeholder=""
                                ></v-text-field>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <p v-else>No subscriptions</p>
                    </v-tab-item>

                    <!-- ==== AppStore Connect ==== -->
                    <v-tab-item key="appstore-connect">
                      <v-row class="mb-3" v-if="submissionState">
                        <v-col>
                          Статус последнего ревью: <b>{{ submissionState }}</b>
                        </v-col>
                      </v-row>

                      <template
                        v-for="(
                          build, index
                        ) in applicationLocal.problemBuildList"
                      >
                        <v-row
                          :key="index"
                          dense
                          class="mb-3"
                          v-if="build?.version"
                        >
                          <v-col>
                            Неудачный статус подготовки
                            <b>{{ build.version }}</b> билда:
                            <b>{{ build.externalStatus }}</b>
                          </v-col>
                        </v-row>
                      </template>

                      <div v-if="applicationLocal.buildList.length > 0">
                        <!-- <v-autocomplete
                          v-model="applicationLocal.buildId"
                          item-value="id"
                          item-text="version"
                          :items="applicationLocal.buildList"
                          label="Builds for submission"
                          :rules="rules.requireRules"
                          required
                        ></v-autocomplete> -->

                        <v-row
                          class="mb-3"
                          v-if="applicationLocal.buildLast?.version"
                        >
                          <v-col>
                            Номер последнего билда:
                            <b>{{ applicationLocal.buildLast.version }}</b>
                          </v-col>
                        </v-row>

                        <v-text-field
                          v-model="applicationLocal.whatsNew"
                          name="whats_new"
                          label="What's New?"
                          type="text"
                          @keyup.enter="handleSubmit()"
                          :rules="rules.requireRules"
                          required
                        />
                      </div>
                    </v-tab-item>
                  </v-tabs-items>

                  <v-btn
                    :disabled="
                      !isFormLoaded() ||
                      !formValid ||
                      (((tab === 3 && !applicationLocal.parse_mailbox) ||
                        tab === 4) &&
                        applicationLocal.buildList.length === 0)
                    "
                    color="success"
                    class="mt-4"
                    :loading="loadings.buildSubmit"
                    @click="handleSubmit()"
                  >
                    <span
                      v-if="
                        isUpdate() &&
                        ((tab === 3 && !applicationLocal.parse_mailbox) ||
                          tab === 4)
                      "
                      >Submit</span
                    >
                    <span v-else-if="isUpdate()">Update</span>
                    <span v-else>Save</span>
                  </v-btn>
                  <v-btn
                    v-if="
                      isUpdate() &&
                      ((tab === 3 && !applicationLocal.parse_mailbox) ||
                        tab === 4)
                    "
                    :disabled="
                      !isFormLoaded() ||
                      !formValid ||
                      (((tab === 3 && !applicationLocal.parse_mailbox) ||
                        tab === 4) &&
                        applicationLocal.buildList.length === 0)
                    "
                    color="info"
                    class="mt-4 ml-3"
                    :loading="loadings.buildRefresh"
                    @click="refreshBuild()"
                  >
                    <span>Refresh</span>
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { converValidationServerErrorsToString } from "../../utils/errorUtil";

export default {
  name: "ApplicationEditDialog",
  props: ["applicationId"],
  components: {},
  data() {
    return {
      dialog: false,
      tab: null,
      formValid: true,
      loading: false,
      submitting: false,
      applicationLocal: {
        id: null,
        enabled: true,
        app_id: null,
        name: null,
        domain: null,
        key: null,

        parsing_events_from_app: true,
        is_collect_app_reviews: true,
        is_refund_parsing: true,
        parse_backend_unsubscribes: true,
        parse_backend_pp: true,

        parse_reviews: false,
        parse_mailbox: false,

        report_missing_rebills: true,

        mail_settings_service_provider: null,
        mail_settings_email: null,
        mail_settings_password: null,

        // traffic_started: null,
        subscriptionsData: [],
        vendorList: [
          {
            id: null,
            name: null,
            transferred_at: null,
          },
        ],
        problemBuildList: [
          {
            id: null,
            version: "",
            internalStatus: null,
            externalStatus: null,
          },
        ],
        buildList: [
          {
            id: null,
            name: "",
            internalStatus: null,
            externalStatus: null,
          },
        ],
        buildLast: {
          id: null,
          version: "",
          internalStatus: null,
          externalStatus: null,
        },
        buildId: null,
        whatsNew: "",
      },
      submissionState: "",
      mailServiceProviders: [],
      vendors: [],
      rules: {
        nameRules: [
          (v) => !!v || "Name must be valid",
          (v) =>
            (v && v.length > 2) || "Name length must be more than 2 characters",
        ],
        domainRules: [(v) => !!v || "domain must be valid"],
        keyRules: [(v) => !!v || "key must be valid"],
        requireRules: [(v) => !!v || "Value must be set"],
      },
      hideTransferAction: false,
      dateToMenu: {},
      loadings: {
        buildSubmit: false,
        buildRefresh: false,
      },
    };
  },
  watch: {
    dialog: {
      immediate: true,
      handler(to, from) {
        if (to === true) {
          this.loadVendors();
          if (this.applicationId) {
            this.loadApplicationData(this.applicationId);
            this.loadBuildsData(this.applicationId);
          }
        }
      },
    },
  },
  //   mounted() {
  //     this.$nextTick(() => {
  //       // Вызов метода только после полной загрузки компонента
  //       console.log("REFS2", this.$refs);
  //       console.log(this.$refs.notification);
  //     });
  //   },
  methods: {
    loadApplicationData(applicationId) {
      this.loading = true;
      this.$http
        .get(
          `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${applicationId}`
        )
        .then((response) => {
          this.loading = false;
          this.fillApplicationLocal(response.data.data.application);
          this.mailServiceProviders = response.data.data.mailServiceProviders;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    loadBuildsData(applicationId) {
      this.loading = true;
      this.loadings.buildRefresh = true;
      this.$http
        .get(
          `${process.env.VUE_APP_SERVER_BASE_URL}/admin/builds/${applicationId}`
        )
        .then((response) => {
          this.loading = false;
          if (response.data.message !== "ok") {
            alert(`Ошибка получения данных билдов: ${response.data.message}`);
            return;
          }
          this.fillBuildsLocal(response.data.data.builds);
          this.submissionState = response.data.state;
          //  this.mailServiceProviders = response.data.data.mailServiceProviders;
        })
        .catch((err) => {
          this.loading = false;
          this.$refs.notification.showError(
            converValidationServerErrorsToString(err)
          );
          console.log(err);
        })
        .finally(() => {
          this.loadings.buildRefresh = false;
        });
    },
    fillApplicationLocal(application) {
      this.applicationLocal.enabled = application.enabled;

      this.applicationLocal.id = application.id;
      this.applicationLocal.app_id = application.app_id;
      this.applicationLocal.sku = application.sku;
      this.applicationLocal.name = application.name;
      this.applicationLocal.domain = application.remote?.domain;
      this.applicationLocal.key = application.remote?.key;

      this.applicationLocal.parsing_events_from_app =
        application.parsing_events_from_app;
      this.applicationLocal.parse_reviews = application.parse_reviews;
      this.applicationLocal.is_refund_parsing = application.is_refund_parsing;
      this.applicationLocal.parse_backend_unsubscribes =
        application.parse_backend_unsubscribes;
      this.applicationLocal.parse_backend_pp = application.parse_backend_pp;

      this.applicationLocal.is_collect_app_reviews =
        application.is_collect_app_reviews;
      this.applicationLocal.parse_mailbox = application.parse_mailbox;

      this.applicationLocal.report_missing_rebills =
        application.report_missing_rebills;

      this.applicationLocal.mail_settings_service_provider =
        application.mail_settings?.service_provider;
      this.applicationLocal.mail_settings_email =
        application.mail_settings?.email;
      this.applicationLocal.mail_settings_password =
        application.mail_settings?.password;

      // this.applicationLocal.traffic_started = data.traffic_started;
      this.applicationLocal.subscriptionsData = [];

      if (application.subscriptions && application.subscriptions.length) {
        for (let i in application.subscriptions) {
          this.applicationLocal.subscriptionsData.push({
            id: application.subscriptions[i].id,
            name: application.subscriptions[i].name,
            trial: application.subscriptions[i].offer_type === "Free Trial",
            price: application.subscriptions[i].price,
            onb: application.subscriptions[i].onb,
          });
        }
      }

      this.applicationLocal.vendorList = [];

      if (application.vendorList && application.vendorList.length) {
        for (let i in application.vendorList) {
          this.applicationLocal.vendorList.push({
            id: application.vendorList[i]?.id,
            transferred_at: application.vendorList[i]?.transferred_at,
          });
        }
      }

      console.log(this.applicationLocal.vendorList);
    },
    fillBuildsLocal(builds) {
      this.applicationLocal.buildList = [];
      this.applicationLocal.problemBuildList = [];
      // console.log("builds:", builds);

      this.applicationLocal.buildLast = {
        id: null,
        version: 0,
        internalStatus: null,
        externalStatus: null,
      };

      for (let i in builds) {
        const tmpData = {
          id: builds[i].id,
          version: builds[i].version,
          internalStatus: builds[i].internalBuildState,
          externalStatus: builds[i].externalBuildState,
        };

        if (builds[i].externalBuildState === "READY_FOR_BETA_SUBMISSION") {
          if (builds[i].version + 0 > this.applicationLocal.buildLast.version) {
            this.applicationLocal.buildLast = tmpData;
          }

          this.applicationLocal.buildList.push(tmpData);
        } else {
          this.applicationLocal.problemBuildList.push(tmpData);
        }
      }

      console.log(
        "this.applicationLocal.buildList:",
        this.applicationLocal.buildList
      );
    },
    loadVendors() {
      this.$http
        .get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/vendors`)
        .then((response) => {
          // this.loading = false;
          this.vendors = response.data;
        })
        .catch((err) => {
          // this.loading = false;
          console.log(err);
        });
    },
    handleSubmit() {
      if (!this.isFormLoaded() || !this.formValid) {
        return false;
      }

      if (
        (this.tab === 3 && !this.applicationLocal.parse_mailbox) ||
        this.tab === 4
      ) {
        this.applicationLocal.buildId = this.applicationLocal.buildLast.version; // в связи с переходом от выбора билда к просто последнему билду
        if (!this.applicationLocal.buildId || !this.applicationLocal.whatsNew) {
          this.$refs.notification.showError("Не заполнены поля");
          return false;
        }
        const url = `${process.env.VUE_APP_SERVER_BASE_URL}/admin/builds/${this.applicationId}`;

        this.submitting = true;
        this.loadings.buildSubmit = true;
        this.$http
          .post(url, this.applicationLocal)
          .then((resp) => {
            this.submitting = false;

            if (resp.data.message !== "ok") {
              alert(`Ошибка получения данных билдов: ${resp.data.message}`);
              console.log("Error response", resp);
              return;
            }

            this.$refs.notification.showSuccess("Успешно");

            this.loadBuildsData(this.applicationId);
          })
          .catch((err) => {
            this.submitting = false;
            // this.$refs.notification.showError(
            //   converValidationServerErrorsToString(err)
            // );
            alert(converValidationServerErrorsToString(err));
          })
          .finally(() => {
            this.loadings.buildSubmit = false;
          });

        return;
      }

      let url = `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/`;

      if (this.isUpdate()) {
        url = `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/${this.applicationId}`;
      }

      this.submitting = true;

      this.$http
        .post(url, this.applicationLocal)
        .then((resp) => {
          this.submitting = false;
          this.$emit("save", this.applicationLocal);
          this.dialog = false;
        })
        .catch((err) => {
          this.submitting = false;
          alert(converValidationServerErrorsToString(err));
        });
    },
    refreshBuild() {
      this.loadBuildsData(this.applicationId);
    },
    isUpdate() {
      return !!this.applicationId;
    },
    addTransferAccount() {
      this.hideTransferAction = true;
      this.applicationLocal.vendorList.push({});
    },
    cancelTransferAction() {
      this.hideTransferAction = false;
      this.applicationLocal.vendorList.pop();
    },
    isFormLoaded() {
      if (this.isUpdate()) {
        if (!this.vendors.length) {
          return false;
        }
      }
      return true;
    },
  },
};
// Вызываем нотификацию с сообщением об успехе
//  this.$refs.notification.showSuccess('Action completed successfully!');
// Вызываем нотификацию с сообщением об ошибке
// this.$refs.notification.showError('Something went wrong!');
</script>

<style scoped></style>
