<template>
    <v-dialog
            v-model="dialog"
            width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    class="ml-2"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    x-small
                    dark
            >
                <v-icon aria-hidden="false">
                    mdi-trash-can
                </v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="headline grey lighten-2">
                Подтверждение действия
            </v-card-title>

            <v-card-text class="mt-4">
                Вы точно уверены, что хотите сделать это?
            </v-card-text>

            <v-divider/>

            <v-card-actions>
                <v-spacer/>
                <v-btn
                        color="red"
                        text
                        @click="agree"
                >
                    Да
                </v-btn>
                <v-btn
                        color="primary"
                        text
                        @click="cancel"
                >
                    Нет
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "AgreeDialog",
        data() {
            return {
                dialog: false
            }
        },
        methods: {
            agree() {
                this.dialog = false
                this.$emit('remove',)
            },
            cancel() {
                this.dialog = false
            },
        }
    }
</script>

<style scoped>

</style>