<template>
    <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="360px"
    >
        <template v-slot:activator="{ on, attrs }">
            <div class="d-flex">
                <span class="mr-2">Proxy:</span>
                <v-chip
                    v-if="proxy.enabled"
                    color="green"
                    text-color="white"
                    v-bind="attrs"
                    v-on="on"
                >
                    Enabled
                </v-chip>
                <v-chip
                    v-if="!proxy.enabled"
                    color="gray"
                    v-bind="attrs"
                    v-on="on"
                >
                    Disabled
                </v-chip>
            </div>
        </template>

        <v-card>
            <v-toolbar dark color="primary">

                <v-toolbar-title>
                    Edit Proxy
                </v-toolbar-title>

                <v-spacer/>

                <v-toolbar-items>
                    <v-btn @click="dialog = false" dark text>
                        Close
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col>

                            <v-form
                                ref="form"
                                v-model="valid"
                                @submit.prevent="handleSubmit"
                            >
                                <v-text-field
                                    ref="host"
                                    v-model="proxy.host"
                                    label="Proxy Host"
                                    type="text"
                                />
                                <v-text-field
                                    ref="login"
                                    v-model="proxy.login"
                                    label="Proxy Login"
                                    type="text"
                                />
                                <v-text-field
                                    ref="password"
                                    v-model="proxy.password"
                                    label="Proxy Password"
                                    type="text"
                                />
                                <v-checkbox
                                    v-model="proxy.enabled"
                                    label="Enable proxy"
                                ></v-checkbox>
                                 <v-btn
                                    color="info"
                                    @click.prevent="handleSubmit"
                                    block
                                    :loading="loading"
                                >
                                   Save
                                </v-btn>
                            </v-form>

                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-divider/>
        </v-card>
    </v-dialog>
</template>

<script>
    import {converValidationServerErrorsToString} from "../../utils/errorUtil";

    export default {
        name: "ManualReviewsProxyControlDialog",
        props: ['settings'],
        data() {
            return {
                dialog: null,
                valid: true,
                rules: {
                    host: [v => !!v || 'Host is requred when proxy is enabled',],
                    login: [v => !!v || 'Login is requred when proxy is enabled',],
                    password: [v => !!v || 'Password is requred when proxy is enabled',],
                },
                proxy: {
                    host: null,
                    login: null,
                    password: null,
                    enabled: null,
                },
                loading: false,
            }
        },
        mounted() {
        },
        watch: {
            settings: function(settings, oldSettings) {
                this.fillProxyFromSettings(settings);
                console.log('Watch: settings are updated');
            },
        },
        methods: {
            fillProxyFromSettings(settings) {
                this.proxy.host = settings.proxy_host || null;
                this.proxy.login = settings.proxy_login || null;
                this.proxy.password = settings.proxy_password || null;
                this.proxy.enabled = settings.proxy_enabled == '1' ? true : false;

                console.log('filled proxy form');
            },
            handleSubmit() {
                this.updateProxySettings();
            },
            updateProxySettings() {
                const proxySettings = {};

                proxySettings.proxy_host = this.proxy.host;
                proxySettings.proxy_login = this.proxy.login;
                proxySettings.proxy_password = this.proxy.password;
                proxySettings.proxy_enabled = this.proxy.enabled;

                this.loading = true;

                this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/review-parsers/settings`,
                    proxySettings
                ).then(resp => {
                    // this.dialog = false;
                    this.$router.go();
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
            },
        },
    }
</script>