<template>
    <v-container fluid>
        <v-row>
            <v-col>

                <v-card class="pa-4">

                    <v-form
                        class="mb-6"
                        ref="form"
                        lazy-validation
                        @submit.prevent="getData()"
                    >

                        <v-row>
                            <v-col cols="4">

                                <div class="mb-1" style="color: rgba(0, 0, 0, 0.6);">Select Subscriptions</div>

                                <v-card
                                    class="mx-auto pa-0"
                                    outlined
                                    color="rgba(0, 0, 0, 0.2)"
                                    style="max-height: 300px; overflow: auto;"
                                >
                                    <v-list>
                                        <v-list-item-group
                                            v-model="subscriptionsSelected"
                                            multiple
                                        >
                                            <template v-for="(application, i) in subscriptions">
                                                <v-divider
                                                    v-if="i !== 0"
                                                    :key="`divider-${i}`"
                                                ></v-divider>

                                                <div class="mt-2 px-3" :key="i">{{ application.name }}</div>
                                                
                                                <template v-if="!application.subscriptions.length">
                                                    <p class="px-5" :key="`no-items-${i}`">No subscriptions</p>
                                                </template>
                                                <template v-for="subscription in application.subscriptions">
                                                    <v-list-item
                                                        :key="`item-${subscription.id}`"
                                                        :value="subscription.id"
                                                        active-class="primary--text text--accent-4"
                                                    >
                                                        <template v-slot:default="{ active }">
                                                            <v-list-item-action>
                                                                <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                                ></v-checkbox>
                                                            </v-list-item-action>

                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="subscription.name"></v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                                                    </v-list-item>
                                                </template>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>

                                <div class="my-2 mb-4">
                                    <v-chip
                                        v-for="(subscriptionId, i) in subscriptionsSelected"
                                        :key="i"
                                        class="mr-2 mb-1"
                                        close
                                        @click:close="clearSelectedSubscriptionById(subscriptionId)"
                                    >
                                        {{ getSubscriptionNameById(subscriptionId) }}
                                    </v-chip>
                                </div>
                                
                                <v-text-field
                                    v-model="subscriptionsFilter"
                                    label="Subscriptions filter"
                                    clearable
                                ></v-text-field>

                                <v-menu
                                    v-model="dateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateFrom"
                                            label="Date From"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateFrom"
                                        @input="dateFromMenu = false"
                                    ></v-date-picker>
                                </v-menu>

                                <v-menu
                                    v-model="dateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateTo"
                                            label="Date To"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateTo"
                                        @input="dateToMenu = false"
                                    ></v-date-picker>
                                </v-menu>

                                <v-autocomplete
                                    v-model="selectedCountry"
                                    :items="countries"
                                    label="Geo"
                                    clearable
                                ></v-autocomplete>

                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="d-flex justify-space-between">

                                <v-btn
                                    class="mt-2"
                                    depressed
                                    color="primary"
                                    type="submit"
                                >
                                    Get Data
                                </v-btn>

                                <v-menu
                                    v-model="tableSettingsMenu"
                                    :close-on-content-click="false"
                                    :nudge-width="200"
                                    offset-x
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="mt-2"
                                            depressed
                                            type="button"
                                            plain
                                            color="black"
                                            v-bind="attrs"
                                            v-on="on"
                                            v-if="tableSettings"
                                        >
                                            <v-icon>mdi-cog</v-icon>Table Settings
                                        </v-btn>
                                    </template>
                                    <v-card class="px-4 py-4">
                                        <div style="height: 400px; overflow-y: scroll;">
                                            <div v-for="item in tableSettings.dataHeaders" :key="item.value">
                                                <v-checkbox
                                                    v-model="item.visible"
                                                    :label="item.text"
                                                    class="mt-0"
                                                ></v-checkbox>
                                            </div>
                                        </div>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                @click="tableSettingsMenu = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                text
                                                @click="updateTableSettings()"
                                                :loading="showTableSettingsLoading"
                                            >
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>

                            </v-col>
                        </v-row>

                    </v-form>

                    <v-data-table
                        :headers="getDataHeaders()"
                        hide-default-header
                        :sort-by="sortParams.by"
                        :sort-desc="sortParams.descending"
                        :items="data"
                        class="elevation-0 no-wrap"
                        :loading="isDataLoading === true"
                        loading-text="Loading... Please wait"
                        fixed-header
                        width="100%"
                        :height="data.length ? '100vh' : 'auto'"
                        hide-default-footer
                        disable-pagination
                    >
                        <template #header="{ }">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th 
                                        v-for="item in getDataHeaders()" 
                                        :key="item.value"
                                        v-bind:class="[item.sortable ? 'sortable' : '', sortParams.by == item.value ? 'active': '', (sortParams.by != item.value ? 'desc' : (sortParams.descending ? 'desc':'asc'))]"
                                        @click="item.sortable ? changeSort(item.value) : ''"
                                    >
                                        <div>{{ item.text }}</div>
                                        <v-icon v-if="item.sortable" class="v-data-table-header__icon" small>mdi-arrow-up</v-icon>
                                    </th>
                                </tr>
                                <tr v-if="data.length && tableSettings" class="total">
                                    <th :colspan="countNullTotalHeaders()" v-if="countNullTotalHeaders()"></th>
                                    <th v-for="header in getVisibleTableHeaders().filter(h => total[h.value] !== null)" :key="header.value">
                                        {{ formatTableData(total[header.value], header.dataFormat) }}
                                        <span v-if="header.dataAppend">&nbsp;{{ header.dataAppend }}</span>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-for="header in getVisibleTableHeaders()" v-slot:[`item.${header.value}`]="{ item }">
                            <div :key="header.value" :style="`max-width: ${getHeaderCustomSettingsParam(header.value, 'width')}; white-space: nowrap; overflow: hidden;`">
                                <span v-if="header.displayValue">
                                    {{ formatTableData(item[header.displayValue], header.dataFormat) }}
                                </span>
                                <span v-else>
                                    {{ formatTableData(item[header.value], header.dataFormat) }}
                                </span>
                                <span v-if="header.dataAppend">&nbsp;{{ header.dataAppend }}</span>
                            </div>
                        </template>
                    </v-data-table>

                </v-card>

            </v-col>
        </v-row>
        <v-dialog v-model="loading" fullscreen>
            <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
                <v-layout justify-center align-center>
                    <v-progress-linear
                        color="light-blue"
                        height="20"
                        :value="loadingProgress"
                        striped
                        style="max-width: 600px;"
                    ></v-progress-linear>
                </v-layout>
            </v-container>
        </v-dialog>
    </v-container>
</template>

<style>
    .no-wrap table th {
        white-space: nowrap !important;
        min-width: 3%;
    }
</style>


<script>

import {objectToQueryString} from "../../../utils/helpers";
import {converValidationServerErrorsToString} from "../../../utils/errorUtil";

export default {
    name: "AdminDashboardSummaryAppSubPage",
    created() {
    },
    watch: {
        subscriptionsSelected: function (newValue, oldValue) {
            localStorage.setItem('dashboard_all_apps__selected_subscription_ids', JSON.stringify(newValue));
        },
        subscriptionsFilter: function (newValue, oldValue) {
            localStorage.setItem('dashboard_all_apps__subscriptions_filter', JSON.stringify(newValue));
        },
        dateFrom: function (newValue, oldValue) {
            localStorage.setItem('dashboard_summary_app_sub__date_from', JSON.stringify(newValue));
        },
        dateTo: function (newValue, oldValue) {
            localStorage.setItem('dashboard_summary_app_sub__date_to', JSON.stringify(newValue));
        },
        selectedCountry: function (newValue, oldValue) {
            localStorage.setItem('dashboard_summary_app_sub__selected_country', JSON.stringify(newValue));
        },
    },
    async mounted() {
        await this.getSubscriptions(() => {
            this.restoreSelectedSubscriptions();
        });
        this.restoreSubscriptionsFilter();
        this.getCountries(() => {
            this.restoreSelectedCountry();
        });
        this.getTableSettings();
        this.restoreSelectedDates();
    },
    data() {
        return {
            loading: false,
            loadingProgress: 0,
            subscriptionLoaded: 0,
            subscriptionsSelected: [],
            subscriptions: [],
            subscriptionsFilter: '',
            dateFromMenu: false,
            dateToMenu: false,
            dateFrom: null,
            dateTo: null,
            selectedCountry: null,
            countries: [],
            rules: {
            },
            tableSettings: [],
            sortParams: {
                by: 'leads',
                descending: true,
            },
            data: [],
            total: {},
            isDataLoading: false,
            tableSettingsMenu: null,
            showTableSettingsLoading: false,
            headersCustomSettings: {},
        }
    },
    methods: {
        async getSubscriptions(callback) {
            callback = callback || function () {};
            try {
                const response = await this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/subscriber/subscriptions`)
                this.subscriptions = response.data.subscriptions;
                callback();
            } catch (err) {
                alert(converValidationServerErrorsToString(err));
            }
        },
        getSubscriptionNameById(subscriptionId)
        {
            for (let i in this.subscriptions) {
                const applicationName = this.subscriptions[i].name;
                if (this.subscriptions[i].subscriptions.length) {
                    for (let j in this.subscriptions[i].subscriptions) {
                        if (this.subscriptions[i].subscriptions[j].id == subscriptionId) {
                            return `${applicationName}: ${this.subscriptions[i].subscriptions[j].name}`;
                        }
                    }
                }
            }
            return subscriptionId;
        },
        clearSelectedSubscriptionById(subscriptionId)
        {
            const index = this.subscriptionsSelected.indexOf(subscriptionId);
            if (index !== -1) {
                this.subscriptionsSelected.splice(index, 1);
            }
        },
        restoreSelectedSubscriptions() {
            const selectedIdsJson = localStorage.getItem('dashboard_all_apps__selected_subscription_ids');
            if (selectedIdsJson) {
                try {
                    this.subscriptionsSelected = JSON.parse(selectedIdsJson);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        restoreSubscriptionsFilter() {
            const stringJson = localStorage.getItem('dashboard_all_apps__subscriptions_filter');
            if (stringJson) {
                try {
                    this.subscriptionsFilter = JSON.parse(stringJson);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        restoreSelectedDates() {
            const dateFrom = localStorage.getItem('dashboard_summary_app_sub__date_from');
            const dateTo = localStorage.getItem('dashboard_summary_app_sub__date_to');
            
            if (dateFrom) {
                try {
                    this.dateFrom = JSON.parse(dateFrom);
                } catch (err) {
                    console.log(err);
                }
            }

            if (dateTo) {
                try {
                    this.dateTo = JSON.parse(dateTo);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        getCountries(callback) {
          this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries`)
              .then((response) => {
                this.initialCountries = response.data;
                this.fillCountries();
                callback();
              })
              .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        fillCountries() {
          for (let i in this.initialCountries) {
            if (Object.prototype.hasOwnProperty.call(this.initialCountries, i)) {
              let abbreviations = this.initialCountries[i].abbreviations.map(item => item.abbreviation);
              this.countries.push({ text: `${this.initialCountries[i].name}`, value: abbreviations[0]});
            }
          }
        },
        restoreSelectedCountry()
        {
            const idString = localStorage.getItem('dashboard_summary_app_sub__selected_country');
            if (idString) {
                const id = JSON.parse(idString);
                this.selectedCountry = id;
            }
        },
        async getTableSettings() {
            await this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/table/dashboard_summary_app_sub/settings`)
                .then((response) => {
                    this.tableSettings = response.data;
                })
                .catch(err => alert(converValidationServerErrorsToString(err)));
        },
        updateTableSettings() {
            this.showTableSettingsLoading = true;
            this.$http.post(
                `${process.env.VUE_APP_SERVER_BASE_URL}/admin/table/dashboard_summary_app_sub/settings`,
                this.tableSettings
            )
                .then(() => {
                    this.showTableSettingsLoading = false;
                    this.tableSettingsMenu = false;
                 })
                .catch(err => {
                    this.showTableSettingsLoading = false;
                    alert(converValidationServerErrorsToString(err));
                });
        },
        getDataHeaders() {
            if (!this.tableSettings) {
                return [];
            }

            let dataHeaders = [];

            for (let i in this.tableSettings.dataHeaders) {
                const header = this.tableSettings.dataHeaders[i];
                if (!header.visible) {
                    continue;
                }

                dataHeaders.push({
                    text: header.text,
                    value: header.value,
                    sortable: true,
                });
            }

            return dataHeaders;
        },
        updateLoadingProgress() {
            const totalSubscriptions = this.subscriptionsSelected.length;
            const totalLoadedSubscriptions = this.subscriptionLoaded.length;
            this.loadingProgress = parseInt((totalLoadedSubscriptions / totalSubscriptions) * 100);
            console.log(this.loadingProgress);
        },
        async getData() {
            this.loading = true;
            this.loadingProgress = 0;
            this.data = [];
            this.subscriptionLoaded = [];
            await this.executeData();
            await this.getDataTotal();
            this.loading = false;
        },
        async getDataTotal() {
            const postData = {
                rows: this.data,
            };
            try {
                const response = await this.$http.post(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/statistic/summary-app-sub/total`,
                    postData
                );
                this.total = response.data.data.result;
            } 
            catch (err) {
                alert(converValidationServerErrorsToString(err));
            }
        },
        async executeData() {
            let promises = this.subscriptionsSelected.map(subscriptionId => {
                const subscriptionName = this.getSubscriptionNameById(subscriptionId);
                const filter = this.subscriptionsFilter || '';
                let filterStrings = filter.split(',');
                filterStrings = filterStrings.map(string => string.trim());
                const result = filterStrings.some(subStr => subscriptionName.toLowerCase().includes(subStr.toLowerCase()));
                if (!this.subscriptionsFilter || result) {
                    return this.getDataForSubscription(subscriptionId)
                }
            });
            return Promise.all(promises);
        },
        async getDataForSubscription(subscriptionId) {
            const postData = {
                subscriptions: [subscriptionId],
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                geo: this.selectedCountry,
            };
            try {
                const response = await this.$http.post(
                    `${process.env.VUE_APP_SERVER_BASE_URL}/admin/applications/statistic/summary-app-sub`,
                    postData
                );
                response.data.data.result.map((item) => {
                    this.data.push(item);
                });
                this.subscriptionLoaded.push(subscriptionId);
                this.updateLoadingProgress();
            } 
            catch (err) {
                alert(converValidationServerErrorsToString(err));
            }
        },
        formatDecimals(num, decimals) {
            return (Math.round(num * 100) / 100).toFixed(decimals);
        },
        changeSort(column) {
            if (this.sortParams.by === column) {
                this.sortParams.descending = !this.sortParams.descending;
            } else {
                this.sortParams.by = column;
                this.sortParams.descending = true;
            }
        },
        formatTableData(value, format) {
            const formatParts = format.split(',');
            if (formatParts[0] == 'decimal') {
                return this.formatDecimals(value, formatParts[1]);
            }
            return value;
        },
        getVisibleTableHeaders() {
            if (!this.tableSettings || !this.tableSettings.dataHeaders) {
                return [];
            }
            return this.tableSettings.dataHeaders.filter(h => h.visible);
        },
        countNullTotalHeaders() {
            let counter = 0;
            const tableHeaders = this.getVisibleTableHeaders();
            for (let i in tableHeaders) {
                if (this.total[tableHeaders[i].value] === null) {
                    counter++;
                }
            }
            return counter;
        },
        getHeaderCustomSettingsParam(header, param) {
            const headerCustomSettings = this.headersCustomSettings[header];
            if (!headerCustomSettings) {
                return null;
            }
            return headerCustomSettings[param] || null;
        }
    }
}
</script>