require('dotenv').config()

export const rolesEnum = [
    {
        id: 1,
        name: 'admin',
    },
]

export const rolesList = {
    admin: 'admin',
}

export const defaultStatusesEnum = [
    {
        name: 'active',
        title: 'Активен',
        color: 'green',
    },
    {
        name: 'deleted',
        title: 'Удалено',
        color: 'red',
    },
    {
        name: 'wait',
        title: 'На паузе',
        color: 'secondary',
    },
]

export const crudActions = {
    create: 'create',
    update: 'update',
    delete: 'delete',
    register: 'register',
    remote: 'remote',
}