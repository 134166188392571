<template>

    <div>

        <v-chip
            v-if="this.$props.parser.status === 'waiting'"
            color="gray"
            text-color="black"
        >
            Waiting
        </v-chip>

        <v-chip
            v-else-if="this.$props.parser.status === 'started'"
            color="orange"
            text-color="white"
        >
            Started at {{ formatDate(this.$props.parser.started_at) }}
        </v-chip>

        <v-chip
            v-else-if="this.$props.parser.status === 'completed'"
            color="green"
            text-color="white"
        >
            Completed at {{ formatDate(this.$props.parser.completed_at) }}
        </v-chip>
        
        <v-chip
            v-else-if="this.$props.parser.status === 'failed'"
            color="red"
            text-color="white"
        >
            Failed at {{ formatDate(this.$props.parser.failed_at) }}
        </v-chip>

        <v-chip
            v-else
            color="yellow"
            text-color="black"
        >
            Unknown status
        </v-chip>

        <v-btn
            @click="sync"
            text
            icon
            color="black"
            :disabled="isSyncDisabled()"
            :loading="loading"
        >
            <v-icon>
                mdi-sync
            </v-icon>
        </v-btn>

    </div>

</template>

<script>
    import {convertUtcToDate} from "../../utils/converters"
    import {converValidationServerErrorsToString} from "../../utils/errorUtil";

    export default {
        name: "ManualReviewsParserStatus",
        props: ['parser'],
        data() {
            return {
                loading: false,
            }
        },
        mounted() {
        },
        created() {
        },
        methods: {
            isSyncDisabled() {
                if (this.$props.parser.status === 'completed' || this.$props.parser.status === 'failed') {
                    if (!this.loading) {
                        return false;
                    }
                }
                return true;
            },
            sync() {
                this.loading = true;
                this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/review-parser/${this.$props.parser.id}/run`)
                    .then(response => this.$router.go())
                    .catch(err => alert(converValidationServerErrorsToString(err)));
            },
            formatDate(date) {
                return convertUtcToDate(date, 'Europe/Moscow');
            },
        },
    }
</script>