<template>
    <v-container fluid>
        <v-row>
            <v-col>

                <v-card>
                    
                    <v-card-title>
                        Reviews Parsers
                        <ManualReviewsParserControlDialog :parser="null" />
                        <v-spacer></v-spacer>
                        <ManualReviewsProxyControlDialog :settings="this.settings" />
                    </v-card-title>

                    <v-card-text>

                        <v-data-table
                            :headers="headers"
                            :items="parsers"
                            :items-per-page="50"
                            class="elevation-1"
                        >
                            <template v-slot:item.app="{ item }">
                                <router-link :to="getResultsUrl(item)">{{ item.app }}</router-link>
                            </template>
                            <template v-slot:item.reviews="{ item }">
                                <v-chip v-if="item.reviews == 0" class="gray" :to="getResultsUrl(item)">{{ item.reviews }}</v-chip>
                                <v-chip v-else class="info" :to="getResultsUrl(item)">{{ item.reviews }}</v-chip>
                            </template>
                            <template v-slot:item.status="{ item }">
                                <ManualReviewsParserStatus :parser="item" />
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <ManualReviewsParserControlDialog :parser="item" />
                                <AgreeDialog @remove="remove(item)" class="ml-2"/>
                            </template>
                        </v-data-table>

                    </v-card-text>

                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    import ManualReviewsParserControlDialog from "../../components/ManualReviews/ManualReviewsParserControlDialog";
    import ManualReviewsProxyControlDialog from "../../components/ManualReviews/ManualReviewsProxyControlDialog";
    import ManualReviewsParserStatus from "../../components/ManualReviews/ManualReviewsParserStatus";
    import AgreeDialog from "../../components/AgreeDialog";

    import {converValidationServerErrorsToString} from "../../utils/errorUtil";

    export default {
        name: "ManualReviewsParsingPage",
        components: {
            ManualReviewsParserControlDialog,
            ManualReviewsProxyControlDialog,
            ManualReviewsParserStatus,
            AgreeDialog,
        },
        created() {
            this.getSettings();
            this.getReviewParsersList();
        },
        mounted() {
        },
        data: function () {
            return {
                dialog: null,
                valid: null,
                headers: [
                    { text: 'Application', value: 'app' },
                    { text: 'Reviews', value: 'reviews' },
                    { text: 'Parsing status', value: 'status' },
                    { text: '', value: 'actions' },
                ],
                settings: [],
                parsers: [],
            }
        },
        methods: {
            getSettings() {
                this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/review-parsers/settings?`)
                    .then((response) => {
                        this.settings = response.data.settings;
                        console.log('settings: ', this.settings);
                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)));
            },
            getReviewParsersList() {
                this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/review-parsers?`)
                    .then((response) => {
                        this.parsers = response.data.parsers;
                        console.log('parsers: ', this.parsers);
                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)));
            },
            getResultsUrl(parser) {
                return `/reviews-parsing/parser/${parser.id}/results`;
            },
            remove(parser) {
                this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/review-parser/${parser.id}?`)
                    .then((response) => {
                        this.$router.go();
                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)));
            },
        },
    }

</script>