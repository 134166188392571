<template>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen hide-overlay>
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" :color="getIconColor()" v-bind="attrs" v-on="on" fab x-small dark>
                <v-icon aria-hidden="false">
                    {{ getIcon() }}
                </v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-toolbar-title>
                    Application
                </v-toolbar-title>

                <v-spacer/>

                <v-toolbar-items>
                    <v-btn @click="dialog = false" dark text>
                        Закрыть
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-row>
                <v-col>
                    <component v-bind:is="this.getComponent()"  :action="action" :country="country"></component>
                </v-col>
            </v-row>

            <v-divider/>
        </v-card>
    </v-dialog>
</template>

<script>
    import {eventBus, eventBusEvents} from "../../../eventBus/eventBus";

    import {crudActions} from "../../../enums/enums";
    import CountryPanel from "./CountryPanel";

    export default {
        name: 'CountryControlDialog',
        props: ['country', 'action'],
        components: {
            CountryPanel,
        },
        data() {
            return {
                dialog: false
            }
        },
        mounted() {
          console.log(this.country);
        },
        computed: {
            crudActions: function () {
                return crudActions
            }
        },
        created() {
            eventBus.$on(eventBusEvents.EVENT_REFRESH_COUNTRIES_BY_ADMIN, async () => {
                this.close()
            })
        },
        methods: {
            getComponent()
            {
                return 'CountryPanel';
            },
            getIcon() {
              if(this.action == 'update')
              {
                  return 'mdi-pencil';
              }
              else if (this.action == 'remote')
              {
                  return 'mdi-remote'
              }
              return 'mdi-plus';
            },
            getIconColor() {
                if(this.action == 'update')
                {
                  return 'orange';
                }
                else if (this.action == 'remote')
                {
                  return 'green';
                }
                return 'info';
            },
            close() {
                this.dialog = false
            }
        },
    }
</script>

<style scoped>

</style>
