<template>
  <v-container v-if="success || error">
    <!-- Успешное уведомление -->
    <v-snackbar v-model="success" :timeout="4000" color="green" top right>
      {{ successMessage }}
    </v-snackbar>

    <!-- Ошибочное уведомление -->
    <v-snackbar v-model="error" :timeout="4000" color="red" top right>
      {{ errorMessage }}
      <!-- <v-btn color="white" text @click="error = false"> Close </v-btn> -->
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      success: false,
      error: false,
      successMessage: "",
      errorMessage: "",
    };
  },
  methods: {
    showSuccess(message) {
      this.successMessage = message;
      this.success = true;
    },
    showError(message) {
      this.errorMessage = message;
      this.error = true;
    },
  },
};
</script>
