import HomePage from "../pages/HomePage";

import AdminApplicationsPage from "../pages/Admin/Applications/AdminApplicationsPage";
import AdminVendorsPage from "../pages/Admin/Vendors/AdminVendorsPage";

// import AdminApplicationsPage from "../pages/Admin/Application/AdminApplicationsPage";
import AdminDataPage from "../pages/Admin/Data/AdminDataPage";
import AdminRetentionPage from "../pages/Admin/Retention/AdminRetentionPage";
import AdminRetentionV2Page from "../pages/Admin/RetentionV2/AdminRetentionV2Page";
import AdminRetentionV3Page from "../pages/Admin/RetentionV3/AdminRetentionV3Page";
import AdminRetentionV4Page from "../pages/Admin/RetentionV4/AdminRetentionV4Page";
import AdminReviewsPage from "../pages/Admin/Reviews/AdminReviewsPage";
import AdminMailboxIndexPage from "../pages/Admin/Mailbox/AdminMailboxIndexPage";
import StatisticPage from "../pages/Admin/Statistic/StatisticPage";
import AdminDashboardPage from "../pages/Admin/Dashboard/AdminDashboardPage";
import AdminDashboardAllAppsPage from "../pages/Admin/Dashboard/AdminDashboardAllAppsPage";
import AdminDashboardSummaryAppSubPage from "../pages/Admin/Dashboard/AdminDashboardSummaryAppSubPage";
import AdminDashboardByEventsPage from "../pages/Admin/Dashboard/AdminDashboardByEventsPage";

import ManualReviewsParsingPage from "../pages/ManualReviews/ManualReviewsParsingPage";
import ManualReviewsParserResultsPage from "../pages/ManualReviews/ManualReviewsParserResultsPage";
import Countries from "../pages/Admin/Countries/Countries";
import Settings from "../pages/Admin/Settings";
import Users from "../pages/Admin/Settings/Users/Users";

const LoginPage = require("../pages/LoginPage").default;
const AdminPage = require("../pages/Admin/AdminPage").default;

export const routes = [
  {
    path: "",
    name: "Home",
    component: HomePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      guest: true,
    },
  },
  {
    path: "/reviews-parsing",
    name: "Review Parsing",
    component: ManualReviewsParsingPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reviews-parsing/parser/:parserId/results",
    name: "Review Parsing Results",
    component: ManualReviewsParserResultsPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/admin",
    component: AdminPage,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: "settings",
        component: Settings,
      },
      {
        path: "settings/users",
        component: Users,
        meta: {
          access: "settings",
        },
      },
      {
        path: "settings/dictionary/countries",
        component: Countries,
        meta: {
          access: "settings",
        },
      },
      {
        path: "",
        component: HomePage,
      },
      {
        path: "applications",
        component: AdminApplicationsPage,
        meta: {
          access: "settings",
        },
      },
      {
        path: "applications/vendors",
        component: AdminVendorsPage,
        meta: {
          access: "settings",
        },
      },
      {
        path: "data",
        component: AdminDataPage,
        meta: {
          access: "data.view",
        },
      },
      {
        path: "retention",
        component: AdminRetentionPage,
        meta: {
          access: "retention.view",
        },
      },
      {
        path: "retention-v2",
        component: AdminRetentionV2Page,
        meta: {
          access: "retention.view",
        },
      },
      {
        path: "retention-v3",
        component: AdminRetentionV3Page,
        meta: {
          access: "retention.view",
        },
      },
      {
        path: "retention-v4",
        component: AdminRetentionV4Page,
        meta: {
          access: "retention.view",
        },
      },
      {
        path: "statistic",
        component: StatisticPage,
        meta: {
          access: "dashboard.view",
        },
      },
      {
        path: "dashboard",
        component: AdminDashboardPage,
        meta: {
          access: "dashboard-bu-sub.view",
        },
      },
      {
        path: "dashboard-all-apps",
        component: AdminDashboardAllAppsPage,
        meta: {
          access: "dashboard-bu-sub.view",
        },
      },
      {
        path: "dashboard-summary-app-sub",
        component: AdminDashboardSummaryAppSubPage,
        meta: {
          access: "dashboard-bu-sub.view",
        },
      },
      {
        path: "dashboard-by-events",
        component: AdminDashboardByEventsPage,
        meta: {
          access: "dashboard.view",
        },
      },
      {
        path: "reviews",
        component: AdminReviewsPage,
        meta: {
          access: "reviews.view",
        },
      },
      {
        path: "mailbox",
        component: AdminMailboxIndexPage,
        meta: {
          access: "mail.view",
        },
      },
    ],
  },
];
