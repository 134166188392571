<template>
    <v-container fluid>
        <v-row>
            <v-col>

                <v-card class="pa-4">

                    <v-row>
                        <v-col cols="6">

                            <v-select
                                item-text="app"
                                item-value="id"
                                v-model="applicationSelected"
                                :items="applications"
                                label="Application"
                                @change="getReviewsForApplication(applicationSelected)"
                            ></v-select>

                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4">
                            <div class="d-flex">
                                <v-select
                                    v-model="selectedRating"
                                    :items="ratings"
                                    placeholder="Rating"
                                    clearable
                                    :filled="selectedRating !== null"
                                    @change="updateReviewsWithFilters()"
                                ></v-select>
                                <v-autocomplete
                                    class="ml-4"
                                    v-model="selectedCountry"
                                    :items="countries"
                                    :filter="filterCountries"
                                    placeholder="Geo"
                                    clearable
                                    :filled="selectedCountry !== null"
                                    @change="updateReviewsWithFilters()"
                                ></v-autocomplete>
                            </div>
                        </v-col>
                    </v-row>

                    <div class="d-flex">
                        <v-card
                            class="mt-2 mb-6 mr-4 elevation-0"
                            outlined
                            max-width="344"
                        >
                            <v-card-title>Ratings Summary</v-card-title>
                            <v-card-text>
                                <div>Average user rating: <strong>{{ getAppAverageUserRating() || '--' }}</strong></div>
                                <div>User rating count: <strong>{{ getAppUserRatingCount() || '--' }}</strong></div>
                            </v-card-text>
                        </v-card>

                        <v-card
                            class="mt-2 mb-6 elevation-0"
                            outlined
                            max-width="344"
                        >
                            <v-card-title>Reviews Summary</v-card-title>
                            <v-card-text>
                                <div>Average rating: <strong>{{ getReviewsAverageUserRating() || '--' }}</strong></div>
                                <div>Total reviews: <strong>{{ getReviewsUserRatingCount() || '--' }}</strong></div>
                            </v-card-text>
                        </v-card>
                    </div>

                    <v-data-table
                        :headers="reviewHeaders"
                        :items="reviews"
                        :items-per-page="50"
                        :sort-by="'raw_updated'"
                        :sort-desc="true"
                        class="elevation-0"
                        :loading="showReviewsLoading === true"
                        loading-text="Loading... Please wait"
                    >
                        <template v-slot:item.content="{ item }">
                          <div class="mt-2 mb-2">{{ item.content }}</div>
                        </template>
                        <template v-slot:item.raw_updated="{ item }">
                            <v-chip>{{ item.updated }}</v-chip>
                        </template>

                    </v-data-table>

                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    import {converValidationServerErrorsToString} from "../../utils/errorUtil";

    export default {
        name: "ManualReviewsParserResultsPage",
        created() {

        },
        mounted() {
            this.getApplicationList();
            this.getCountries();
        },
        data() { //  ( дата-автор-заголовок-оценка-текст отзыва-voteSum-voteCount)
            return {
                applicationSelected: null,
                applications: [],
                ratings: [],
                selectedRating: null,
                initialCountries: [],
                countries: [],
                selectedCountry: null,
                reviewHeaders: [
                    { text: 'Date', value: 'raw_updated', width: '8%' },
                    { text: 'Author', value: 'author_name' },
                    { text: 'Country', value: 'country' },
                    { text: 'Title', value: 'title' },
                    { text: 'Rating', value: 'rating', width: '8%' },
                    { text: 'Review', value: 'content', width: '40%' },
                    { text: 'voteSum', value: 'vote_sum', width: '8%' },
                    { text: 'voteCount', value: 'vote_count', width: '8%' },
                ],
                initialReviews: [],
                reviews: [],
                showReviewsLoading: false,
            }
        },
        methods: {
            getApplicationList() {
                this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/review-parsers?`)
                    .then((response) => {

                        this.applications = response.data.parsers;

                        console.log('initial parserId: ' + this.$route.params.parserId);

                        this.$nextTick(() => {
                            this.applicationSelected = parseInt(this.$route.params.parserId);
                            this.getReviewsForApplication(this.applicationSelected);
                        });

                        console.log(this.applications);
                        
                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)));
            },
            getCountries() {
                this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/dictionaries/countries?`)
                    .then((response) => {
                        this.initialCountries = response.data;
                        console.log('initial countries', this.initialCountries);
                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)));
            },
            getReviewsForApplication(applicationId) {

                this.showReviewsLoading = true;

                this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/review-parser/${applicationId}/results?`)
                    .then((response) => {

                        this.initialReviews = response.data.data.reviews;
                        this.updateReviewsWithFilters();

                        this.showReviewsLoading = false;

                    })
                    .catch(err => alert(converValidationServerErrorsToString(err)));

            },
            updateReviewsWithFilters() {

                console.log('selected country: ', this.selectedCountry);

                this.reviews = [];

                for (let i in this.initialReviews) {

                    if (this.selectedRating !== null) {
                        if (this.selectedRating != this.initialReviews[i].rating) {
                            continue;
                        }
                    }

                    if (this.selectedCountry !== null) {
                        console.log('iteration country: ', this.initialReviews[i].country);
                        if (this.selectedCountry != this.initialReviews[i].country) {
                            continue;
                        }
                    }

                    this.reviews.push(this.initialReviews[i]);
                }

                this.fillRatingsFromReviews();
                this.fillCountriesFromReviews();

            },
            fillRatingsFromReviews() {

                this.ratings = [
                    { text: `1-Star`, value: 1},
                    { text: `2-Star`, value: 2},
                    { text: `3-Star`, value: 3},
                    { text: `4-Star`, value: 4},
                    { text: `5-Star`, value: 5},
                ];
            },
            fillCountriesFromReviews() {

                for (let i in this.initialCountries) {
                    if (Object.prototype.hasOwnProperty.call(this.initialCountries, i)) {
                        this.countries.push({ text: `${this.initialCountries[i]}`, value: i });
                    }
                }

            },
            filterCountries (item, queryText, itemText) {
                console.log(item, queryText);
                const textOne = item.text.toLowerCase();
                const textTwo = `${item.value}`;

                const searchText = queryText.toLowerCase();

                return textOne.indexOf(searchText) > -1 ||
                    textTwo.indexOf(searchText) > -1;
            },
            getSelectedApplication() {
                if (this.applicationSelected === null) {
                    return null;
                }

                for (let i in this.applications) {
                    if (this.applications[i].id === this.applicationSelected) {
                        return this.applications[i];
                    }
                }

                return null;
            },
            getAppAverageUserRating() {
                const app = this.getSelectedApplication();
                if (!app) {
                    return null;
                }
                return this.getSelectedApplication().average_user_rating || 0;
            },
            getAppUserRatingCount() {
                const app = this.getSelectedApplication();
                if (!app) {
                    return null;
                }
                return this.getSelectedApplication().user_rating_count || 0;
            },
            getReviewsAverageUserRating() {

                if (!this.reviews.length) {
                    return null;
                }

                let ratings = [];

                for (let i in this.reviews) {
                    ratings.push(this.reviews[i].rating);
                }

                const ratingsSum = ratings.reduce((a, b) => a + b);
                const ratingsCount = ratings.length;

                const rating = ratingsSum / ratingsCount;

                return (Math.round(rating * 100) / 100).toFixed(1);

            },
            getReviewsUserRatingCount() {

                return this.reviews.length || null;

            }
        },
    }

</script>
