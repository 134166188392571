<template>
    <v-card>
        <v-card-title>
            {{ getTitle() }}
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <CountryForm
                            v-if="this.actionLocal"
                            :action="actionLocal"
                            :country="countryLocal"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import {crudActions} from "../../../enums/enums";
    import {eventBus, eventBusEvents} from "../../../eventBus/eventBus";
    import CountryForm from "./CountryForm";

    export default {
        name: "CountryPanel",
        props: ['action', 'country'],
        components: {
            CountryForm,
        },
        data() {
            return {
                actionLocal: null,
                countryLocal: null,
            }
        },
        mounted() {
            this.actionLocal = this.action
            this.countryLocal = this.country
        },
        created() {
            eventBus.$on(eventBusEvents.EVENT_EDIT_APPLICATION_BY_ADMIN, async () => {
                this.actionLocal = this.crudActions.update
            });
            eventBus.$on(eventBusEvents.EVENT_REFRESH_APPLICATION_BY_ADMIN, async () => {
                this.actionLocal = this.crudActions.create
            });
        },
        computed: {
            crudActions: function () {
                return crudActions
            },
        },
        methods: {
            goToCreate() {
                this.actionLocal = this.crudActions.create
                eventBus.$emit(eventBusEvents.EVENT_REFRESH_APPLICATION_BY_ADMIN)
            },
            isActionCreate() {
                return this.actionLocal === this.crudActions.create
            },
            isActionUpdate() {
                return !this.isActionCreate()
            },
            getTitle() {
                if (this.actionLocal === this.crudActions.update) {
                    return 'Edit country';
                }
                return 'Add country';
            },
        }
    }
</script>

<style scoped>

</style>
