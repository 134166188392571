import Vue from 'vue'

export const eventBus = new Vue();

export const eventBusEvents = {
    EVENT_REFRESH_APPLICATION_BY_ADMIN: "eventBus_EVENT_REFRESH_APPLICATION_BY_ADMIN",
    EVENT_REFRESH_COUNTRIES_BY_ADMIN: "eventBus_EVENT_REFRESH_COUNTRIES_BY_ADMIN",
    EVENT_EDIT_APPLICATION_BY_ADMIN: "eventBus_EVENT_EDIT_APPLICATION_BY_ADMIN",
    EVENT_CREATE_APPLICATION_BY_ADMIN: "eventBus_EVENT_CREATE_APPLICATION_BY_ADMIN",
    EVENT_FILTER_APPLICATION_BY_ADMIN: "eventBus_EVENT_FILTER_APPLICATION_BY_ADMIN",
}
