<template>
    <div>
        <v-container fluid>

            <v-row>
                <v-col>

                    <v-card class="pa-4">
                        <v-row>
                          <CountryControlDialog :action="crudActions.create"/>
                        </v-row>
                        <v-row>
                            <v-data-table
                            :items="countries"
                            :headers="this.header"
                            >
                                <template v-slot:item.actions="{ item }">
                                  <CountryControlDialog :country="item" :action="crudActions.update"/>
                                  <AgreeDialog @remove="remove(item)" class="ml-2"/>
                                </template>

                            </v-data-table>
                        </v-row>

                    </v-card>

                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>
import {converValidationServerErrorsToString} from "../../../utils/errorUtil";
import AgreeDialog from "../../../components/AgreeDialog";
import CountryControlDialog from "./CountryControlDialog";
import {crudActions} from "../../../enums/enums";
import {eventBus, eventBusEvents} from "../../../eventBus/eventBus";

export default {
    name: "Countries",



    data() {
      return {
        countries: [],
        header: [
          { text: 'Countries', value: 'name'},
          { text: 'Abbreviations', value: 'abbreviations'},
          { text: 'Tier', value: 'tier'},
          { text: '', value: 'actions'},
        ],
      }
    },
    components: {
        AgreeDialog,
        CountryControlDialog,
    },
    computed: {
      crudActions: function () {
        return crudActions
      }
    },
    mounted() {
      this.getCountries();
    },
    created() {
      eventBus.$on(eventBusEvents.EVENT_REFRESH_COUNTRIES_BY_ADMIN, async () => {
        this.getCountries();
      });
    },
    methods: {
        remove(country) {
          this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries/${country.id}`).then(response => {
            console.log('application removed', response.data)
            this.getCountries()
          }).catch(err => alert(converValidationServerErrorsToString(err)));
        },

        getCountries() {
          this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/dictionary/countries`)
              .then((response) => {
                 this.countries = this.formatCountries(response.data);
              })
              .catch(err => alert(converValidationServerErrorsToString(err)));
        },

        formatCountries(countries) {
          let result = [];
          countries.forEach((country) => {
            let abbreviations = country.abbreviations.map(item => item.abbreviation);
            result.push(
                {
                  id: country.id,
                  name: country.name,
                  tier: country.tier,
                  abbreviations: abbreviations
                }
            )
          });
          return result;
        },
    },
}
</script>
<style>
  .t-w-900 {
    width: 900px;
  }
</style>